const React = require('react');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const nonEditableComponent = (Base) => {
  // eslint-disable-next-line no-unused-vars
  const nonEditable = (props, context) => (
    <div className="payment-data-editable">
      <Base {...props} />
    </div>
  );

  nonEditable.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return nonEditable;
};

module.exports = Base => nonEditableComponent(Base);
