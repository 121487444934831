const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const { useRenderContext } = require('../../context');

const { THEMES_WITH_TRANSPARENT_HEADER, LEGEND_THEME } = require('../../../../utils/constants/themeConfig');
const { HOME } = require('../../../../utils/constants/sections');
const { isFirstComponent } = require('./utils');

const HomeLayout = ({ section, children, shopName, isCustomHTML }) => {
  const { appearance } = useRenderContext();

  const layoutTheme = appearance.getComponentProperty('Document', 'theme');
  const themeUsesTransparency = THEMES_WITH_TRANSPARENT_HEADER.includes(layoutTheme);
  let isTransparent = true;
  if (layoutTheme !== LEGEND_THEME) {
    const opacity = appearance.getComponentProperty('Header', 'opacity');
    isTransparent = (opacity !== undefined) && (opacity !== 1);
  }

  const componentsToSearch = ['Slider'];
  if (layoutTheme === LEGEND_THEME) {
    componentsToSearch.push('BannerSplinter');
  }
  const isFirstElement = isFirstComponent(componentsToSearch, appearance);

  const homeClass = ClassNames(
    'home',
    layoutTheme ? `home--${layoutTheme}` : null,
    {
      'home--custom-html': isCustomHTML,
      'home--with-transparency': isFirstElement && themeUsesTransparency && isTransparent,
      'home--with-payment': appearance.getComponentByName('PaymentMethods'),
      'home--with-flash': appearance.getComponentProperty('Header', 'flash'),
      'home--with-banner': appearance.getComponentByName('Banner'),
      'home--with-padding-bottom': !appearance.getComponentByName('ShoppingInfo'),
      'home--padding-top-content': (section !== HOME),
    },
  );

  return (
    <div className={homeClass}>
      <h1 className="clipped">{shopName}</h1>
      { children }
    </div>
  );
};

HomeLayout.propTypes = {
  section: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

module.exports = HomeLayout;
