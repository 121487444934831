/**
 * Module dependencies
 */
const React = require('react');
const classNames = require('classnames');

const Action = require('../../action');
const Image = require('../../image-desktop');
const PriceBlock = require('../../price-block');
const Review = require('../../review');
const Shipping = require('../../shippingMaterial');
const Props = require('../../props');

const goToBrand = require('../../../../utils/goToBrand');

/**
 * ItemMaterial Component
 */
const ItemMaterial = ({
  actions,
  attributes,
  brand,
  condition,
  className,
  discount,
  display,
  showTitle,
  id,
  image,
  imgSize,
  installments,
  lazyLoad,
  price,
  review,
  shipping,
  title,
  titleRef,
  url,
}) => {
  const Element = url ? 'a' : 'div';

  const itemClassNames = classNames('ui-item', { [`${className}`]: className });

  // Re use header styles to avoid adding new definitions
  const discountBoxClasses = classNames('ui-item__discount-box', 'nav-header');
  const discountBoxTextClasses = classNames('ui-item__material-discount-text', 'nav-header__logo');
  const contentClasses = classNames('ui-item__material-content', {
    'ui-item__material-content--with-shipping': shipping && shipping.freeShipping,
  });

  return (
    <Element
      href={url}
      className={itemClassNames}
      itemScope
      itemType="http://schema.org/Product"
      title={showTitle ? title : null}
    >
      <Shipping shipping={shipping} hidden />
      {discount
        && (
          <div className={discountBoxClasses}>
            <span className={discountBoxTextClasses}>{discount.text}</span>
          </div>
        )
      }
      {/**
        * Image: supports retina sizes
        */}
      <Image {...image} size={imgSize} title={title} lazyLoad={lazyLoad} />
      <div className={contentClasses}>
        {/**
          * Shipping: Truck icon + free shipping text
          */}
        {title && (
          <p className="ui-item__title" itemProp="name" ref={titleRef}>
            {title}
          </p>
        )}
        {/**
          * Price block: Main price, discount (original price + discount tag), installments
          */}
        {(price || discount || installments) && (
          <PriceBlock price={price} discount={discount} installments={installments} display={display} />
        )}
        <Shipping shipping={shipping} />
        {/**
          * Attributes as text. For example: "2014 | 60000 km"
          */}
        {attributes && <span className="ui-item__attributes">{attributes}</span>}
        {/**
          * Brand (Tienda oficial)
          */}
        {brand && (
          <button
            className="ui-item__brand"
            onClick={(e) => {
              e.preventDefault();
              goToBrand(brand.url);
            }}
          >
            {brand.text}
          </button>
        )}
        {/**
          * Review
          */}
        {review && (
          <Review className="ui-item__review" stars={review.stars} total={review.total} />
        )}

        {actions.map(action => <Action key={action.id} {...action} />)}
      </div>
      {/**
        * Metadata: see schema.org
        */}
      {url && <meta itemProp="url" content={url} />}
      {condition && <meta itemProp="itemCondition" content={condition} />}
      {id && <meta itemProp="productID" content={id} />}
    </Element>
  );
};

/**
 * Prop types and defaults
 */
ItemMaterial.propTypes = Props.propTypes;
ItemMaterial.defaultProps = Props.defaultProps;

/**
 * Expose ItemMaterial
 */
module.exports = ItemMaterial;
