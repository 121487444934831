const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { FAKE_CONTAINER_PRODUCTS } = require('../../utils/constants/tabbedCarousel');
const FakeCard = require('../../components/placeholder/FakeCard');

const WrapperEditable = (Base) => {
  const Wrapper = (props, { metadata }) => {
    const { tabs, empty_state_card: emptyStateCard } = props;
    const { deviceType } = metadata;
    const isNOC = (tabs.length === 0);
    const fakeItems = new Array(FAKE_CONTAINER_PRODUCTS[deviceType]).fill({});
    return isNOC
      ? (
        <div className="fake-card-container fake-card-container--grid">
          <div className="fake-card-container__cards">
            { fakeItems.map(() => <FakeCard {...emptyStateCard} />)}
          </div>
        </div>
      )
      : <Base {...props} />;
  };

  Wrapper.propTypes = {
    hidden: PropTypes.bool.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    empty_state_card: PropTypes.shape({
      icon: PropTypes.string,
      price: PropTypes.string,
      fees: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  Wrapper.defaultProps = {
    empty_state_card: PropTypes.shape({
      icon: '',
      price: '',
      fees: '',
      title: '',
    }),
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
