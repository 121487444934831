const React = require('react');
const PropTypes = require('prop-types');

const Appearance = require('@mshops-web-core/appearance').default;

const renderReducer = require('./reducers');
const RenderActions = require('./actions');

const {
  useContext,
  useReducer,
  createContext,
} = React;

const RenderContext = createContext(null);
const RenderDispatcher = createContext(null);

const RenderProvider = ({
  value,
  children,
}) => {
  const appearance = new Appearance(value.appearance);
  const initial = new Appearance(value.initial ?? value.appearance);
  const [state, dispatch] = useReducer(renderReducer, { ...value, appearance, initial });
  const renderActions = RenderActions.initialize(dispatch, value.apiBasePath);
  return (
    <RenderContext.Provider value={state}>
      <RenderDispatcher.Provider value={renderActions}>
        {children}
      </RenderDispatcher.Provider>
    </RenderContext.Provider>
  );
};

RenderProvider.propTypes = {
  value: PropTypes.shape({
    appearance: PropTypes.shape(),
    initial: PropTypes.shape(),
    editableMode: PropTypes.string,
    apiBasePath: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

const useRenderContext = () => {
  const render = useContext(RenderContext);
  if (!render) {
    throw new Error(
      'useRenderContext must be used within RenderProvider',
    );
  }
  return render;
};

const useRenderDispatcher = () => {
  const dispatcher = useContext(RenderDispatcher);
  if (!dispatcher) {
    throw new Error(
      'useRenderDispatcher must be used within RenderProvider',
    );
  }
  return dispatcher;
};

module.exports = {
  RenderProvider,
  useRenderContext,
  useRenderDispatcher,
};
