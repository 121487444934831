const PropTypes = require('prop-types');
const React = require('react');
const classNames = require('classnames');

const withTracker = require('../with-tracker');

const global = require('../../../global');
const FormatPriceService = require('../../../services/formatPrice');

const metadataPropTypes = PropTypes.shape({
  decimal_places: PropTypes.number.isRequired,
  decimal_separator: PropTypes.string.isRequired,
  thousand_separator: PropTypes.string.isRequired,
});

const updateMappedProps = (props) => {
  const {
    metadata,
    price,
    itemsPerRow,
    display,
    is_trigger: isTrigger,
    actions,
    installments,
    attributes,
    bookmarked,
    squared,
    picture,
    shipping,
    permalink,
  } = props;

  const { decimal_places: decimalPlaces, thousand_separator: thousandSeparator } = metadata;

  const priceDigits = FormatPriceService.getPriceDigits(price);
  const discount = FormatPriceService.getDiscount(props, decimalPlaces, thousandSeparator);
  const className = classNames(props.className,
    `items-per-row-${itemsPerRow}`,
    {
      [`${display}`]: display,
      [`price-digits-${priceDigits}`]: discount,
      'price-text': price.text,
      'trigger-item': isTrigger,
      'with-actions': actions.length,
      'with-discount': discount,
      'with-installments': installments,
      'with-attributes': attributes,
    });

  return {
    ...props,
    className,
    bookmarked,
    discount,
    showTitle: squared,
    image: picture,
    installments,
    price: {
      ...price,
      symbol: price.currency_symbol,
      fraction: FormatPriceService.getThousandSeparated({
        price: price.value,
        separator: thousandSeparator,
      }),
      cents: FormatPriceService.getDecimalPart(price.value, decimalPlaces),
      decimal_separator: metadata.decimal_separator, // Not displayed, but needed for microdata,
      original_price: price.original_price && FormatPriceService
        .getThousandSeparated({
          price: price.original_price,
          separator: thousandSeparator,
        }),
      price: FormatPriceService.getThousandSeparated({
        price: price.original_price,
        separator: thousandSeparator,
      }),
    },
    shipping: shipping && FormatPriceService.getShipping(shipping),
    url: permalink,
    onFavClick: this.bookmark,
  };
};

function withItemBehavior(WrappedComponent) {
  class WithItemBehavior extends React.Component {
    constructor(props) {
      super(props);

      this.bookmark = this.bookmark.bind(this);
      this.restorePreviousBookmark = this.restorePreviousBookmark.bind(this);
      this.onFinishFetching = this.onFinishFetching.bind(this);
      this.switchBookmark = this.switchBookmark.bind(this);
      this.itemHover = this.itemHover.bind(this);
      this.isFetching = false;

      this.state = {
        bookmarked: props.bookmarked,
        singleLineTitleClassName: '',
        mappedProps: updateMappedProps(props),
      };
    }

    onFinishFetching() {
      this.isFetching = false;
    }

    switchBookmark(prevState) {
      return {
        bookmarked: !prevState.bookmarked,
      };
    }

    bookmark(e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.isFetching) {
        this.isFetching = true;
        this.setState(this.switchBookmark, this.updateBookmark);
      }
    }

    updateBookmark() {
      const verb = this.state.bookmarked ? 'post' : 'delete';
      this.props.restClient[verb](`/bookmarks/${this.props.id}`, {
        loginParams: { loginType: 'favorite', item_id: this.props.id },
        params: { go: global.location.href },
      })
        .then(this.onFinishFetching)
        // Restore the previous bookmark state if the endpoint failed.
        .catch(this.restorePreviousBookmark);
    }

    restorePreviousBookmark() {
      this.setState(this.switchBookmark, this.onFinishFetching);
    }

    itemHover() {
      if (this.title) {
        const itemDescriptionHeight = this.title.clientHeight;
        this.isSingleLineDescription(itemDescriptionHeight);
      }
    }

    isSingleLineDescription(height) {
      if (height <= 18) {
        this.setState({
          singleLineTitleClassName: 'with-single-line-description',
        });
      }
    }

    componentWillReceiveProps(nextProps) {
      const mappedProps = updateMappedProps(nextProps);
      this.setState({ mappedProps });
    }

    render() {
      const { mappedProps, singleLineTitleClassName } = this.state;
      const className = classNames(mappedProps.className, singleLineTitleClassName);
      const wrapperClassNames = className && className.split(/\s+/).map(name => `${name}__wrapper`);
      return (
        <div
          className={classNames('ui-item__wrapper', wrapperClassNames)}
          onFocus={this.itemHover}
          onMouseOver={this.itemHover}
        >
          <WrappedComponent
            {...mappedProps}
            className={className}
            titleRef={(title) => { this.title = title; }}
          />
        </div>
      );
    }
  }

  WithItemBehavior.itemPropTypes = {
    actions: PropTypes.array,
    bookmarked: PropTypes.bool,
    className: PropTypes.string,
    display: PropTypes.string,
    id: PropTypes.string.isRequired,
    is_trigger: PropTypes.bool,
    permalink: PropTypes.string.isRequired,
    picture: PropTypes.shape({
      title: PropTypes.string,
      src: PropTypes.string,
      src2x: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      className: PropTypes.string,
    }).isRequired,
    price: PropTypes.shape({
      currency_id: PropTypes.string,
      currency_symbol: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.number,
    }).isRequired,
    shipping: PropTypes.shape({
      free_shipping: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    }),
    squared: PropTypes.bool,
  };

  WithItemBehavior.defaultProps = {
    actions: [],
    bookmarked: false,
    className: '',
    currency_id: null,
    currency_symbol: null,
    display: 'original',
    price: null,
    price_text: null,
    is_trigger: false,
    shipping: null,
    squared: false,
  };

  WithItemBehavior.propTypes = {
    ...WithItemBehavior.itemPropTypes,
    metadata: metadataPropTypes.isRequired,
    restClient: PropTypes.shape({}).isRequired,
  };

  const exportItem = withTracker(WithItemBehavior);
  exportItem.metadataPropTypes = metadataPropTypes;
  return exportItem;
}

module.exports = withItemBehavior;
