const React = require('react');
const PropTypes = require('prop-types');

const Address = ({ address }) => (
  <div>
    <label htmlFor="address" className="contact-info__label">{address.title}</label>
    <div id="address" className="contact-info__data contact-info__address">{address.value}</div>
  </div>
);

Address.propTypes = {
  address: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

module.exports = Address;
