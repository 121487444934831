const React = require('react');
const PropTypes = require('prop-types');
const Style = require('nordic/style');

const MLErrorScreen = require('ui-library_ml/ui/error-screen');

function ErrorScreen({ title, description, link }) {
  return (
    <div>
      <Style href="errorScreen.css" />
      <MLErrorScreen
        title={title}
        description={description}
        link={{
          text: link.text,
          href: link.href,
        }}
      />
    </div>
  );
}

ErrorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
};

module.exports = ErrorScreen;
