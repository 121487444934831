const React = require('react');
const PropTypes = require('prop-types');
const uuid = require('uuid/v4');

const { getCategoriesGalleryProps } = require('./utils');
const FakeItem = require('./fakeItem');
const CategoryGallery = require('./index');
const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const createEditable = require('../../adapters/createEditable');
const withEditable = require('../../adapters/withEditable');
const withHover = require('../../adapters/withHover');
const withOrder = require('../../adapters/withOrder');

const Wrapper = (props, context) => {
  const { metadata: { layoutTheme } } = context;
  const { children, empty_state_card: emptyStateCard, i18n } = props;

  const fakeItemCount = children?.length < 2 ? (2 - children.length) : 0;
  const fakeChildren = fakeItemCount > 0 ? Array.from(Array(fakeItemCount).keys()) : [];

  const propsFakeItem = {
    emptyStateCard,
    theme: layoutTheme,
    subtitle: i18n.gettext('desde'),
  };

  const drawFakeItems = () => fakeChildren.map(() => <FakeItem key={uuid()} {...propsFakeItem} />);

  return <CategoryGallery {...props} editable fakeChildren={drawFakeItems} />;
};

Wrapper.propTypes = {
  i18n: PropTypes.shape(),
  empty_state_card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

Wrapper.defaultProps = {
  i18n: { gettext: f => f },
  empty_state_card: null,
};

Wrapper.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = withHover(createEditable(withEditable(withOrder(Wrapper)), getCategoriesGalleryProps));
