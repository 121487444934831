const React = require('react');
const CarouselPaged = require('ui-library_ml/ui/carousel');
const Recommendations = require('./recommendations');

function RecommendationsDesktop(props) {
  return (
    <Recommendations carousel={CarouselPaged} {...props} />
  );
}

module.exports = RecommendationsDesktop;
