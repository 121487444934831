/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Security = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="174" height="76" viewBox="0 0 174 76">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero" transform="translate(1 1) translate(.776 5.2)">
        <path fill="#EDEDED" stroke="#484848" strokeWidth="1.3" d="M10.552 22.637v-8.36c0-7.69 6.238-13.926 13.93-13.926 7.693 0 13.93 6.236 13.93 13.93v8.357l-4.772-.02V14.59c0-5.057-4.1-9.157-9.158-9.157-5.057 0-9.159 4.1-9.159 9.157v8.026l-4.77.021v-.001z" />
        <rect width="48.963" height="45.834" y="21.698" fill="#EDEDED" stroke="#484848" strokeWidth="1.3" rx="11.7" />
        <path fill="#ADADAD" d="M29.975 38.953c0 3.034-2.46 5.494-5.493 5.494-3.035 0-5.494-2.46-5.494-5.494s2.46-5.492 5.494-5.492 5.493 2.458 5.493 5.492" />
        <path fill="#ADADAD" d="M21.876 42.796L18.743 53.721 30.218 53.721 26.511 42.796z" />
      </g>
      <path fill="#ADADAD" fillRule="nonzero" d="M142.181 28.877c0 .718-.581 1.3-1.3 1.3h-4.005c-.72 0-1.3-.582-1.3-1.3V26.68h-1.838c-1.453 0-2.63-1.178-2.63-2.63s1.177-2.63 2.63-2.63h20.238c1.144-4.06 4.874-7.036 9.3-7.036 5.34 0 9.664 4.327 9.664 9.666 0 5.34-4.325 9.665-9.664 9.665-4.428 0-8.155-2.975-9.3-7.035H142.18v2.197zm21.095-8.72c-2.153 0-3.9 1.74-3.9 3.893 0 2.153 1.747 3.893 3.9 3.893 2.154 0 3.9-1.74 3.9-3.893 0-2.153-1.747-3.893-3.9-3.893z" transform="translate(1 1)" />
      <g transform="translate(1 1) translate(40.95)">
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M6.456 3.61L76.656 3.61 76.656 67.946 6.456 67.946z" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M0 10.027v4.698c.004 3.01 2.141 5.45 4.779 5.454h72.756c2.637-.004 4.773-2.444 4.776-5.454v-9.27C82.308 2.444 80.172.005 77.535 0H4.779C2.14.005.004 2.444 0 5.455" />
        <path fill="#E4E4E4" fillRule="nonzero" d="M4.335 4.17c-.29.33-.435.795-.435 1.29v9.265c0 .489.146.953.435 1.283.116.133.258.27.45.27h72.748c.186 0 .33-.137.444-.268.288-.33.434-.795.434-1.29V5.455c0-.492-.146-.956-.434-1.286-.115-.13-.258-.269-.45-.269l-73.192.27z" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M77.535 25.94H4.779C2.145 25.944.007 28.38 0 31.387v9.272c.004 3.01 2.141 5.45 4.779 5.453h72.756c2.637-.004 4.773-2.444 4.776-5.453v-9.27c-.004-3.009-2.141-5.445-4.776-5.449h0z" />
        <path fill="#E4E4E4" fillRule="nonzero" d="M4.335 30.108c-.288.33-.434.793-.435 1.287v9.264c0 .489.146.954.435 1.283.116.133.258.27.45.27h72.748c.186 0 .33-.138.444-.269.288-.33.434-.794.434-1.288v-9.267c0-.487-.146-.95-.434-1.279-.115-.131-.257-.269-.448-.269l-73.194.27v-.002z" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M77.535 51.878H4.779C2.14 51.883.004 54.32 0 57.33v9.276c.004 3.009 2.141 5.447 4.779 5.452h72.756c2.636-.006 4.772-2.444 4.776-5.453v-9.272c-.003-3.01-2.139-5.45-4.776-5.455z" />
        <path fill="#E4E4E4" fillRule="nonzero" d="M4.335 56.048c-.29.33-.435.793-.435 1.287v9.27c0 .49.146.953.435 1.282.115.132.258.27.449.27h72.75c.185 0 .328-.138.443-.27.288-.329.434-.793.434-1.287v-9.267c0-.49-.146-.955-.434-1.285-.115-.131-.258-.269-.45-.27l-73.192.27z" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M33.45 10.089L71.8 10.089" />
        <circle cx="13.451" cy="9.783" r="2.6" stroke="#333" strokeWidth="1.3" />
        <circle cx="13.451" cy="36.027" r="2.6" stroke="#333" strokeWidth="1.3" />
        <circle cx="13.451" cy="61.967" r="2.6" stroke="#333" strokeWidth="1.3" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M33.45 36.027H71.8m-38.35 25.94H71.8" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M21.1 10.089L28.25 10.089" transform="matrix(-1 0 0 1 49.35 0)" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M21.1 36.027L28.25 36.027" transform="matrix(-1 0 0 1 49.35 0)" />
        <path fill="#FFF" fillRule="nonzero" stroke="#333" strokeWidth="1.3" d="M21.1 61.967L28.25 61.967" transform="matrix(-1 0 0 1 49.35 0)" />
      </g>
      <g fillRule="nonzero">
        <path fill="#FFF" d="M43.687 0H5.114C2.318 0 .04 2.278.04 5.075v22.332c0 2.797 2.278 5.075 5.075 5.075h38.572c2.797 0 5.075-2.278 5.075-5.075V5.075C48.762 2.278 46.484 0 43.687 0" transform="translate(1 1) translate(101.4 40.3)" />
        <path fill="#FFF" stroke="#333" strokeWidth="1.3" d="M48.762 13.862V5.075C48.762 2.278 46.484 0 43.687 0H5.114C2.318 0 .04 2.278.04 5.075v22.332c0 2.797 2.278 5.075 5.075 5.075h38.572c2.797 0 5.075-2.278 5.075-5.075V24.95m0-4.966v-1.68" transform="translate(1 1) translate(101.4 40.3)" />
        <path fill="#EDEDED" d="M34.01 29.613H5.388c-2.679 0-4.85-2.171-4.85-4.848v2.336c0 2.678 2.171 4.85 4.85 4.85h37.993c2.678 0 4.85-2.172 4.85-4.85v-2.336c0 2.678-2.172 4.848-4.85 4.848" transform="translate(1 1) translate(101.4 40.3)" />
        <path fill="#333" d="M7.192 20.985h14.856v-.929H7.192v.929zm0 3.088h14.856v-.928H7.192v.928z" transform="translate(1 1) translate(101.4 40.3)" />
        <path fill="#E4E4E4" d="M47.104 12.181H2.068v-6.5h45.036v6.5zm-7.418 14.91h-4.585c-1.087 0-1.97-.882-1.97-1.97v-4.585c0-1.087.883-1.97 1.97-1.97h4.585c1.089 0 1.97.883 1.97 1.97v4.585c0 1.088-.881 1.97-1.97 1.97" transform="translate(1 1) translate(101.4 40.3)" />
      </g>
    </g>
  </svg>
);

Security.propTypes = {
  className: PropTypes.string,
};

Security.defaultProps = {
  className: '',
};

module.exports = Security;
