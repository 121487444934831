const React = require('react');

const PropTypes = require('prop-types');
const { Icons: { PlayIcon } } = require('@mshops-web-components/icons');

const PlayIconVideo = ({ onImageClick, i18n }) => {
  const onInternalImageClick = () => {
    onImageClick();
  };

  const buttonLabel = i18n.gettext('Ver video');

  return (
    <div className="play-wrapper" onClick={onImageClick} aria-hidden="true">
      <PlayIcon className="play-icon" onClick={onInternalImageClick} />
      <p className="play-text">{buttonLabel}</p>
    </div>
  );
};

PlayIconVideo.propTypes = {
  i18n: PropTypes.shape(),
  onImageClick: PropTypes.func.isRequired,
};

PlayIconVideo.defaultProps = {
  i18n: { gettext: f => f },
};

module.exports = PlayIconVideo;
