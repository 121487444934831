const React = require('react');
const ClassNames = require('classnames');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Button = require('@andes/button');

const { Shape } = require('../icon/index');
const { LEGEND_THEME, EXPLORER_THEME } = require('./constants');

const CategoryCard = ({
  label,
  image,
  showPrice,
  showButton,
  title,
  price,
  buttonText,
  link: { url },
  columns,
  pricePrefix,
  theme,
  isEmpty,
  isDefaultImage,
}) => {
  const carouselItem = ClassNames(
    'carousel__item',
    { 'carousel__item--3': columns === 3 },
    { 'carousel__item--4': columns === 4 },
  );
  const carouselItemImage = ClassNames(
    'carousel__item-image',
    { 'carousel__item-image-3': columns === 3 },
    { 'carousel__item-image-4': columns === 4 },
    { 'carousel__item-image-empty': isEmpty },
    { 'carousel__item-image-default': isDefaultImage },
  );

  const renderPrice = () => (
    <div className="carousel__item-foot-subtitle">
      <span>{pricePrefix}</span> <span>{price}</span>
    </div>
  );

  const renderButton = () => (
    <div className="carousel__item-foot-button">
      <Button>
        {buttonText}
        {theme === LEGEND_THEME && (<Shape />)}
      </Button>
    </div>
  );

  return (
    <a className={carouselItem} href={url}>
      <div className={carouselItemImage}>
        {(theme === LEGEND_THEME || theme === EXPLORER_THEME) && <div className="carousel__item-image-background" />}
        <Image
          src={image}
          alt={label}
        />
      </div>
      <div className="carousel__item-foot">
        <h2 className="carousel__item-foot-title">{title}</h2>
        {showPrice && renderPrice()}
        {showButton && renderButton()}
      </div>
    </a>
  );
};

CategoryCard.propTypes = {
  columns: PropTypes.number,
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string,
  pricePrefix: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
  }),
  showPrice: PropTypes.bool.isRequired,
  showButton: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isDefaultImage: PropTypes.bool,
};

CategoryCard.defaultProps = {
  columns: 2,
  buttonText: '',
  link: {},
  price: '',
  pricePrefix: '',
  isDefaultImage: false,
};

module.exports = CategoryCard;
