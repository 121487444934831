const React = require('react');
const PropTypes = require('prop-types');

const CarouselPaged = require('ui-library_ml/ui/carousel');

const InfoSlide = require('./info-slide');
const Section = require('../section');
const withTracker = require('../with-tracker');

const carouselProps = {
  arrows: false,
  dots: true,
  Slide: InfoSlide,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 300,
};

function SiteShoppingInfoMobile({
  elements,
  lazyLoad,
  type,
}) {
  elements.forEach((element) => {
    element.id = element.title_label;
    element.lazyLoad = lazyLoad;
  });
  return (
    <Section type={type}>
      <CarouselPaged {...carouselProps} slides={elements} />
    </Section>
  );
}

SiteShoppingInfoMobile.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
};

module.exports = withTracker(SiteShoppingInfoMobile);
