const React = require('react');
const PropTypes = require('prop-types');

const wordings = require('./wordings');
const FaqItem = require('./components/FaqItem');

const Faq = ({ i18n, flowGuest }) => {
  const locales = wordings(i18n);
  const faqs = locales.faqs.filter(faq => {
    if (flowGuest && faq.text) {
      return faq;
    }
    if (!flowGuest) {
      faq.text = faq.noGuest || faq.text;
      return faq;
    }
    return null;
  });

  return (
    <div className="how-buy-landing__faqs">
      <h2 className="faqs__title">{locales.title}</h2>
      <ul className="faqs__list">
        {faqs.map(faq => (
          <FaqItem key={faq.title} title={faq.title} text={faq.text} />
        ))}
      </ul>
    </div>
  );
};

Faq.contextTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string,
    faqs: PropTypes.array,
  }),
  flowGuest: PropTypes.bool,
};

module.exports = Faq;
