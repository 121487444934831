const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Image = require('../../components/image');

const FakeMaterial = ({ type, emptyStateCard }) => {
  const imageClass = classNames(
    'label-image',
    'label-image--fake',
    `label-image--${type}`,
    'label-image--behavior-show', {
      'label-image--material-fake-small': type === 'small',
      'label-image--material-fake-large': type === 'large',
    },
  );
  const labelBackgroundClass = classNames(
    'label-image__background',
    'discovery--custom-label-image-gradient',
  );
  const labelTitleClass = classNames(
    'label-image__fake-title',
    'label-image__fake-title--material',
  );

  return (
    <div className={imageClass}>
      <div className={labelBackgroundClass}>
        { emptyStateCard && (
          <>
            <div className={labelTitleClass}>
              <h3>{emptyStateCard.title}</h3>
              { type === 'small' && (
              <div className="label-image__material-button">
                <button type="button">
                  {emptyStateCard.cta}
                </button>
              </div>
              )}
            </div>
            <div className="image-container">
              <Image
                className="image-material"
                src={emptyStateCard.icon}
                alt={emptyStateCard.title}
              />
            </div>
            { type === 'large' && (
            <div className="label-image__material-button">
              <button type="button">
                {emptyStateCard.cta}
              </button>
            </div>
            )}
          </>
        )
      }
      </div>
    </div>
  );
};

FakeMaterial.propTypes = {
  type: PropTypes.oneOf(['small', 'large']).isRequired,
  emptyStateCard: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    cta: PropTypes.string,
  }),
};

FakeMaterial.defaultProps = {
  emptyStateCard: null,
};

module.exports = FakeMaterial;
