const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Button = require('@andes/button');

const Edit = require('./icons/Edit');

const { ButtonText } = Button;

const EditButton = ({ label, onClick, withoutLabel }) => {
  const buttonClasses = classNames(
    'editable-button',
    'editable-button--edit',
    'scale-up', {
      'editable-button--without-label': withoutLabel,
    },
  );

  return (
    <Button className={buttonClasses} onClick={onClick}>
      <Edit />
      { !withoutLabel && <ButtonText>{label}</ButtonText> }
    </Button>
  );
};

EditButton.propTypes = {
  label: PropTypes.string.isRequired,
  withoutLabel: PropTypes.string.bool,
  onClick: PropTypes.func,
};

EditButton.defaultProps = {
  onClick: null,
  withoutLabel: false,
};

module.exports = EditButton;
