const BadgeCarousel = require('.');

const createEditable = require('../../adapters/createEditable');
const withEditable = require('../../adapters/withEditable');
const withHover = require('../../adapters/withHover');
const withOrder = require('../../adapters/withOrder');
const WrapperEditable = require('./brand-carousel-empty-state/wrapper.editable');
const getCarouselProps = require('./getProps');


module.exports = withHover(withEditable(withOrder(createEditable(WrapperEditable(BadgeCarousel), getCarouselProps))));
