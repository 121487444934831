const CategoriesCarousel = require('.');

const createEditable = require('../../adapters/createEditable');
const withEditable = require('../../adapters/withEditable');
const withHover = require('../../adapters/withHover');
const withOrder = require('../../adapters/withOrder');
const WrapperEditable = require('./wrapper');
const getCategoryCarouselProps = require('./getProps');

module.exports = withHover(
  createEditable(
    withEditable(
      withOrder(
        WrapperEditable(CategoriesCarousel),
      ),
    ),
    getCategoryCarouselProps,
  ),
);
