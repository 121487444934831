/* eslint-disable max-len */
const React = require('react');

const Choose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="176" height="90" viewBox="0 0 176 90">
    <defs>
      <path id="prefix__a" d="M5.739 8.63l.51.818c.461.86.37 1.206.06 1.513-.376.371-.892.33-1.45-.114 0-.001-.073-.063-.178-.166-.124-.123-.294-.304-.445-.515-.295-.41-.575-1.135-.68-1.759-.02-.153-.099-.307-.215-.422-.3-.296-.766-.298-1.062-.006-.19.188-.259.433-.199.704.159.876.514 1.666 1.057 2.35l-.464.457c-.361.357-.15 1.074.612 1.071.03 0 .06-.013.082-.034l.624-.616c1.228.968 2.599 1.015 3.512.114.93-.918.9-2.009-.101-3.645l-.494-.834c-.44-.846-.358-1.17-.052-1.473.364-.359.874-.316 1.4.116.003.001.08.063.186.168.12.118.29.304.414.52.162.277.377.74.498 1.207.039.15.108.276.204.37.305.3.774.307 1.07.015.196-.193.255-.448.173-.734-.152-.645-.422-1.213-.825-1.733l.584-.576c.021-.021.034-.051.034-.08.004-.754-.722-.962-1.084-.605l-.395.39c-.62-.503-1.282-.759-1.902-.762-.575-.004-1.114.209-1.551.64-.933.922-.911 1.97.077 3.621z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#F7D032" d="M91 65.082L79 65.091 91 65.106z" transform="translate(32 3.5)" />
      <path fill="#B0B0B0" d="M30 25.082L88 25.082 88 35.082 30 35.082z" opacity=".2" transform="translate(32 3.5)" />
      <g>
        <path fill="#B0B0B0" d="M0 0H21.507V17.976H0z" opacity=".2" transform="translate(32 3.5) translate(30 39.082)" />
        <g transform="translate(32 3.5) translate(30 39.082) translate(25.516)">
          <path fill="#B0B0B0" d="M0 0H32.078V17.976H0z" opacity=".2" />
          <path fill="#FFF" d="M12.029 10.066H27.703V12.942H12.029zM12.029 4.314H22.236V7.1899999999999995H12.029z" />
          <use fill="#FFF" transform="rotate(-45 6.448 8.52)" />
        </g>
      </g>
      <path stroke="#B0B0B0" strokeLinecap="round" strokeWidth="1.2" d="M5 74.915L0 74.915" opacity=".4" transform="translate(32 3.5)" />
      <g>
        <path stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M110.405 74.464H0h110.405z" opacity=".4" transform="translate(32 3.5) translate(8 .082)" />
        <g>
          <path fill="#B0B0B0" d="M5.14 52.111L76.738 52.111 76.738 48.441 5.14 48.441z" opacity=".4" transform="translate(32 3.5) translate(8 .082) translate(12.484 13.178)" />
          <path stroke="#484848" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".8" d="M0 33.395L0 0 77.105 0 77.105 52.111" transform="translate(32 3.5) translate(8 .082) translate(12.484 13.178)" />
          <path stroke="#484848" strokeLinecap="round" strokeWidth=".8" d="M5.514 6.606V5.94c0-.443.356-.802.8-.802h64.49c.442 0 .8.348.8.809v45.797H5.14" transform="translate(32 3.5) translate(8 .082) translate(12.484 13.178)" />
        </g>
        <g opacity=".2" style={{ mixBlendMode: 'multiply' }} stroke="#B0B0B0" strokeLinecap="round" strokeWidth="1.2">
          <path d="M8.854 5.557L8.854.101M13 7.544L16.932 3.596M.542 3.848L0 3.303M4.408 7.72L1.836 5.138" transform="translate(32 3.5) translate(8 .082) rotate(45 44.68 108.996)" />
        </g>
        <g>
          <path fill="#ADADAD" d="M59.48.734v3.67H35.616V.734H.367c-.08.115-2.254 9.473 6.152 9.48l81.803.061c8.406.007 6.516-9.68 6.59-9.541H59.481z" transform="translate(32 3.5) translate(8 .082) translate(4.039 64.555)" />
          <path fill="#B0B0B0" d="M2.02 6.606s1.1 2.568 4.222 2.568h81.327c3.205 0 4.59-2.018 5.14-2.568" opacity=".4" style={{ mixBlendMode: 'multiply' }} transform="translate(32 3.5) translate(8 .082) translate(4.039 64.555)" />
          <path stroke="#484848" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".8" d="M93.367 0s2.07 9.174-6.165 9.174H8.445" transform="translate(32 3.5) translate(8 .082) translate(4.039 64.555)" />
        </g>
      </g>
      <g>
        <path fill="#B0B0B0" d="M62.146 19.773c3.592 0 6.504-2.93 6.504-6.543 0-.034-10.373-3.526-10.659-3.526-.116 0-2.348 3.419-2.348 3.526 0 3.614 2.912 6.543 6.503 6.543zM49.14 19.773c3.59 0 6.503-2.93 6.503-6.543 0-.034-11.819-3.16-12.104-3.16-.117 0-.904 3.053-.904 3.16 0 3.614 2.912 6.543 6.504 6.543zM36.132 19.773c3.592 0 6.503-2.93 6.503-6.543 0-.034-6.218 0-6.503 0-.117 0-6.504-.107-6.504 0 0 3.614 2.912 6.543 6.504 6.543z" opacity=".4" style={{ mixBlendMode: 'multiply' }} transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#B0B0B0" d="M23.124 19.773c3.592 0 6.504-2.93 6.504-6.543 0-.015.692-.687-.723-1.512-1.722-1.005-5.624-2.197-5.78-2.197-.117 0-6.504 3.602-6.504 3.709 0 3.614 2.911 6.543 6.503 6.543zM10.117 19.773c3.592 0 6.504-2.93 6.504-6.543 0-.034-6.038-3.893-6.323-3.893-.117 0-6.685 3.786-6.685 3.893 0 3.614 2.912 6.543 6.504 6.543z" opacity=".4" style={{ mixBlendMode: 'multiply' }} transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#ADADAD" stroke="#484848" strokeWidth=".8" d="M43.358 9.52c0 4.045 3.235 7.324 7.226 7.324s7.227-3.279 7.227-7.323" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#FFF" stroke="#484848" strokeWidth=".8" d="M28.905 9.52c0 4.045 3.236 7.324 7.227 7.324h0c3.99 0 7.226-3.279 7.226-7.323" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#ADADAD" stroke="#484848" strokeWidth=".8" d="M14.453 9.52c0 4.045 3.235 7.324 7.226 7.324h0c3.991 0 7.226-3.279 7.226-7.323" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#FFF" stroke="#484848" strokeLinejoin="round" strokeWidth=".8" d="M12.277 9.52H0c0 4.045 3.235 7.324 7.226 7.324s7.227-3.279 7.227-7.323" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#FFF" d="M45.887 9.887L0 9.887 3.252 0.366 69.011 0.366 72.263 9.887" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#ADADAD" d="M3.613 0L15.537 0 14.453 9.154 0.361 9.154z" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#ADADAD" d="M59.617 0L71.902 0 70.276 9.154 56.727 9.154z" transform="translate(32 3.5) translate(23 18.082) matrix(-1 0 0 1 128.629 0)" />
        <path fill="#ADADAD" d="M29.989 0L42.093 0 42.816 9.154 29.267 9.154z" transform="translate(32 3.5) translate(23 18.082)" />
        <path fill="#FFF" stroke="#484848" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".8" d="M57.811 9.52c0 4.045 3.236 7.324 7.227 7.324 3.99 0 7.226-3.279 7.226-7.323H49.5" transform="translate(32 3.5) translate(23 18.082)" />
        <path stroke="#484848" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".8" d="M45.887 9.521L0 9.521 3.252 0 69.011 0 72.263 9.521" transform="translate(32 3.5) translate(23 18.082)" />
      </g>
    </g>
  </svg>

);

module.exports = Choose;
