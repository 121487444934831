const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Image = require('../../image');
const withTracker = require('../../with-tracker');
const withTrackerOnPrint = require('../../with-tracker-on-print');

const FakeCardOriginal = ({ cardSize, price, fees, title, icon, currencySymbol }) => {
  const fakeCardSize = classNames('fake-card', {
    'fake-card__big-element': cardSize === 2,
    'fake-card__medium-element': cardSize === 3,
    'fake-card__small-element': cardSize === 4 || cardSize === 0,
    'fake-card__smaller-element': cardSize === 5,
  });

  return (
    <div className={fakeCardSize}>
      <div className="fake-card__image">
        <Image src={icon} alt={title} />
      </div>
      <div className="fake-card__content">
        <p className="fake-card__content-price">{currencySymbol} {price}</p>
        <p className="fake-card__content-fees">{fees}</p>
        <p className="fake-card__content-title">{title}</p>
      </div>
    </div>
  );
};

FakeCardOriginal.propTypes = {
  price: PropTypes.string,
  fees: PropTypes.string,
  title: PropTypes.string,
  cardSize: PropTypes.number,
  icon: PropTypes.string,
  currencySymbol: PropTypes.string,
};

FakeCardOriginal.defaultProps = {
  price: '',
  fees: '',
  cardSize: 0,
  title: '',
  icon: '',
  currencySymbol: '',
};

module.exports = withTracker(withTrackerOnPrint(FakeCardOriginal));
