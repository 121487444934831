const React = require('react');
const PropTypes = require('prop-types');

const EditableButtons = require('../appearance/EditableButtons');
const Editable = require('../appearance/Editable').default;
const Message = require('../components/message');
const withEditable = require('./withEditable');

const renderMe = () => true;

const createEditable = (Base, getProps, renderWrapper = renderMe, renderBase = renderMe) => {
  const Wrapper = (props) => {
    const { i18n, advice_message: message } = props;
    const wrapperRef = React.createRef();
    const editableProps = getProps(props);
    const editButton = {
      label: i18n.gettext('Editar'),
      ...editableProps,
    };

    if (!renderWrapper(props)) {
      return renderBase(props) ? <Base {...props} /> : null;
    }

    if (message.type === 'warning') {
      return (
        <div className="editable-container editable-container--warning" ref={wrapperRef}>
          <Editable
            className="editable-wrapper"
            wrapperRef={wrapperRef}
          >
            {message.link
              ? (
                <div className="editable-container__base">
                  <Base {...props} />
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a
                    className="editable-container__base--link"
                    href={message.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </div>
              )
              : (
                <div className="editable-container__base">
                  <Base {...props} />
                </div>
              )}
          </Editable>
          <Message {...message} />
        </div>
      );
    }

    if (message.type === 'info') {
      const MessageSection = withEditable(Message);
      return (
        <div className="editable-container editable-container--info" ref={wrapperRef}>
          <Editable
            className="editable-wrapper"
            type={editButton.type}
            payload={editButton.payload}
            wrapperRef={wrapperRef}
          >
            <div className="editable-container__base--link">
              <Base {...props} />
            </div>
            <EditableButtons editButton={editButton} showDelete={false} />
          </Editable>
          <MessageSection
            {...message}
            wrapperRef={wrapperRef}
            scope={editButton.type}
            payload={editButton.payload}
          />
        </div>
      );
    }

    return (
      <div className="editable-wrapper editable-wrapper--with-border wrapper--editable" ref={wrapperRef}>
        <Editable type={editButton.type} payload={editButton.payload} wrapperRef={wrapperRef}>
          <Base {...props} />
          <EditableButtons editButton={editButton} showDelete={false} />
        </Editable>
      </div>
    );
  };

  Wrapper.propTypes = {
    i18n: PropTypes.shape(),
    advice_message: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  };

  Wrapper.defaultProps = {
    advice_message: {
      text: '',
      icon: '',
      type: '',
      link: {
        text: '',
        url: '',
      },
    },
    i18n: { gettext: f => f },
  };

  return Wrapper;
};

module.exports = createEditable;
