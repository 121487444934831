const ClassNames = require('classnames');

const useFormClasses = ({ pretitle, title, description }) => {
  const pretitleClasses = ClassNames('newsletter-form__pretitle', {
    'newsletter-form__pretitle--hidden': !pretitle.enabled || !pretitle.text,
  });

  const titleClasses = ClassNames('newsletter-form__title', {
    'newsletter-form__title--hidden': !title.enabled || !title.text,
  });

  const descriptionClasses = ClassNames('newsletter-form__description', {
    'newsletter-form__description--hidden': !description.enabled || !description.text,
  });

  return {
    pretitleClasses, titleClasses, descriptionClasses,
  };
};

module.exports = useFormClasses;
