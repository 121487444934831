const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { FAKE_CONTAINER_PRODUCTS } = require('../CollectionGrid/utils');
const FakeCard = require('../../components/placeholder/FakeCard');

const WrapperEditable = (Base) => {
  const Wrapper = (props, { metadata }) => {
    const {
      items,
      title,
      view_more_action: viewMoreAction,
      empty_state_card: emptyStateCard,
    } = props;
    const { deviceType } = metadata;
    const defaultLength = FAKE_CONTAINER_PRODUCTS[deviceType];
    const fillNeeded = items.length < 4;
    const isEmpty = items.length === 0;
    const emptyState = fillNeeded || isEmpty;
    const fakeLength = fillNeeded ? defaultLength - items.length : defaultLength;
    const showHeader = title && viewMoreAction;
    const fakeItems = new Array(fakeLength).fill({});

    return emptyState ? (
      <div className="fake-card-container">
        {showHeader && (
          <div className="fake-card-container__header">
            <h3 className="fake-card-container__header-title">{title}</h3>

            {viewMoreAction.label && (
              <div className="fake-card-container__header-action">
                {viewMoreAction.label}
              </div>
            )}
          </div>
        )}

        <div className="fake-card-container__cards">
          {fillNeeded
            && items.map((item) => (
              <FakeCard
                currencySymbol={item.price.currency_symbol}
                price={item.price.value}
                fees={item.installments}
                title={item.title}
                icon={item.picture.src}
              />
            ))}
          {fakeItems.map(() => (
            <FakeCard {...emptyStateCard} />
          ))}
        </div>
      </div>
    ) : (
      <Base {...props} />
    );
  };

  Wrapper.propTypes = {
    title: PropTypes.string,
    view_more_action: PropTypes.shape({
      label: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({
      installments: PropTypes.string,
      title: PropTypes.string,
      picture: PropTypes.shape({
        src: PropTypes.string,
      }),
      price: PropTypes.shape({
        value: PropTypes.string,
        currencySymbol: PropTypes.string,
      }),
    })),
    empty_state_card: PropTypes.shape({
      icon: PropTypes.string,
      price: PropTypes.string,
      fees: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  Wrapper.defaultProps = {
    title: '',
    items: [],
    view_more_action: {
      label: '',
    },
    empty_state_card: PropTypes.shape({
      icon: '',
      price: '',
      fees: '',
      title: '',
    }),
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
