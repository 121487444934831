/**
 * Module dependencies
 */
require('babel-polyfill');
const React = require('react');

const PropTypes = require('prop-types');
const Head = require('react-declarative-head');
const serialize = require('serialize-javascript');

const injectI18n = require('nordic/i18n/injectI18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const Script = require('nordic/script');
const Style = require('nordic/style');
const Appearance = require('@mshops-web-core/appearance').default;
const StorageService = require('@mshops-web-core/storage-service').default;

const canUseDOM = require('../../../utils/enabledDOM');

const HomeLayout = require('./HomeLayout');
const MetadataProvider = require('../../../components/metadataProvider');
const RenderTree = require('../../../appearance/RenderTree');

const { RenderProvider } = require('../context');

const { HOME } = require('../../../utils/constants/sections');
const { EDITABLE_MODE } = require('../../../utils/constants/common');
const { SCROLL_HOME } = require('../utils/constants');

const { useEffect } = React;
/**
 * HomeView Component
 */
function HomeView({
  i18n,
  translations,
  site,
  siteId,
  lowEnd,
  deviceType,
  company,
  shopModel,
  config,
  editable,
  section,
  allowMetrics,
  siteKey,
  apiBasePath,
}) {
  // eslint-disable-next-line
  const { appearance, fiscal_data, shop, contact } = shopModel;
  const { currency, title } = appearance.properties;
  const { restClient } = config;

  const appearanceService = new Appearance(appearance);
  const layoutTheme = appearanceService.getComponentProperty('Document', 'theme');
  const metaTags = appearanceService.getComponentProperty('Document', 'meta_tags');

  const serializableProps = {
    translations,
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    shopModel,
    config,
    editable,
    section,
    allowMetrics,
    siteKey,
    apiBasePath,
  };

  const customDimensions = { collectorId: shopModel.shop.id };
  const page = (section === HOME) ? 'MAIN' : section.toUpperCase();

  /* This is temporary while we find a way to make the back
  not return the Grid as editable or we don't wrap Mosaic's children in a Grid */
  const fixMosaicComponents = () => {
    const mosaicArray = appearance.children[1].children.filter(component => component.component === 'MosaicEditable');
    mosaicArray.forEach(component => {
      component.children[0].component = 'Grid';
    });
  };

  fixMosaicComponents();

  const handleScroll = () => {
    StorageService.setItem(SCROLL_HOME, window.scrollY);
    window.parent.postMessage(
      {
        message: window.scrollY,
        action: 'SCROLL',
      },
      '*',
    );
  };

  useEffect(() => {
    if (canUseDOM() && window.tracking_mediator) {
      window.tracking_mediator(
        'dispatch',
        `page:${window.__PRELOADED_STATE__.section}`,
        window.__PRELOADED_STATE__.shopModel.appearance,
      );
      window.tracking_mediator(
        'dispatch',
        `gtm:${window.__PRELOADED_STATE__.section}`,
        { page, section },
      );
    }
    if (editable) {
      window.scrollTo(0, StorageService.getItem(SCROLL_HOME) || 0);
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderProviderInitialValue = {
    appearance,
    editableMode: EDITABLE_MODE.COMPONENTS,
    initial: { ...appearance },
    apiBasePath,
  };

  return (
    <>
      {(!editable && allowMetrics)
        && [
          <MeliGA
            siteId={siteId}
            platform="MS"
            section="HOME"
            business="SHOPS"
            page={`/${page}/`}
            dimensions={customDimensions}
          />,
          <MelidataTrack
            path="/home"
          />,
        ]}

      <Head>
        <title>{title}</title>
        {(metaTags && metaTags.length)
          ? metaTags.map((meta) => <meta name={meta.type} content={meta.content} />)
          : null
        }
      </Head>
      <Style href={`${layoutTheme}/home-${layoutTheme}-${deviceType}.css`} />
      {editable && <Style href={`home-${deviceType}-editable.css`} />}
      <Script>
        {`
          window.__PRELOADED_STATE__ = ${serialize(serializableProps, { isJSON: true }) || {}};
        `}
      </Script>
      <script type="text/javascript" src="https://cdn.polyfill.io/v3/polyfill.min.js?features=es5,es6&flags=gated" />
      <Script src={`home-${deviceType}${editable ? '-editable' : ''}.js`} />

      <I18nProvider i18n={i18n}>
        <MetadataProvider
          metadata={{
            currency,
            deviceType,
            siteId,
            lowEnd,
            siteKey,
            restClient,
            fiscalData: fiscal_data,
            contact,
            socialNetworks: shop.social_networks,
            layoutTheme,
            shopModel,
          }}
        >
          <RenderProvider value={renderProviderInitialValue}>
            <HomeLayout
              section={section}
              isCustomHTML={shopModel?.appearance?.properties?.custom}
              shopName={shopModel?.shop?.name}
            >
              <RenderTree i18n={i18n} />
            </HomeLayout>
          </RenderProvider>
        </MetadataProvider>
      </I18nProvider>
    </>
  );
}

HomeView.propTypes = {
  i18n: PropTypes.shape().isRequired,
  translations: PropTypes.shape(),
  site: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  shopModel: PropTypes.shape(),
  config: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
  section: PropTypes.string.isRequired,
  allowMetrics: PropTypes.bool.isRequired,
  siteKey: PropTypes.string.isRequired,
  apiBasePath: PropTypes.string.isRequired,
};

HomeView.defaultProps = {
  translations: {},
  site: 'MS',
  shopModel: {
    appearance: {
      properties: {},
    },
  },
  editable: false,
};

/**
 * Inject i18n context as props into DemoView.
 */
module.exports = injectI18n(HomeView);
