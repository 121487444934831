/* eslint-disable max-len */
const React = require('react');

const Shipping = () => (
  <svg width="98" height="72" viewBox="0 0 98 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M91.0891 63.738V69.493C91.0891 70.0453 90.6414 70.493 90.0891 70.493H6.8494C6.29711 70.493 5.8494 70.0453 5.8494 69.493V17.1445H91.0891V51.1198V63.738Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M60.979 4.73828H35.4449V17.1456H6.83506V22.4396H35.4449V40.1409L41.8284 34.9728L48.212 40.1409L54.5955 34.9728L60.979 40.1409V22.4396H91.0211V17.1456H60.979V4.73828ZM5.8494 65.6768H91.0211V70.4927H5.8494V65.6768Z" fill="#EEEEEE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M91.0891 63.738V69.493C91.0891 70.0453 90.6414 70.493 90.0891 70.493H6.8494C6.29711 70.493 5.8494 70.0453 5.8494 69.493V17.1445H91.0891V51.1198V63.738Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="0.810059" y="1.5" width="95.4" height="15.6" rx="2" fill="white" stroke="#333333" strokeWidth="1.5" />
    <mask id="path-5-inside-1" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M60.48 1.5H35.88V34.8052L42.03 29.9089L48.18 34.8052L54.33 29.9089L60.48 34.8052V1.5ZM14.5621 62.1646H31.0006V51.2866H14.5621V62.1646Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M60.48 1.5H35.88V34.8052L42.03 29.9089L48.18 34.8052L54.33 29.9089L60.48 34.8052V1.5ZM14.5621 62.1646H31.0006V51.2866H14.5621V62.1646Z" className="icon--custom-color" fill="#22277A" />
    <path d="M35.88 1.5V0H34.38V1.5H35.88ZM60.48 1.5H61.98V0H60.48V1.5ZM35.88 34.8052H34.38V37.9168L36.8143 35.9787L35.88 34.8052ZM42.03 29.9089L42.9643 28.7354L42.03 27.9916L41.0957 28.7354L42.03 29.9089ZM48.18 34.8052L47.2457 35.9787L48.18 36.7226L49.1143 35.9787L48.18 34.8052ZM54.33 29.9089L55.2643 28.7354L54.33 27.9916L53.3957 28.7354L54.33 29.9089ZM60.48 34.8052L59.5457 35.9787L61.98 37.9168V34.8052H60.48ZM31.0006 62.1646V63.6646H32.5006V62.1646H31.0006ZM14.5621 62.1646H13.0621V63.6646H14.5621V62.1646ZM31.0006 51.2866H32.5006V49.7866H31.0006V51.2866ZM14.5621 51.2866V49.7866H13.0621V51.2866H14.5621ZM35.88 3H60.48V0H35.88V3ZM37.38 34.8052V1.5H34.38V34.8052H37.38ZM41.0957 28.7354L34.9457 33.6317L36.8143 35.9787L42.9643 31.0824L41.0957 28.7354ZM49.1143 33.6317L42.9643 28.7354L41.0957 31.0824L47.2457 35.9787L49.1143 33.6317ZM53.3957 28.7354L47.2457 33.6317L49.1143 35.9787L55.2643 31.0824L53.3957 28.7354ZM61.4143 33.6317L55.2643 28.7354L53.3957 31.0824L59.5457 35.9787L61.4143 33.6317ZM58.98 1.5V34.8052H61.98V1.5H58.98ZM31.0006 60.6646H14.5621V63.6646H31.0006V60.6646ZM29.5006 51.2866V62.1646H32.5006V51.2866H29.5006ZM14.5621 52.7866H31.0006V49.7866H14.5621V52.7866ZM16.0621 62.1646V51.2866H13.0621V62.1646H16.0621Z" fill="white" mask="url(#path-5-inside-1)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M36.1621 1.5H60.1835V34.8052L54.1782 29.9433L48.1728 34.8052L42.1675 29.9433L36.1621 34.8052V1.5Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="31.0006" y="62.166" width="16.4385" height="10.878" transform="rotate(-180 31.0006 62.166)" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.8595 54.2996H76.0595V50.0996H71.8595V54.2996Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.8595 61.4988H76.0595V57.2988H71.8595V61.4988Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M83.6101 54.2996H82.2343H80.2266H79.4101V50.0996H83.6101V54.2996Z" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

module.exports = Shipping;
