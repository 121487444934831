const React = require('react');
const PropTypes = require('prop-types');
const classnames = require('classnames');
const metadataPropType = require('../metadataProvider/metadataPropType');
const Section = require('../section');
const Card = require('../card-with-image-price');

const PriceAndImage = require('./index');

const { THEMES, MAX_CATEGORIES } = require('../../utils/constants/priceAndImage');

const PriceAndImageEditable = (props, { metadata }) => {
  const { theme, title, categories, card_theme: cardTheme, empty_state_card: emptyStateCard } = props;
  const showPlaceholder = categories.length < MAX_CATEGORIES;
  const isCustomTheme = theme === THEMES.CUSTOM;
  const fakeLength = showPlaceholder ? (MAX_CATEGORIES - categories.length) : 0;
  const fakeItems = new Array(fakeLength).fill({});
  const sectionClassName = classnames('price-and-image', {
    'header--custom-background': isCustomTheme,
    [theme]: (!isCustomTheme),
  });
  const headingClassName = classnames('price-and-image__title', {
    'header--custom-text': isCustomTheme,
  });

  return !showPlaceholder ? (
    <PriceAndImage {...props} {...metadata} />
  ) : (
    <Section className={sectionClassName}>
      <div className="row container price-and-image__container price-and-image-empty-state">
        <h2 className={headingClassName}>{title}</h2>
        <section className="price-and-image__row">
          { categories.map(cardProps => (
            <Card theme={cardTheme} {...cardProps} />
          )) }
          { fakeItems.map(() => (
            <Card theme={cardTheme} image={emptyStateCard.icon} label={emptyStateCard.title} fakePrice={emptyStateCard.price} />
          ))}
        </section>
      </div>
    </Section>
  );
};

PriceAndImageEditable.propTypes = PropTypes.shape({
  card_theme: PropTypes.oneOf(['dark', 'light']).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.shape({
        currency_id: PropTypes.string.isRequired,
        currency_symbol: PropTypes.string.isRequired,
        original_price: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  theme: PropTypes.oneOf(['dark', 'light', 'custom']).isRequired,
  title: PropTypes.string.isRequired,
}).isRequired;

PriceAndImageEditable.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = PriceAndImageEditable;
