const { RestClientService, RequestStrategy } = require('@mshops-web-core/rest-client');

class ClientService {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  get(url, config) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        config,
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }

  post(url, requestBody) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        method: 'post',
        config: {
          data: requestBody,
        },
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }

  put(url, requestBody) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        method: 'put',
        config: {
          data: requestBody,
        },
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }
}

module.exports = ClientService;
