const React = require('react');
const PropTypes = require('prop-types');

const Form = require('@andes/form');

const useFormClasses = require('./utils/formClasses');
const newsletterFormPropTypes = require('./utils/formProps');

const NewsletterForm = ({
  pretitle,
  title,
  description,
  inputs,
  buttons,
  disclaimer,
  handleSubscription,
  email,
  handleEmailChange,
}) => {
  const { email: emailInput } = inputs;
  const { text: disclaimerText, link_text: disclaimerLink, link_url: disclaimerUrl } = disclaimer;

  const { pretitleClasses, titleClasses, descriptionClasses } = useFormClasses({
    pretitle, title, description,
  });

  return (
    <Form onSubmit={handleSubscription}>
      <span className={pretitleClasses}>{pretitle.text}</span>
      <h3 className={titleClasses}>{title.text}</h3>
      <p className={descriptionClasses}>{description.text}</p>
      <div className="newsletter-form__control-group">
        <input
          className="newsletter-form__textfield"
          placeholder={emailInput.placeholder}
          name="email"
          type="text"
          value={email}
          onChange={handleEmailChange}
        />
        <button
          className="newsletter-form__button ui-button--primary"
          type="submit"
        >
          <span>{buttons.primary.text}</span>
        </button>
      </div>
      <p className="newsletter-form__disclaimer">
        {disclaimerText}
        <a href={disclaimerUrl}>{disclaimerLink}</a>
      </p>
    </Form>
  );
};

NewsletterForm.propTypes = {
  ...newsletterFormPropTypes,
  email: PropTypes.string.isRequired,
  handleSubscription: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
};

module.exports = NewsletterForm;
