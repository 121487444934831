const React = require('react');
const PropTypes = require('prop-types');
const Style = require('nordic/style');

const Panel = require('ui-library_ml/ui/panel');

function HelpComponent({ title, body }) {
  return (
    <div>
      <Style href="helpComponent.css" />
      <Panel raised className="m-y-3">
        <h1>{title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: body }} // eslint-disable-line react/no-danger
        />
      </Panel>
    </div>
  );
}

HelpComponent.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

module.exports = HelpComponent;
