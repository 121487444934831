const React = require('react');
const PropTypes = require('prop-types');

const PlayIconCaptive = require('./captive');
const PlayIconExplorer = require('./explorer');
const PlayIconDefault = require('./default');

const PLAY_ICON_BY_THEME = {
  captive: PlayIconCaptive,
  explorer: PlayIconExplorer,
};

const PlayIcon = ({ onImageClick, i18n, theme }) => {
  const PlayIconComponent = PLAY_ICON_BY_THEME[theme] || PlayIconDefault;

  return <PlayIconComponent onImageClick={onImageClick} i18n={i18n} />;
};

PlayIcon.propTypes = {
  i18n: PropTypes.shape(),
  onImageClick: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

module.exports = PlayIcon;
