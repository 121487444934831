const PropTypes = require('prop-types');
const React = require('react');
const CarouselPaged = require('ui-library_ml/ui/carousel');
const uuid = require('uuid/v4');

const Item = require('../item');
const withTracker = require('../with-tracker');
const withTrackerOnPrint = require('../with-tracker-on-print');
const Section = require('../section');
const TabList = require('./tab-list');
const { IMG_SIZE_LARGE } = require('../../utils/constants/tabbedCarousel');

const carouselSettings = {
  Slide: Item,
  slidesToShow: 4,
  arrowsVisible: false,
  variableWidth: false,
  infinite: false,
  slidesToScroll: 4,
  // Width of the carousel. Needs to be considered as: (slide width) * (amount of slides per page to show)
  size: {
    width: '1200px',
    minWidth: '1200px',
  },
};

class CarouselWithTabsDesktop extends React.Component {
  constructor(props) {
    super(props);

    const tabContent = props.tabs.map((tab) => {
      const items = tab.items.map(item => ({
        ...item,
        lazyLoad: props.lazyLoad,
        metadata: props.metadata,
        restClient: props.restClient,
        imgSize: IMG_SIZE_LARGE,
      }));

      return ({ id: tab.id, items }
      );
    });

    this.state = {
      carouselActive: tabContent[0] ? tabContent[0].id : null,
      tabContent,
    };

    this.toggleCarousel = this.toggleCarousel.bind(this);
  }

  toggleCarousel(event) {
    const carouselActive = event.currentTarget.getAttribute('id');
    this.setState({ carouselActive });
  }

  render() {
    const { tabs, type } = this.props;
    const { tabContent, carouselActive } = this.state;
    const selectedTab = tabContent.find(tab => tab.id === carouselActive);
    const Carousel = CarouselPaged;

    return (
      <Section type={type}>
        <div className="row container carousel-with-tabs">
          <div className="section-header">
            <TabList tabs={tabs} carouselActive={carouselActive} toggleCarousel={this.toggleCarousel} />
          </div>
          <Carousel
            className="carousel-with-tabs__carousel"
            key={uuid()}
            slides={selectedTab ? selectedTab.items : []}
            {...carouselSettings}
          />
        </div>
      </Section>
    );
  }
}

CarouselWithTabsDesktop.propTypes = {
  carousel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  restClient: PropTypes.shape.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  type: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = withTracker(withTrackerOnPrint(CarouselWithTabsDesktop));
