const Base = require('.');

const createEditable = require('../../adapters/createEditable');
const withEditable = require('../../adapters/withEditable');
const withHover = require('../../adapters/withHover');
const withOrder = require('../../adapters/withOrder');

const { getContentListProps } = require('./getProps');

module.exports = withHover(createEditable(withEditable(withOrder(Base)), getContentListProps));
