const React = require('react');
const PropTypes = require('prop-types');

const Title = ({ title, namespace }) => (
  <h2 className={`${namespace}-landing__title`}>{title}</h2>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

module.exports = Title;
