/* eslint-disable max-len */
const settings = (shop) => ({
  tags: {
    1: '<strong>',
    2: '</strong>',
  },
  replacements: {
    0: shop,
  },
});

module.exports = (i18n, shop) => ({
  title: i18n.gettext('¿Cómo comprar?'),
  main: {
    first: i18n.jsx.gettext('Realizar una compra en {1}{0}{2} es muy simple. Sigue estos pasos:', settings(shop)),
  },
  noGuest: {
    first: i18n.gettext('Realizar una compra en'),
    second: i18n.gettext('es muy simple. Sólo sigue estos pasos:'),
  },
});
