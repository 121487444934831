const React = require('react');
const PropTypes = require('prop-types');

const EditButton = require('./Edit');
const EditDropdown = require('./EditDropdown');
const DeleteButton = require('./Delete');

const Editable = require('../Editable').default;
const { useRenderContext } = require('../../pages/home/context');
const { EDITABLE_MODE } = require('../../utils/constants/common');

const EditableButtons = (props) => {
  const { editButton, showDelete, deleteButton, withoutLabel } = props;
  const { editableMode } = useRenderContext();

  const onMouseDown = (event) => {
    if (editButton.options) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  return (editableMode === EDITABLE_MODE.COMPONENTS) && (
    <div className="editable-buttons" onMouseDown={onMouseDown} role="button" tabIndex={0}>
      { editButton.options
        ? <EditDropdown label={editButton.label} options={editButton.options} />
        : <EditButton label={editButton.label} withoutLabel={withoutLabel} />}
      { showDelete
        && (
        <Editable type={deleteButton.type} payload={deleteButton.payload}>
          <DeleteButton label={deleteButton.label} />
        </Editable>
        )}
    </div>
  );
};

EditableButtons.propTypes = {
  editButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    payload: PropTypes.shape(),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        payload: PropTypes.shape(),
      }),
    ),
  }).isRequired,
  showDelete: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  deleteButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    payload: PropTypes.shape(),
  }),
};

EditableButtons.defaultProps = {
  showDelete: true,
  deleteButton: {},
  wrapperRef: null,
  withoutLabel: false,
};

module.exports = EditableButtons;
