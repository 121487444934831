const React = require('react');

const { useState } = React;
const PropTypes = require('prop-types');
const classNames = require('classnames');
const uuid = require('uuid');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const FakeCard = require('../../components/placeholder/FakeCard');

const WrapperEditable = (Base) => {
  const Wrapper = (props) => {
    const {
      items,
      title,
      view_more_action: viewMoreAction,
      empty_state_card: emptyStateCard,
      display,
      items_per_row: underItemsPerRow,
      max_items: underMaxItems,
      itemsPerRow,
      maxItems,
      categories,
    } = props;

    const parsedItemsPerRow = underItemsPerRow || itemsPerRow;
    const parsedMaxItems = underMaxItems || maxItems;

    const hasCategory = Boolean(categories?.length);

    const [viewItems, setViewItems] = useState(hasCategory ? categories[0].items : items);
    const [id, setId] = useState(0);

    const updateSubHeader = (index) => {
      setId(index);
      setViewItems(categories[index].items);
    };

    const itemAmount = viewItems.length;
    const fillNeeded = itemAmount < parsedMaxItems;
    const isEmpty = itemAmount === 0;
    const emptyState = isEmpty || fillNeeded;
    const fakeLength = fillNeeded ? parsedMaxItems - itemAmount : parsedItemsPerRow;
    const showHeader = title && viewMoreAction;
    const fakeItems = new Array(fakeLength).fill({});

    const titleClassName = classNames({ 'section-header__title': hasCategory });
    const sectionHeaderClassName = classNames('fake-card-container__header', {
      'section-header__title-container': hasCategory,
    });

    const fakeCardContainerGrid = classNames('fake-card-container__cards', {
      'fake-card-container__cards-grid': parsedMaxItems > 5,
      'grid-6': parsedMaxItems === 6,
      'grid-8': parsedMaxItems === 8,
      'grid-10': parsedMaxItems === 10,
    });

    const setDecoration = (index) => classNames({ 'section-header__sub-header-decoration': id === index });

    return emptyState ? (
      <div className="fake-card-container fake-card-container--grid">
        {showHeader && (
          <div>
            <div className={sectionHeaderClassName}>
              <h3 className={titleClassName}>{title}</h3>

              {(!hasCategory && viewMoreAction.label) && (
                <div className="fake-card-container__header-action">
                  {viewMoreAction.label}
                </div>
              )}
            </div>
            {
              hasCategory && (
                <div className="fake-card-container__sub-header">
                  {categories.map((item, index) => (
                    <h3
                      key={uuid()}
                      onClick={() => updateSubHeader(index, item.id)}
                      className={setDecoration(index)}
                    >
                      {item.label}
                    </h3>
                  ))}
                </div>
              )}
          </div>
        )}
        <div className={fakeCardContainerGrid}>
          {fillNeeded
            && viewItems.map((item) => (
              <FakeCard
                cardSize={parsedItemsPerRow}
                currencySymbol={item.price.currency_symbol}
                price={item.price.value}
                fees={item.installments}
                title={item.title}
                icon={item.picture.src}
                discountLabel={item.discount_label}
                display={display}
              />
            ))}
          {fakeItems.map(() => (
            <FakeCard cardSize={parsedItemsPerRow} {...emptyStateCard} display={display} />
          ))}
        </div>
      </div>
    ) : (
      <Base {...props} />
    );
  };

  Wrapper.propTypes = {
    hidden: PropTypes.bool.isRequired,
    display: PropTypes.string,
    itemsPerRow: PropTypes.number.isRequired,
    maxItems: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({
      installments: PropTypes.string,
      title: PropTypes.string,
      picture: PropTypes.shape({
        src: PropTypes.string,
      }),
      price: PropTypes.shape({
        currencySymbol: PropTypes.string,
        value: PropTypes.string,
      }),
    })),
    title: PropTypes.string,
    view_more_action: PropTypes.shape({
      label: PropTypes.string,
    }),
    empty_state_card: PropTypes.shape({
      icon: PropTypes.string,
      price: PropTypes.string,
      fees: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  Wrapper.defaultProps = {
    title: '',
    maxItems: 4,
    items: [],
    view_more_action: {
      label: '',
    },
    empty_state_card: PropTypes.shape({
      icon: '',
      price: '',
      fees: '',
      title: '',
    }),
    display: 'original',
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
