const CHILDREN_TO_IGNORE = 'debug-info';

const removeUnnecessaryChildren = appearance => {
  let elementRemoved = null;
  if (appearance.layout) {
    elementRemoved = appearance.layout.children.find(children => CHILDREN_TO_IGNORE.includes(children.id));
    appearance.layout.children = (
      appearance.layout.children.filter(children => !(CHILDREN_TO_IGNORE === (children.id)))
    );
  }

  return { appearanceChildrenRemoved: appearance, elementRemoved };
};

module.exports = removeUnnecessaryChildren;
