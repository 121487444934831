const types = require('../Editable/types');

// THIS CONST GIVES US THE CONFIGURATION TO DRAW FAKE CONTAINERS
// BASED ON THE QUANTITY OF CHILDREN APPEARANCE RETURN FOR THIS COMPONENT
// IN EDITABLE MODE
const EDITABLE_FAKE_CHILDREN = [
  // FOR 0 CHILDREN, WE DRAW 3 FAKE CONTAINERS
  [
    { type: 'large' },
    { type: 'small' },
    { type: 'small' },
  ],
  // FOR 1 CHILDREN WE COMPLETE WITH 2 FAKES
  [
    { type: 'small' },
    { type: 'small' },
  ],
  // AND FOR 2 CHILDREN, WE COMPLETE WITH 1 FAKE
  [
    { type: 'small' },
  ],
];

const getDiscoveryProps = (props) => {
  const { componentId, title, theme, behavior, categories, display, default_config: defaultConfig } = props;
  return {
    type: types.DISCOVERY,
    payload: { componentId, title, theme, behavior, categories, display, defaultConfig },
  };
};

module.exports = { EDITABLE_FAKE_CHILDREN, getDiscoveryProps };
