const React = require('react');
const PropTypes = require('prop-types');

const SocialNetworks = ({ socialNetworks }) => (
  <div className="contact-info__social-networks">
    <label htmlFor="socialNetworks" className="contact-info__label">{socialNetworks.title}</label>
    <div id="socialNetworks" className="contact-info__data">
      <ul className="nav-footer-social-links contact-info__social-links">
        {socialNetworks.value.map(accountData => (
          <a
            className="nav-footer-social-link-item contact-data__social"
            href={accountData.url}
            target="_blank"
            rel="noopener noreferrer"
            key={accountData.name}
          >
            {accountData.name}
          </a>
        ))}
      </ul>
    </div>
  </div>
);

SocialNetworks.propTypes = {
  socialNetworks: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })),
  }).isRequired,
};

module.exports = SocialNetworks;
