const React = require('react');
const PropTypes = require('prop-types');
const CarouselPaged = require('ui-library_ml/ui/carousel');

const Slide = require('../slide');

const metadataPropType = require('../metadataProvider/metadataPropType');
const { defaultCarouselSettings, SLIDER_SIZE } = require('./config');

const classesHelper = require('../../utils/customBEMHelper');
const createVariation = require('../../utils/createVariation');

const { useRenderContext } = require('../../pages/home/context');

const { useMemo } = React;

const Slider = (
  { autoplay, slides, size },
  { metadata: { deviceType, lowEnd } },
) => {
  const { appearance } = useRenderContext();
  const layoutTheme = appearance.getComponentProperty('Document', 'theme');

  const classes = classesHelper({ name: (layoutTheme === 'material') ? 'slider-material' : 'slider' });

  const SlideVariation = useMemo(() => createVariation(Slide,
    (layoutTheme === 'material') ? 'material' : 'original'), []);

  const carouselSettings = {
    ...defaultCarouselSettings,
    Slide: SlideVariation,
    arrows: deviceType !== 'mobile',
    autoplay: autoplay.enable,
    dots: !lowEnd,
    speed: autoplay.time,
    infinite: deviceType === 'desktop',
  };

  const slidesWithSize = slides.map(slide => ({
    ...slide,
    sliderSize: SLIDER_SIZE[deviceType][size],
  }));

  return (
    <div className={classes('', size)}>
      { (slides.length === 1)
        ? <div className="carousel-container"><SlideVariation {...slidesWithSize[0]} /></div>
        : <CarouselPaged {...carouselSettings} slides={slidesWithSize} />
      }
    </div>
  );
};

Slider.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  slides: PropTypes.arrayOf(PropTypes.object),
  autoplay: PropTypes.shape({
    enable: PropTypes.bool,
    time: PropTypes.number,
  }).isRequired,
  variation: PropTypes.oneOf(['original', 'material']).isRequired,
};

Slider.defaultProps = {
  slides: [],
};

Slider.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Slider;
