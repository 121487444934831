const PropTypes = require('prop-types');
const React = require('react');
const classNames = require('classnames');
const withLifeCycle = require('../with-life-cycle/mobile');

function Image({ className, mounted, lazyLoad, src, src2x, alt, size }) {
  if (!mounted && lazyLoad) {
    return (
      <img
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        data-src={src}
        className={classNames(className, 'lazy-loadable')}
        alt={alt}
        data-srcset={src2x ? `${src} 1x, ${src2x} 2x` : null}
        width={size}
        height={size}
      />
    );
  }
  return (
    <img
      src={src}
      className={className}
      alt={alt}
      srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
      width={size}
      height={size}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
  mounted: PropTypes.bool,
  src: PropTypes.string.isRequired,
  src2x: PropTypes.string,
  size: PropTypes.string,
};

Image.defaultProps = {
  alt: null,
  className: null,
  lazyLoad: false,
  mounted: true,
  src2x: null,
  size: null,
};

module.exports = withLifeCycle(Image);
