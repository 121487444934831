/* global window */
const React = require('react');
const logger = require('nordic/logger')('mshops-home');

const metadataPropType = require('../components/metadataProvider/metadataPropType');

/**
 * The idea of this adapter is to pass the handler for
 * sending events to its parent window when we are in
 * an editable environment.
 * @param {*} WrappedComponent The component to wrap.
 */
function withEditable(WrappedComponent) {
  class Editable extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage(scope, payload, coords, options = { warmup: false }) {
      window.parent.postMessage({
        scope,
        payload,
        coords,
        options,
      }, '*');
      logger.info(`--> emit >> ${scope} - ${JSON.stringify({ payload, coords, options })}`);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          sendMessage={this.sendMessage}
        />
      );
    }
  }

  Editable.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return Editable;
}

module.exports = withEditable;
