const React = require('react');

const metadataPropType = require('../components/metadataProvider/metadataPropType');

function createVariation(Base, variation) {
  const VariationComponent = (props) => <Base {...props} variation={variation} />;

  VariationComponent.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return VariationComponent;
}

module.exports = createVariation;
