const React = require('react');
const PropTypes = require('prop-types');

const Picture = ({ size, color, className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <path fill={color} stroke={color} fillRule="nonzero" d="M0 0v24h24V0H0zm21.903 2.097v8.74l-4.648-5.026-7.15 8.26-3.334-3.208-4.674 4.421V2.097h19.806zM2.097 21.903v-3.739l4.674-4.42 3.486 3.334 7.048-8.16 4.598 4.977v7.983H2.097v.025z" />
  </svg>
);

Picture.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

Picture.defaultProps = {
  size: 24,
  color: '#3483FA',
  className: '',
};

module.exports = Picture;
