const types = require('../Editable/types');

const getCarouselProps = (props) => {
  const { componentId, title, id, values, display, width, tabs, empty_state_card: emptyStateCard } = props;
  return {
    type: types.TABBED_CAROUSEL,
    payload: { componentId, title, id, values, display, width, tabs, emptyStateCard },
  };
};

module.exports = { getCarouselProps };
