const React = require('react');
const PropTypes = require('prop-types');

const Section = require('../section');
const Action = require('../action');

const SectionText = ({ title, text, ctas, steering_wheel: steeringWheel, classNames }) => {
  const {
    subTitleClassName,
    titleClassName,
    textClassName,
    ctasClassName,
    actionsClassName,
    actionsSecondaryClassName,
  } = classNames || {};

  return (
    <Section className="section-text">
      <div className="section-text__container">
        <div className="section-text__row section-text__row--fill">
          {(steeringWheel?.text && !steeringWheel?.hidden) && (
            <div className="section-text__row--fill">
              <p className={subTitleClassName}>{steeringWheel.text}</p>
            </div>
          )}
          {(title?.text && !title?.hidden) && (
            <div className="section-text__row--fill">
              <p className={titleClassName}>{title.text}</p>
            </div>
          )}
        </div>
        {(text.text && !text.hidden) && (
          <div className="section-text__row section-text__row--fill">
            <div
              className={textClassName}
              dangerouslySetInnerHTML={{ __html: text.text }}
            />
            {(!ctas?.hidden && ctas?.buttons?.length > 0) && (
              <div className={ctasClassName}>
                {ctas.buttons.map((row, index) => (
                  (row.message)
                  && (
                    <Action
                      key={`${index + 0}-${row.message}`}
                      className={(index !== 1) ? actionsClassName : actionsSecondaryClassName}
                      target={row.href}
                    >
                      <span>{row.message}</span>
                    </Action>
                  )
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Section>
  );
};

SectionText.defaultProps = {
  text: {
    text: '',
    align: false,
    hidden: false,
  },
};


SectionText.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  steering_wheel: PropTypes.shape({
    text: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  ctas: PropTypes.shape({
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  classNames: PropTypes.shape({
    subTitleClassName: PropTypes.string.isRequired,
    titleClassName: PropTypes.string.isRequired,
    textClassName: PropTypes.string.isRequired,
    ctasClassName: PropTypes.string.isRequired,
    actionsClassName: PropTypes.string.isRequired,
    actionsSecondaryClassName: PropTypes.string.isRequired,
  }),
};

module.exports = SectionText;
