const React = require('react');
const PropTypes = require('prop-types');

const Info = ({ lastUpdate, subtitle, text, link, url }) => (
  <section className="legal-notices__info-section">
    <div className="legal-notices__info-container">
      <h2 className="title-section__subtitle">{subtitle}</h2>
      <h6 className="title-section__last-update">{lastUpdate}</h6>
      <p className="title-section__text">{text}</p>
      <a
        className="title-section__link"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {link}
      </a>
    </div>
  </section>
);

Info.propTypes = {
  lastUpdate: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

module.exports = Info;
