const types = require('../Editable/types');

const getVideoAndTextProps = ({
  componentId,
  title,
  text,
  video_link: videoLink,
  videos,
  variant,
  alignment,
  id,
}) => ({
  type: types.VIDEO_AND_TEXT,
  payload: { componentId, title, text, videoLink, videos, variant, alignment, id },
});

module.exports = { getVideoAndTextProps };
