const types = require('../Editable/types');

const THEME_TITLE_FONT = 'legend';

const getCategoriesGalleryProps = (props) => {
  const {
    componentId,
    id,
    title,
    categories,
    variation,
    max_variations: maxVariations,
    show_title_category: showTitleCategory,
    behavior,
    show_base_price: showBasePrice,
    show_category_button: showCategoryButton,
    category_button_text: categoryButtonText,
  } = props;

  return {
    type: types.CATEGORIES_GALLERY,
    payload: {
      componentId,
      id,
      title,
      categories,
      variation,
      maxVariations,
      showTitleCategory,
      behavior,
      showBasePrice,
      showCategoryButton,
      categoryButtonText,
    },
  };
};

module.exports = { getCategoriesGalleryProps, THEME_TITLE_FONT };
