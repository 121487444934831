const React = require('react');
const PropTypes = require('prop-types');
const Image = require('nordic/image');

const Action = require('../action');

const Badge = ({ label, image, position, link }) => {
  const { url } = link;

  return (
    <div className="carousel__item">
      <Action target={url}>
        <div className={`carousel__item__${position}`}>
          <div className="carousel__item-img">
            <Image
              src={image}
              alt={label}
              lazyLoad
            />
          </div>
          {label && <h3 className="carousel__item-title">{label}</h3>}
        </div>
      </Action>
    </div>
  );
};

Badge.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Badge.defaultProps = {
  link: {},
};

module.exports = Badge;
