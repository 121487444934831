const PropTypes = require('prop-types');

const priceShape = PropTypes.shape({
  currency_id: PropTypes.string,
  symbol: PropTypes.string,
  fraction: PropTypes.string.isRequired,
  decimal_separator: PropTypes.string,
  cents: PropTypes.string,
});

const propTypes = {
  /**
   * Required properties
   */
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    src2x: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  /**
   * Optional properties
   */
  title: PropTypes.string,
  url: PropTypes.string,
  condition: PropTypes.string,
  className: PropTypes.string,
  tracking: PropTypes.string,
  bookmarked: PropTypes.bool,
  installments: PropTypes.string,
  attributes: PropTypes.string,
  lazyLoad: PropTypes.bool.isRequired,
  /**
   * Shaped props
   */
  price: priceShape,
  shipping: PropTypes.shape({
    freeShipping: PropTypes.bool,
    text: PropTypes.string.isRequired,
  }),
  brand: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  discount: PropTypes.shape({
    text: PropTypes.string.isRequired,
    original_price: priceShape.isRequired,
  }),
  review: PropTypes.shape({
    stars: PropTypes.number.isRequired,
    total: PropTypes.number,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};

const defaultProps = {
  actions: [],
  shipping: null,
  title: null,
  brand: null,
  review: null,
  url: null,
  condition: null,
  tracking: null,
  id: null,
  installments: null,
  attributes: null,
  discount: null,
  className: '',
  bookmarked: false,
  cuurency_id: null,
  symbol: null,
};

module.exports = { propTypes, defaultProps };
