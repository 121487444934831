const Appearance = require('@mshops-web-core/appearance').default;

const { EDITABLE_MODE } = require('../../../utils/constants/common');

const renderDefaultState = {
  appearance: new Appearance({}),
  editableMode: EDITABLE_MODE.COMPONENTS,
  initial: new Appearance({}),
  fetching: false,
  apiBasePath: '/api',
};

module.exports = renderDefaultState;
