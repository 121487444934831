const React = require('react');
const PropTypes = require('prop-types');

const Title = require('../LegalNoticesInfo/components/Title');
const TermsAndConditions = require('./components/TermsAndConditions');

/* eslint-disable react/no-danger, react/destructuring-assignment */
const AdhesionContractInfo = (props) => {
  const { text: paragraph, title, terms } = props;

  return (
    <section>
      <Title title={title} />
      <div className="simple-landing__info-section">
        <div className="simple-landing__info-container">
          <div dangerouslySetInnerHTML={{ __html: paragraph }} />
          {
            terms.map(term => (<TermsAndConditions key={term.title} title={term.title} text={term.text} />))
          }
        </div>
      </div>
    </section>
  );
};

AdhesionContractInfo.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(PropTypes.objectOf({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = AdhesionContractInfo;
