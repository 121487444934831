const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const { CARD_VARIATIONS } = require('./constants');

const FakeMosaicCard = ({ image, title, cta, variation }) => {
  const containerClassName = ClassNames({
    'mesh-col-mobile-12 mesh-col-desktop-6': (variation === CARD_VARIATIONS.PRIMARY),
    'mesh-col-mobile-6 mesh-col-desktop-4': (variation === CARD_VARIATIONS.SECONDARY),
    'mesh-col-mobile-6 mesh-col-desktop-2': (variation === CARD_VARIATIONS.TERTIARY),
  });
  const cardClassName = ClassNames(`andes-card hub_grid__item item_card card_${variation} andes-card--animated`);

  return (
    <div className={containerClassName}>
      <div className={cardClassName}>
        <div className="item-image">
          <img src={image} alt={title} />
        </div>
        <div className="item-description">
          <h3>{title}</h3>
          <p>{cta}</p>
        </div>
      </div>
    </div>
  );
};

FakeMosaicCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.string,
  variation: PropTypes.oneOf([
    CARD_VARIATIONS.PRIMARY,
    CARD_VARIATIONS.SECONDARY,
    CARD_VARIATIONS.TERTIARY,
  ]),
};

module.exports = FakeMosaicCard;
