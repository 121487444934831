const React = require('react');
const PropTypes = require('prop-types');
const Style = require('nordic/style');

function Document({ children, ...props }) {
  return (
    <div>
      <Style href={props.styles_url} />
      {children}
    </div>
  );
}

Document.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  styles_url: PropTypes.string.isRequired,
};

module.exports = Document;
