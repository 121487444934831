const React = require('react');
const PropTypes = require('prop-types');

const MaterialContent = require('./MaterialContent');
const OriginalContent = require('./OriginalContent');

const VARIATION_CONTENT = {
  material: MaterialContent,
  original: OriginalContent,
};

const ContentContainer = ({ display, styles, content, variations }) => {
  const LabelImageContent = VARIATION_CONTENT[display];

  return (
    <LabelImageContent
      styles={styles}
      content={content}
      variations={variations}
    />
  );
};

ContentContainer.propTypes = {
  styles: PropTypes.shape({
    background_image: PropTypes.string,
  }).isRequired,
  variations: PropTypes.string.isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
      onlyLink: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light', 'custom']),
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
  }).isRequired,
  display: PropTypes.string,
};

ContentContainer.defaultProps = {
  display: 'original',
};

module.exports = ContentContainer;
