const React = require('react');
const PropTypes = require('prop-types');

const namespace = 'ui-ms-condition-container';

const Condition = ({ id, name, installmentsLabel, methodNames, title, legals }) => (
  <section className={namespace} data-testid="condition-info">
    <div className="condition-short-info">
      <div className="condition-logo">
        <div
          role="img"
          title={name}
          className={`payments-large _${id}-large`}
        />
      </div>
      <p className="condition-sub-info">{installmentsLabel}</p>
      <p className="condition-name">{methodNames}</p>
    </div>
    <div className="condition-more-info">
      <h3 className="condition-title">{title}</h3>
      <p className="condition-legals">{legals}</p>
    </div>
  </section>
);

Condition.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  installments_label: PropTypes.string.isRequired,
  legals: PropTypes.string.isRequired,
  financial_label: PropTypes.string,
  method_names: PropTypes.string.isRequired,
};

Condition.defaultProps = {
  id: 0,
  name: '',
  installments_label: '',
  legals: '',
  financial_label: '',
  method_names: '',
};

module.exports = Condition;
