const React = require('react');
const PropTypes = require('prop-types');

const Title = require('./components/Title');
const Info = require('./components/Info');

const LGPDInfo = (props) => {
  const { title, subtitle, questions, text } = props;

  return (
    <div className="lgpd-landing">
      <Title title={title} subtitle={subtitle} text={text} />
      <Info questions={questions} />
    </div>
  );
};

LGPDInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  questions: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

module.exports = LGPDInfo;
