module.exports = {
  REORDER_LAYOUT_COMPONENT: 'APPEARANCE/REORDER_LAYOUT',
  MOVE_COMPONENT_UP: 'APPEARANCE/MOVE_UP',
  MOVE_COMPONENT_DOWN: 'APPEARANCE/MOVE_DOWN',
  MOVE_TO_COMPONENT: 'APPEARANCE/MOVE_TO_COMPONENT',
  RESET_LAYOUT: 'APPEARANCE/RESET_LAYOUT',
  SAVE_LAYOUT: 'APPEARANCE/SAVE_LAYOUT',
  CHANGE_EDITABLE_MODE: 'EDITABLE_MODE/CHANGE',
  TRIGGER_ANIMATION_DOWN: 'TRIGGER_ANIMATION/DOWN',
  TRIGGER_ANIMATION_UP: 'TRIGGER_ANIMATION/UP',
  UPDATE_COMPONENT_PROPS: 'APPEARANCE/UPDATE_COMPONENT_PROPS',
  SET_COMPONENT_HIDDEN: 'APPEARANCE/SET_COMPONENT_HIDDEN',
  REMOVE_LAYOUT_COMPONENT: 'APPEARANCE/REMOVE_COMPONENT',
  LAYOUT_REQUEST: 'APPEARANCE/LAYOUT_REQUEST',
  LAYOUT_REQUEST_SUCCESS_FULL: 'APPEARANCE/LAYOUT_REQUEST_SUCCESS_FULL',
  LAYOUT_REQUEST_SUCCESS_PARTIAL: 'APPEARANCE/LAYOUT_REQUEST_SUCCESS_PARTIAL',
  LAYOUT_REQUEST_ERROR: 'APPEARANCE/LAYOUT_REQUEST_ERROR',
};
