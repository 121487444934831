const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Modal = require('@andes/modal');
const Close20 = require('@andes/icons/Close20');

const NewsletterForm = require('../newsletter-form');
const newsletterFormPropTypes = require('../newsletter-form/utils/formProps');

const NewsletterModal = (props) => {
  const { image, showModal, modalType, setShowModal } = props;

  const closeModal = () => (setShowModal(false));

  return (
    <Modal type={modalType} isOpen={showModal} className="newsletter-modal">
      {image && (
        <div className="newsletter-modal--image-content">
          <Image className="newsletter-modal__image" src={image} lazyload="off" />
          <div className="newsletter-modal--image-shadow shops__background--primary-color" />
        </div>
      )}
      <button onClick={closeModal} className="newsletter-modal__content-close">
        <Close20 />
      </button>
      <div className="newsletter-modal__content">
        <NewsletterForm {...props} />
      </div>
    </Modal>
  );
};

NewsletterModal.propTypes = {
  ...newsletterFormPropTypes,
  campaign_id: PropTypes.number.isRequired,
  image: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
};

NewsletterModal.defaultProps = {
  image: null,
};

module.exports = NewsletterModal;
