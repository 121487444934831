const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const CarouselPaged = require('ui-library_ml/ui/carousel');
const CarouselDraggable = require('ui-library_ml/ui/carousel-draggable');

const Badge = require('./badge');

const metadataPropType = require('../metadataProvider/metadataPropType');
const { settings, buildImageUrl } = require('./config');

const BadgeCarousel = ({ title, shape, variations, children }, { metadata }) => {
  if (children.length === 0) {
    return null;
  }

  const { deviceType } = metadata;

  // hub_carousel class is only added to maintain compatibility with custom html shops
  const articleClases = ClassNames('hub__carousel hub_carousel');

  const carouselClasses = ClassNames(
    variations === 'brands' ? 'carousel__brands-ul' : 'carousel__ul',
    variations === 'brands' ? `carousel__brands-ul--${shape}` : `carousel__ul--${shape}`,
  );

  const slides = children.map(({ props }, position) => {
    const { id, properties } = props?.node;
    return ({
      id,
      label: properties.label,
      image: buildImageUrl(properties.image_src),
      link: properties.link,
      position,
    });
  });
  const SlidesItem = item => (
    <Badge {...item} position={item.position} deviceType={deviceType} />
  );

  settings.Slide = SlidesItem;
  settings.slidesToShow = (children.length >= 8) ? 8 : children.length;

  return (
    <article className={articleClases}>
      <h2 className="title">{title}</h2>
      <div className={carouselClasses}>
        {deviceType === 'mobile' ? (
          <CarouselDraggable slideTemplate={Badge} slides={slides} />
        ) : (
          <CarouselPaged {...settings} slides={slides} />
        )}
      </div>
    </article>
  );
};

BadgeCarousel.propTypes = {
  title: PropTypes.string,
  shape: PropTypes.oneOf(['circle', 'square']),
  variations: PropTypes.oneOf(['categories', 'brands']).isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

BadgeCarousel.defaultProps = {
  title: null,
  shape: 'circle',
  children: [],
};

BadgeCarousel.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = BadgeCarousel;
