const payment = require('./Payment');
const shield = require('./Shield');
const shipping = require('./Shipping');
const { choose, buy, collect } = require('../../../appearance/HowBuyInfo/components/Steps/icons');

module.exports = {
  payment,
  shield,
  shipping,
  choose,
  buy,
  collect,
};
