const React = require('react');

const Card = require('@andes/card');

const PropTypes = require('prop-types');
const Title = require('../../components/landing-title');

const namespace = 'ui-ms-cancel-buy';

/* eslint-disable react/no-danger */
const CancelBuyInfo = (props) => {
  const {
    title,
    paragraphs,
  } = props;

  const htmlData = paragraphs && paragraphs.map(({ text }) => text).join('');

  return (
    <section className="cancel-buy-landing__section">
      <Title title={title} namespace={namespace} />
      <Card className="cancel-buy-landing__card">
        <section className="cancel-buy-landing__content" dangerouslySetInnerHTML={{ __html: htmlData }} />
      </Card>
    </section>
  );
};

CancelBuyInfo.propTypes = {
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.objectOf({
    text: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = CancelBuyInfo;
