const React = require('react');
const HomeView = require('./HomeView');

function HomeViewDesktop(props) {
  return (
    <HomeView {...props} />
  );
}

module.exports = HomeViewDesktop;
