/* eslint-disable max-len */
const React = require('react');

const Collect = () => (
  <svg width="176px" height="90px" viewBox="0 0 176 90" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path d="M2.99039603,0.0252436782 C1.99079567,0.0252436782 1.07194381,0.61096092 0.598859675,1.54945057 C0.126075721,2.48602299 0.175605469,3.62454483 0.72853756,4.51158851 L2.99039603,7.75683908 L5.27086569,4.51158851 C5.8249985,3.62134943 5.87272716,2.4789931 5.3954405,1.54050345 C4.92145583,0.606806897 4.00380469,0.0252436782 3.00720613,0.0252436782 L2.99039603,0.0252436782 L2.99039603,0.0252436782 Z" id="path-1" />
    </defs>
    <g id="Altas" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1920-copy" transform="translate(-1256.000000, -232.000000)">
        <g id="Paquete" transform="translate(1256.000000, 232.000000)">
          <g transform="translate(26.000000, 27.000000)">
            <line x1="7.45717339" y1="50.5" x2="115.5" y2="51.1819167" id="Stroke-20" stroke="#B0B0B0" strokeWidth="1.2" opacity="0.4" strokeLinecap="round" />
            <rect id="Rectangle" fill="#FFFFFF" x="14" y="2" width="94" height="12" />
            <polygon id="Fill-1" fill="#ADADAD" points="53 33.0816961 71 33.0816961 71 0.0816960981 53 0.0816960981" />
            <polygon id="Fill-2" fill="#B0B0B0" style={{ mixBlendMode: 'multiply' }} opacity="0.4" points="20 18.0816961 107 18.0816961 107 14.0816961 20 14.0816961" />
            <polyline id="Stroke-4" stroke="#484848" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" points="106 13.0816961 106 51.0816961 19 51.0816961 19 43.0577917" />
            <polyline id="Stroke-5" stroke="#484848" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" points="71 31.0816961 68.0002096 28.0816961 65.0001048 31.0816961 62 28.0816961 58.9998952 31.0816961 56.0001048 28.0816961 53 31.0816961" />
            <polygon id="Fill-6" fill="#ADADAD" points="24 38.0816961 39 38.0816961 39 35.0816961 24 35.0816961" />
            <polygon id="Fill-7" fill="#ADADAD" points="24 42.0816961 33 42.0816961 33 39.0816961 24 39.0816961" />
            <polygon id="Fill-8" fill="#ADADAD" points="23 46.0816961 35 46.0816961 35 43.0816961 23 43.0816961" />
            <polyline id="Stroke-9" stroke="#484848" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" points="44.6258065 14.0816961 14 14.0816961 14 2.0816961 108 2.0816961 108 14.0816961 104.967742 14.0816961" />
            <path d="M25.9899731,26.0817554 C24.8884794,26.0817554 23.8765998,26.6878266 23.3554487,27.6589291 C22.8346284,28.6280478 22.8895212,29.8064643 23.4979716,30.7240025 L25.9899731,34.0816961 L28.5021461,30.7240025 C29.1125806,29.8028272 29.1654894,28.6207736 28.6397088,27.649671 C28.1142589,26.6779072 27.0951044,26.0748119 25.9899731,26.0817554" id="Fill-10" fill="#ADADAD" />
            <g id="Group-17" transform="translate(23.000000, 26.081696)">
              <mask id="mask-2" fill="white">
                <use />
              </mask>
              <g id="Clip-16" />
              <polygon id="Fill-15" fill="#ADADAD" mask="url(#mask-2)" points="-0.858515625 8.96438161 6.85791887 8.96438161 6.85791887 -1.18165977 -0.858515625 -1.18165977" />
            </g>
            <g id="Group-24" transform="translate(0.000000, 28.081696)" strokeLinecap="round">
              <path d="M26.2041052,2.47604206 C25.5390471,2.47604206 25,1.93540818 25,1.26801552 C25,0.601539182 25.5390471,0.0605998648 26.2041052,0.0605998648 C26.8691633,0.0605998648 27.4079061,0.601539182 27.4079061,1.26801552 C27.4079061,1.93540818 26.8691633,2.47604206 26.2041052,2.47604206 L26.2041052,2.47604206 Z" id="Stroke-18" stroke="#FFFFFF" strokeWidth="0.8" strokeLinejoin="round" />
              <line x1="5.022178" y1="22.4183039" x2="0.152187212" y2="22.4183039" id="Stroke-22" stroke="#B0B0B0" strokeWidth="1.2" opacity="0.4" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

module.exports = Collect;
