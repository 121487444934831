const React = require('react');
const { isGridBannerFather } = require('../utils');
const { getAdviceMessage } = require('../../RenderTree/utils');

const withAdviceMessage = (Component) => {
  const Mapper = (props) => {
    const { nodeItems } = props;
    let message = {};
    let adviceMessage = null;
    const isBannerFather = isGridBannerFather(props);
    if (nodeItems?.length > 0 && isBannerFather) {
      const { properties } = nodeItems[0];
      adviceMessage = getAdviceMessage(null, properties);
      if (adviceMessage.hasAdviceMessage) {
        message = properties.advice_message;
      }
    }
    return (<Component {...props} advice_message={message} />);
  };
  return Mapper;
};

module.exports = withAdviceMessage;
