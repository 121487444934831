const React = require('react');
const PropTypes = require('prop-types');
const Classnames = require('classnames');

const Discovery = ({ display, title, children, editable, hidden, fakeChildren }) => {
  const wrapperClasses = Classnames('column-discovery', {
    'column-discovery--hidden': hidden,
  });
  const columnClasses = Classnames('column-discovery__columns', {
    'column-material-discovery__columns': display === 'material',
    'column-discovery--editable': editable,
  });

  return (
    <div className={wrapperClasses}>
      <div className="column-discovery__title">{title}</div>
      <div className="column-discovery__wrapper">
        <div className={columnClasses}>
          { React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { display });
            }
            return child;
          })}
          { hidden && fakeChildren() }
        </div>
      </div>
    </div>
  );
};

Discovery.propTypes = {
  display: PropTypes.oneOf(['original', 'material']).isRequired,
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  editable: PropTypes.bool,
  hidden: PropTypes.bool,
  fakeChildren: PropTypes.func.isRequired,
};

Discovery.defaultProps = {
  title: '',
  editable: false,
  hidden: false,
};

module.exports = Discovery;
