/**
 * Module dependencies
 */
const React = require('react');
const Props = require('./props');

const ItemOriginal = require('./item/mobile/itemOriginal');
const ItemMaterial = require('./item/mobile/itemMaterial');

const VARIATION_ELEMENT = {
  original: ItemOriginal,
  material: ItemMaterial,
};

const VariationItem = (props) => {
  const { display } = props;
  const Item = VARIATION_ELEMENT[display];
  return <Item {...props} />;
};

VariationItem.propTypes = Props.propTypes;
VariationItem.defaultProps = Props.defaultProps;

module.exports = VariationItem;
