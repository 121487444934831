/* eslint-disable max-len */
const React = require('react');

const Shield = () => (
  <svg width="59" height="72" viewBox="0 0 59 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.46298 11.3319C1.29785 11.393 1.16312 11.4998 1.06906 11.6344C0.974904 11.7692 0.921472 11.9318 0.92199 12.1034L0.922001 35.1113C0.883975 43.5803 3.77393 51.513 8.74933 57.8381C13.7236 64.1618 20.7825 68.8787 29.4785 70.9178C37.7776 68.8787 44.8364 64.1618 49.8107 57.8381C54.7861 51.513 57.676 43.5803 57.638 35.1113V12.1111C57.6386 11.9318 57.5851 11.7692 57.491 11.6344C57.3969 11.4998 57.2622 11.393 57.1 11.333L29.597 1.12334C29.3913 1.05524 29.1688 1.05524 28.9631 1.12334L1.46298 11.3319Z" fill="white" />
    <path d="M1.9812 12.1559L29.2674 1.97167C29.2757 1.9699 29.2843 1.9699 29.2926 1.97167L56.5788 12.1559L56.5788 35.1104L56.5788 35.1146C56.6157 43.3759 53.8123 51.1139 48.9841 57.2853C44.2009 63.3991 37.4319 67.9735 29.4725 69.9923C21.1261 67.973 14.3579 63.3976 9.57592 57.2853C4.74771 51.1139 1.9443 43.3759 1.98121 35.1146V35.1104L1.9812 12.1559ZM1.90918 12.1827C1.90886 12.1829 1.90854 12.183 1.90822 12.1831L1.90918 12.1827Z" className="icon--custom-color" fill="#22277A" stroke="white" strokeWidth="1.87818" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.47973 11.3029C1.3153 11.3641 1.18114 11.471 1.08748 11.6058C0.993721 11.7407 0.940516 11.9036 0.941031 12.0753L0.941043 35.1104C0.903177 43.5894 3.78089 51.5314 8.73522 57.8639C13.6884 64.1951 20.7174 68.9176 29.3766 70.959C37.6405 68.9176 44.6694 64.1951 49.6226 57.8639C54.577 51.5314 57.4547 43.5894 57.4168 35.1104V12.0831C57.4173 11.9036 57.3641 11.7407 57.2704 11.6058C57.1767 11.471 57.0426 11.3641 56.8811 11.304L29.4945 1.08239C29.2897 1.0142 29.0681 1.0142 28.8633 1.08239L1.47973 11.3029Z" stroke="#333333" strokeWidth="1.5" />
    <ellipse cx="29.1789" cy="29.7292" rx="14.6081" ry="14.6081" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M24.5317 30.0514L27.6115 33.2568L34.1559 26.4453" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

module.exports = Shield;
