const React = require('react');
const PropTypes = require('prop-types');
const uuid = require('uuid/v4');

const List = require('@andes/list');

const EditButton = require('./Edit');
const Editable = require('../Editable').default;

const { ListItem } = List;

class EditDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showList: false };

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onMouseDownHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }

  onClickHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.setState({ showList: !this.state.showList });
  }

  render() {
    const { label, options } = this.props;
    return (
      <>
        <EditButton label={label} onClick={this.onClickHandler} onMouseDown={this.onMouseDownHandler} />
        {this.state.showList && (
          <List className="editable-button--list scale-up">
            {options.map(option => (
              <Editable
                type={option.type}
                payload={option.payload}
                key={uuid()}
              >
                <ListItem
                  primary={option.label}
                  onClick={this.onClickHandler}
                  selected={option.selected}
                />
              </Editable>
            ))}
          </List>
        )}
      </>
    );
  }
}

EditDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      payload: PropTypes.shape(),
    }),
  ),
};

EditDropdown.defaultProps = {
  label: '',
  options: [],
};

module.exports = EditDropdown;
