const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const metadataPropType = require('../metadataProvider/metadataPropType');

const KEYS = require('../../utils/constants/keys');

const TabList = ({ tabs, carouselActive, toggleCarousel }, { metadata }) => {
  const { layoutTheme } = metadata;
  const keyHandler = (event) => {
    if ((event.key === KEYS.ENTER.KEY) || (event.keyCode === KEYS.ENTER.KEY_CODE)) {
      toggleCarousel(event);
    }
  };

  return (
    <ul className="carousel-with-tabs__tabs-list">
      {
        tabs.map(tab => {
          const isTabActive = (carouselActive === tab.id && tabs.length > 1);
          const tabClasses = classNames(
            'tabs-list__item', {
              'tabs-list__item--without-underline': (tabs.length === 1),
              'tabs-list__item--active': isTabActive,
              'header--custom-background': isTabActive && (layoutTheme === 'explorer'),
            },
          );
          const tabLabelClasses = classNames({
            'header--custom-text': isTabActive && (layoutTheme === 'explorer'),
          });

          if (tab.items.length === 0) {
            return null;
          }

          return (
            <li className={tabClasses}>
              <div
                className={tabLabelClasses}
                id={tab.id}
                onClick={toggleCarousel}
                onKeyDown={keyHandler}
                role="button"
                tabIndex="-1"
              >
                {tab.label}
              </div>
            </li>
          );
        })
      }
    </ul>
  );
};

TabList.propTypes = {
  toggleCarousel: PropTypes.func.isRequired,
  carouselActive: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

TabList.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = TabList;
