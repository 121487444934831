const PropTypes = require('prop-types');

/**
 * Module dependencies
 */
const React = require('react');
const StarHalfIcon = require('./glyphs/star-half');
const StarFullIcon = require('./glyphs/star-full');

/**
 * Sub components: Stars
 */
const Full = () => (
  <StarFullIcon className="ui-review-star ui-review-star__full" />
);

const Empty = () => (
  <StarFullIcon className="ui-review-star ui-review-star__empty" />
);

const Half = () => (
  <span className="ui-review-star ui-review-star__half">
    <StarHalfIcon className="ui-review-star ui-review-star__half-icon" />
    <Empty />
  </span>
);

/**
 * Review Component
 */
const Review = ({ className, stars, total }) => {
  let classNames = 'ui-review';
  if (className) classNames += ` ${className}`;

  const full = Math.floor(stars);
  const half = Math.ceil(stars - full);
  const empty = 5 - half - full;

  const starsList = [];
  for (let f = 0; f < full; f += 1) {
    starsList.push(<Full />);
  }
  starsList.push(<Half />);
  for (let e = 0; e < empty; e += 1) {
    starsList.push(<Empty />);
  }

  return (
    <p className={classNames}>{starsList} <span className="ui-review-total">{total}</span></p>
  );
};

Review.propTypes = {
  className: PropTypes.string.isRequired,
  stars: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
};

/**
 * Expose Review
 */
module.exports = Review;
