/* eslint-disable max-len */
const React = require('react');

const Delete = () => (
  <svg version="1.1" width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-565 -965)" fill="#3483FA">
        <g transform="translate(548 957)">
          <g transform="translate(23.5 16) scale(-1 1) translate(-6.5 -8)">
            <path d="m4.3433 2.6003v-0.30067c0-1.1598 0.9402-2.1 2.1-2.1h1.1165c1.1598 0 2.1 0.9402 2.1 2.1v0.30067h3.3326v1.2h-0.73872v9.8973c0 1.1598-0.9402 2.1-2.1 2.1h-6.4546c-1.1598 0-2.1-0.9402-2.1-2.1v-9.8973h-0.61137v-1.2h3.3557zm1.2 0h2.9165v-0.30067c0-0.49706-0.40294-0.9-0.9-0.9h-1.1165c-0.49706 0-0.9 0.40294-0.9 0.9v0.30067zm-2.7443 1.2v9.8973c0 0.49706 0.40294 0.9 0.9 0.9h6.4546c0.49706 0 0.9-0.40294 0.9-0.9v-9.8973h-8.2546zm2.9916 8.3991h-1.2v-5.9995h1.2v5.9995zm3.596 0h-1.2v-5.9995h1.2v5.9995z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

module.exports = Delete;
