const React = require('react');
const PropTypes = require('prop-types');

const Item = require('../item');
const withTracker = require('../with-tracker');
const withTrackerOnPrint = require('../with-tracker-on-print');
const Section = require('../section');
const { DEVICE_TYPE_MOBILE, IMG_SIZE_SMALL, IMG_SIZE_LARGE } = require('../../utils/constants/tabbedCarousel');

const RecommendationsMobile = (props) => {
  const { tabs, type } = props;

  return (
    <Section type={type}>
      <div className="row container carousel-with-tabs-mobile">
        {
          tabs.map((tab) => {
            const items = tab.items.map(item => ({
              ...item,
              lazyLoad: props.lazyLoad,
              metadata: props.metadata,
              restClient: props.restClient,
              imgSize: props.deviceType === DEVICE_TYPE_MOBILE ? IMG_SIZE_SMALL : IMG_SIZE_LARGE,
            }));

            return (
              <>
                <h2>{tab.label}</h2>
                <div className="carousel-with-tabs-mobile__list">
                  {
                    items.map(item => (
                      <Item {...item} />
                    ))
                  }
                </div>
              </>
            );
          })
        }
      </div>
    </Section>
  );
};

RecommendationsMobile.propTypes = {
  type: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = withTracker(withTrackerOnPrint(RecommendationsMobile));
