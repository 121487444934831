/**
 * startApp dependency
 */
const startApp = require('../startApp');

/**
 * Main component
 */
const HomeViewDesktop = require('../../pages/home/components/HomeView.desktop');

startApp(HomeViewDesktop);
