/* eslint-env browser */
const logger = require('nordic/logger')('services/messenger');

const VALID_PROD_ORIGIN = /^https:\/\/[a-zA-Z0-9-.]+\.mercadoli[vb]re\.(?:com\.(?:ar|br|co|mx)|cl)$/;

// eslint-disable-next-line max-len
const VALID_DEV_ORIGIN = /^https:\/\/[a-zA-Z0-9-.]+\.mercadoli[vb]re\.(?:com\.(?:ar|br|co|mx)|cl):8444/;
const VALID_DEV_PARENT = /^https:\/\/[a-zA-Z0-9-.]+\.mercadoli[vb]re\.(?:com\.(?:ar|br|co|mx)|cl):8443/;

// IMPORTANT: Check the origin of the data!
const matchs = (origin, pattern) => origin.search(pattern) !== -1;

const isDevEnvironment = () => (typeof window === 'object'
  ? matchs(window.location.href, VALID_DEV_PARENT)
  : false);

class Messenger {
  constructor(data, origin) {
    this.data = data;
    this.origin = origin;
  }

  static isValidEvent(event = {}) {
    const { data, origin = '' } = event;
    const msgn = new Messenger(data, origin);

    return msgn.isValidData() && msgn.isValidOrigin();
  }

  isValidData() {
    return !!this.data;
  }

  isValidOrigin() {
    if (isDevEnvironment()) {
      return this.isValidOriginOnDev();
    }

    return this.isValidOriginOnProd();
  }

  isValidOriginOnProd() {
    return matchs(this.origin, VALID_PROD_ORIGIN);
  }

  isValidOriginOnDev() {
    const data = typeof this.data !== 'object' ? { data: this.data } : this.data;

    if (matchs(this.origin, VALID_DEV_ORIGIN)) {
      logger.info('[development-mode]', data);
      return true;
    }

    logger.warn(
      `Signals from an invalid origin [${this.origin}, data: ${data}]`,
    );
    return false;
  }
}

module.exports = Messenger;
