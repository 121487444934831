const types = require('../Editable/types');

const getCarouselProps = ({
  componentId,
  title,
  id,
  values,
  display,
  width,
  empty_state_card: emptyStateCard,
}) => ({
  type: types.CAROUSEL,
  payload: { componentId, title, id, values, display, width, emptyStateCard },
});

// We explicitly say that length must be greater than 0 to avoid strange javascript type casting
const renderWrapper = props => props.items && (props.items.length > 0);

module.exports = { getCarouselProps, renderWrapper };
