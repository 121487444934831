const types = require('../Editable/types');

const getCategoryCarouselProps = ({
  componentId,
  title,
  show_title: showTitle,
  sub_title: subTitle,
  show_sub_title: showSubTitle,
  categories,
  show_button: showButton,
  show_price: showPrice,
  columns,
  button_text: buttonText,
}) => ({
  type: types.CATEGORY_CAROUSEL_V2,
  payload: {
    componentId,
    title,
    subTitle,
    showTitle,
    showSubTitle,
    categories,
    showButton,
    showPrice,
    columns,
    buttonText,
  },
});

module.exports = getCategoryCarouselProps;
