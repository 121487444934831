module.exports = {
  FAVICON: {
    href: 'https://http2.mlstatic.com/storage/mshops-appearance-api/resources/sections/home/favicon-v1.png',
    type: 'image/png',
  },
  COOKIES: {
    D2ID: '_d2id',
    CP: 'cp',
    SSID: 'ssid',
  },
  TRACK_COOKIES_TITLE: 'TrackCookies',
  DROPOUT_TITLE: 'TrackDropout',
  DROPOUT_COMPLETED_PURCHASE: 'MShopsCompletedPurchase',
  DROPOUT_INDICATION: 'MShopsDropoutIndication',
  DROPOUT_PREFIX: 'DROPOUT_',
  THEMES: {
    MATERIAL: 'material',
  },
  EDITABLE_MODE: {
    COMPONENTS: 'COMPONENTS',
    LAYOUT: 'LAYOUT',
  },
  NOT_ORDERABLE_COMPONENTS: ['DebugInfo', 'DebugInfoEditable'],
  NOT_REMOVABLE_COMPONENTS: [
    'PaymentMethods', 'PaymentMethodsEditable',
    'ShoppingInfo', 'ShoppingInfoEditable',
    'BuyerInfo', 'BuyerInfoEditable',
    'Carousel', 'CarouselEditable',
  ],
};
