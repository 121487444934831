const types = require('../Editable/types');

const getContentListProps = ({
  componentId,
  elements,
  title,
}) => ({
  type: types.CONTENT_LIST,
  payload: { componentId, elements, title },
});

module.exports = { getContentListProps };
