const React = require('react');
const Props = require('./props');

const StarHalf = ({ className }) => (
  <svg className={className} viewBox="0 0 32 32">
    <path d="M16 25.2L6 30.5l2-11-8-7.7 11-2L16 0l.7 1.5v23z" />
  </svg>
);

StarHalf.propTypes = Props.propTypes;
StarHalf.defaultProps = Props.defaultProps;

module.exports = StarHalf;
