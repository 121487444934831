const React = require('react');
const PropTypes = require('prop-types');

const Question = ({ title, text }) => (
  <div className="info-section__question">
    <h3>{title}</h3>
    {/* eslint-disable-next-line react/no-danger */}
    <div dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

Question.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

module.exports = Question;
