const PropTypes = require('prop-types');

const metadataPropType = PropTypes.shape({
  currency: PropTypes.shape({
    decimal_places: PropTypes.number.isRequired,
    decimal_separator: PropTypes.string.isRequired,
    thousand_separator: PropTypes.string.isRequired,
  }),
  deviceType: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  siteKey: PropTypes.string.isRequired,
  restClient: PropTypes.shape({
    baseURL: PropTypes.string.isRequired,
    timeout: PropTypes.number.isRequired,
  }),
  fiscalData: PropTypes.shape({
    business_name: PropTypes.string,
    business_doc: PropTypes.string,
    qr_id: PropTypes.string,
  }),
  layoutTheme: PropTypes.string,
});

module.exports = metadataPropType;
