/**
 * ui map dependencies
 */
require('ui-map/ui-publish/styles/ui-map.css');
require('ui-map/ui-publish/scripts/ui-map');

/**
 * Module dependencies
 */
const React = require('react');
const ReactDOM = require('react-dom');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const newRelicErrorHandler = require('../utils/services/newRelic/errorHandler');

function startApp(Component) {
  /**
   * Get server state
   */
  const {
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    translations,
    shopModel,
    config,
    editable,
    section,
    allowMetrics,
    siteKey,
    apiBasePath,
  } = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

  /**
   * i18n
   */
  const i18n = new I18n({ translations });

  if (window && window.newrelic) {
    window.newrelic.setErrorHandler(newRelicErrorHandler);
  }

  /**
   * Mount HomeView on client
   */
  ReactDOM.hydrate(
    <I18nProvider i18n={i18n}>
      <Component
        site={site}
        siteId={siteId}
        lowEnd={lowEnd}
        deviceType={deviceType}
        company={company}
        shopModel={shopModel}
        config={config}
        editable={editable}
        section={section}
        allowMetrics={allowMetrics}
        siteKey={siteKey}
        apiBasePath={apiBasePath}
      />
    </I18nProvider>,
    document.getElementById('root-app'),
  );
}

module.exports = startApp;
