const React = require('react');
const PropTypes = require('prop-types');

const Layout = require('.');
const withEditable = require('../../adapters/withEditable');
const MessengerService = require('../../utils/messengerService');

const { useRenderDispatcher, useRenderContext } = require('../../pages/home/context');

const types = require('../Editable/types');
const { LAYOUT_ACTIONS, LAYOUT_UPDATE_MODES } = require('./layoutActions');

const { useEffect } = React;

const LayoutEditable = (props) => {
  const { sendMessage } = props;
  const { appearance, fetching } = useRenderContext();
  const renderActions = useRenderDispatcher();

  const handleLayoutMessage = (event) => {
    if (!MessengerService.isValidEvent(event)) {
      return null;
    }

    const { message, action } = event.data;
    switch (action) {
      case LAYOUT_ACTIONS.CHANGE_EDITABLE_MODE: {
        return renderActions.changeEditableMode(message);
      }
      case LAYOUT_ACTIONS.LAYOUT_ORDER: {
        const { componentId, position } = message;
        return renderActions.reorderLayoutComponent(componentId, position);
      }
      case LAYOUT_ACTIONS.RESET_LAYOUT: {
        return renderActions.resetLayout();
      }
      case LAYOUT_ACTIONS.SAVE_LAYOUT: {
        return renderActions.saveLayout();
      }
      case LAYOUT_ACTIONS.LAYOUT_HIDDEN_COMPONENT: {
        const { componentId, hidden } = message;
        return renderActions.setComponentHidden(componentId, hidden);
      }
      case LAYOUT_ACTIONS.LAYOUT_REMOVE_COMPONENT: {
        const { componentId } = message;
        return renderActions.removeLayoutComponent(componentId);
      }
      case LAYOUT_ACTIONS.UPDATE_FROM_SERVER: {
        const {
          type = LAYOUT_UPDATE_MODES.FULL,
          section = 'home',
          device = 'desktop',
          componentId,
        } = message;
        return renderActions.getLayoutFromServer(type, section, device, componentId);
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleLayoutMessage, false);

    return function cleanUp() {
      window.removeEventListener('message', handleLayoutMessage);
    };
  }, []);

  useEffect(() => {
    sendMessage(
      types.LAYOUT_UPDATE,
      {
        layout: appearance.getTree(),
        condensed: appearance.getCondensedTree(),
      },
    );
  });

  return (
    <Layout {...props} fetching={fetching} />
  );
};

LayoutEditable.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  sendMessage: PropTypes.func.isRequired,
  empty_state_card: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    fees: PropTypes.string,
    cta: PropTypes.string,
  }).isRequired,
};

module.exports = withEditable(LayoutEditable);
