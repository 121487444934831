const React = require('react');
const PropTypes = require('prop-types');

const ShoppingInfo = require('../../components/site-shopping-info/mobile');
const metadataPropType = require('../../components/metadataProvider/metadataPropType');

function MobileShoppingInfo({ elements }) {
  return (
    <div id="section-info">
      <ShoppingInfo
        type="site-shopping-info"
        lazyLoad={false}
        elements={elements}
      />
    </div>
  ); // FIXME: If lazyLoad is not false, the tests will fail (check why)
}

MobileShoppingInfo.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

MobileShoppingInfo.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = MobileShoppingInfo;
