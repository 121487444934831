const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Image = require('../../components/image');

const FakeOriginal = ({ type, emptyStateCard }) => {
  const imageClass = classNames(
    'label-image',
    'label-image--fake',
    `label-image--${type}`,
    'label-image--behavior-show',
  );
  const labelClass = classNames('label-image__text', 'label-image__text--dark');
  const labelBackgroundClass = classNames('label-image__background');

  return (
    <div className={imageClass}>
      <div className={labelBackgroundClass}>
        { emptyStateCard && (
          <>
            <div className="image-container">
              <Image
                className="image-container--center"
                src={emptyStateCard.icon}
                alt={emptyStateCard.title}
              />
            </div>
            <div className={labelClass}>
              <h4>{emptyStateCard.title}</h4>
            </div>
          </>
        )
      }
      </div>
    </div>
  );
};

FakeOriginal.propTypes = {
  type: PropTypes.oneOf(['small', 'large']).isRequired,
  emptyStateCard: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    cta: PropTypes.string,
  }),
};

FakeOriginal.defaultProps = {
  emptyStateCard: null,
};

module.exports = FakeOriginal;
