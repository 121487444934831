const React = require('react');
const ReactDOM = require('react-dom');
const displayName = require('../display-name');
const global = require('../../../global');

function withLifeCycleMobile(WrappedComponent) {
  class WithLifeCycle extends React.Component {
    constructor(props) {
      super(props);
      this.state = { mounted: false };
    }

    componentDidMount() {
      if ('IntersectionObserver' in global) {
        this.observer = new global.IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({ mounted: true });
                this.observer.unobserve(this.element);
                this.observer.disconnect();
              }
            });
          },
          { threshold: 0.25 },
        );
        // eslint-disable-next-line
        this.element = ReactDOM.findDOMNode(this);
        if (this.element) {
          // element is null when WrappedComponent returns null
          this.observer.observe(this.element);
        }
      } else {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ mounted: true });
      }
    }

    render() {
      const { mounted } = this.state;
      return <WrappedComponent {...this.props} mounted={mounted} />;
    }
  }

  WithLifeCycle.displayName = displayName(WithLifeCycle, WrappedComponent);

  return WithLifeCycle;
}

module.exports = withLifeCycleMobile;
