const Action = require('../action');
const CarouselDraggable = require('ui-library_ml/ui/carousel-draggable');
const Item = require('../item/mobile');
const PropTypes = require('prop-types');
const React = require('react');
const withTracker = require('../with-tracker');
const withTrackerOnPrint = require('../with-tracker-on-print');
const RecommendationsContainer = require('./recommendations-container');

const viewMoreSlides = {};
viewMoreSlides['week-deal'] = require('./view-more/week-deal');

function RecommendationsMobile(props) {
  const imgSize = props.deviceType === 'mobile' ? '160' : '224';

  const extendedItems = props.items.map((item, i) => ({
    ...item,
    lazyLoad: props.lazyLoad,
    metadata: props.metadata,
    restClient: props.restClient,
    isFirstPage: i <= 3, // Amount of item images that we want them to act as a first page
    imgSize,
  }));

  const ViewMoreSlide = viewMoreSlides[props.id];
  if (ViewMoreSlide && props.view_more_element_action) {
    extendedItems.push({
      Component: ViewMoreSlide,
      lazyLoad: props.lazyLoad,
      view_more_action: props.view_more_action,
      ...props.view_more_element_action,
    });
  }

  return (
    <RecommendationsContainer {...props}>
      <CarouselDraggable slideTemplate={Item} slides={extendedItems} />
    </RecommendationsContainer>
  );
}

RecommendationsMobile.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  restClient: PropTypes.object.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes),
  view_more_element_action: PropTypes.shape(Action.propTypes),
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

RecommendationsMobile.defaultProps = {
  view_more_action: null,
  view_more_element_action: null,
};

module.exports = withTracker(withTrackerOnPrint(RecommendationsMobile));
