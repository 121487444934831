const React = require('react');
const PropTypes = require('prop-types');

const COLOR_HIGHLIGHTED = '#3483FA';
const COLOR_NORMAL = '#000';

const Eye = ({ highlighted, width, height, viewBox }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <g fill="none" fillRule="evenodd" stroke={highlighted ? COLOR_HIGHLIGHTED : COLOR_NORMAL} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="M14.86 14.86A8.24 8.24 0 0 1 10 16.545C4.273 16.545 1 10 1 10a15.095 15.095 0 0 1 4.14-4.86m3.142-1.49A7.462 7.462 0 0 1 10 3.456c5.727 0 9 6.545 9 6.545a15.136 15.136 0 0 1-1.767 2.61m-5.498-.875a2.455 2.455 0 1 1-3.47-3.47M1 1l18 18" />
    </g>
  </svg>
);

Eye.propTypes = {
  highlighted: PropTypes.bool,
};

Eye.defaultProps = {
  highlighted: false,
  width: '20',
  height: '20',
  viewBox: '0 0 20 20',
};

module.exports = Eye;
