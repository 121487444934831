const PropTypes = require('prop-types');
const React = require('react');

// eslint-disable-next-line no-undef
const goToAction = (url) => { window.location = url; };

function Action({ label, onClick, target }) {
  return (
    <button
      className="ui-item__action"
      onClick={onClick || ((e) => {
        e.preventDefault();
        goToAction(target);
      })}
    >{label}
    </button>
  );
}

Action.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

Action.defaultProps = {
  onClick: null,
  target: null,
};

module.exports = Action;
