const LAYOUT_ACTIONS = {
  CHANGE_EDITABLE_MODE: 'CHANGE_EDITABLE_MODE',
  LAYOUT_ORDER: 'LAYOUT_ORDER',
  RESET_LAYOUT: 'RESET_LAYOUT',
  SAVE_LAYOUT: 'SAVE_LAYOUT',
  LAYOUT_HIDDEN_COMPONENT: 'LAYOUT_HIDDEN_COMPONENT',
  LAYOUT_REMOVE_COMPONENT: 'LAYOUT_REMOVE_COMPONENT',
  UPDATE_FROM_SERVER: 'UPDATE_FROM_SERVER',
};

const LAYOUT_UPDATE_MODES = {
  FULL: 'FULL', // Full update layout structure
  PARTIAL: 'PARTIAL', // Update only given component props
};

module.exports = { LAYOUT_ACTIONS, LAYOUT_UPDATE_MODES };
