const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Action = require('../action');

const { hasGivenTheme } = require('../../utils/validateTheme');
const { THEMES } = require('../../utils/constants/common');

const ContentContainer = require('./ContentContainer');

const { MATERIAL } = THEMES;

const LabelImage = (props) => {
  const { styles, variations, content, display } = props;
  const { link, behavior } = content;
  const isMaterialTheme = hasGivenTheme(display, MATERIAL);

  const imageClass = classNames(
    'label-image',
    `label-image--${variations}`,
    `label-image--behavior-${behavior}`, {
      'label-image__material-container--small': (isMaterialTheme && (variations === 'small')),
      'label-image__material-container--large': (isMaterialTheme && (variations === 'large')),
    },
  );

  return (
    <Action className={imageClass} target={link.url}>
      <ContentContainer
        display={display}
        styles={styles}
        variations={variations}
        content={content}
      />
    </Action>
  );
};

LabelImage.propTypes = {
  styles: PropTypes.shape({
    background_image: PropTypes.string,
  }).isRequired,
  variations: PropTypes.string.isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
      onlyLink: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light', 'custom']),
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
  }).isRequired,
};

module.exports = LabelImage;
