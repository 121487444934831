const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const types = require('../Editable/types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const EditableButtons = require('../EditableButtons');
const Editable = require('../Editable').default;

const Wrapper = (props, context) => {
  const {
    componentId,
    id,
    image_url: imageUrl,
    slider_properties: sliderProperties,
    hovered,
    alignment,
    i18n,
  } = props;
  const { slides, size } = sliderProperties || {};
  const bannerRef = React.createRef();
  const { layoutTheme } = context.metadata;

  const bannerWrapperClassNames = classNames('editable-wrapper banner--editable', {
    'banner--editable-hovered': hovered,
    'banner--editable-simple': (layoutTheme === 'simple'),
  });
  const editableProps = {
    label: i18n.gettext('Editar'),
    type: types.BANNER,
    options: [{
      label: i18n.gettext('Imagen decorativa'),
      type: types.BANNER,
      payload: { componentId, id, imageUrl, alignment },
      wrapperRef: bannerRef,
      selected: true,
    }, {
      label: i18n.gettext('Carrusel de diapositivas'),
      type: types.SLIDER,
      payload: { componentId: 'slider', slides, size } },
    ],
  };
  return (
    <div className={bannerWrapperClassNames} ref={bannerRef}>
      <Editable
        type={editableProps.type}
        payload={editableProps.options[0].payload}
        className="editable_fullheight"
        wrapperRef={bannerRef}
      >
        <EditableButtons
          editButton={editableProps}
          showDelete={false}
        />
      </Editable>
    </div>
  );
};

Wrapper.propTypes = {
  hovered: PropTypes.bool.isRequired,
  i18n: PropTypes.shape(),
  componentId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
  slider_properties: PropTypes.shape({
    size: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      action: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        url_alternative: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        alignment: PropTypes.oneOf(['top', 'center', 'bottom']),
      }),
      alt: PropTypes.string,
      text_alignment: PropTypes.oneOf(['left', 'center', 'right']),
    })),
  }).isRequired,
};

Wrapper.defaultProps = {
  i18n: { gettext: f => f },
};

Wrapper.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Wrapper;
