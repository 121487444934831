const React = require('react');
const classNames = require('classnames');

const MessengerService = require('../utils/messengerService');

const metadataPropType = require('../components/metadataProvider/metadataPropType');

const { useEffect, useState } = React;

function withHover(WrappedComponent) {
  const Hovered = (props) => {
    const [hovered, setHovered] = useState(false);

    const postMessageHover = mouseEnter => {
      window.parent.postMessage(
        {
          message: { componentId: mouseEnter ? props.componentId : '' },
          action: 'SECTION_HOVER',
        },
        '*',
      );
    };

    const setHoveredStatusOn = () => {
      postMessageHover(true);
      setHovered(true);
    };

    const setHoveredStatusOff = () => {
      postMessageHover(false);
      setHovered(false);
    };

    const handleLayoutMessage = (event) => {
      if (!MessengerService.isValidEvent(event)) {
        return;
      }

      const { message, action } = event.data;
      if (action === 'SECTION_HOVER') {
        const { componentId, mouseEnter } = message;
        if (componentId === props.componentId) {
          setHovered(mouseEnter);
        }
      }
    };

    useEffect(() => {
      window.addEventListener('message', handleLayoutMessage, false);

      return function cleanUp() {
        window.removeEventListener('message', handleLayoutMessage);
      };
    }, []);

    const containerHoverClassNames = classNames('dragable-component__container', {
      'dragable-component__container--hovered': hovered,
    });

    return (
      <div
        className={containerHoverClassNames}
        onMouseEnter={setHoveredStatusOn}
        onMouseLeave={setHoveredStatusOff}
      >
        <WrappedComponent
          {...props}
          hovered={hovered}
        />
      </div>
    );
  };

  Hovered.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return Hovered;
}

module.exports = withHover;
