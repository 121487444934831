/* global window */
const React = require('react');
const PropTypes = require('prop-types');

const types = require('./types');
const MessengerService = require('../../utils/messengerService');
const { ARROW_SIZE } = require('../../utils/constants/slider');
const { DEVICE_TYPE_MOBILE } = require('../../utils/constants/tabbedCarousel');

const withEditable = require('../../adapters/withEditable');
const { useRenderDispatcher } = require('../../pages/home/context');

const { useEffect } = React;

const Editable = ({
  className,
  style,
  children,
  wrapperRef,
  type,
  payload,
  targets,
  deviceType,
  sendMessage,
}) => {
  let slider;
  let sliderPosition;

  const renderActions = useRenderDispatcher();

  const sendMessageByTarget = (event, messageTargets) => {
    const classList = (event.target.className || '').split(' ');

    classList.forEach((targetClass) => {
      const target = messageTargets[targetClass];
      if (target) {
        sendMessage(target.type, target.payload);
      }
    });
  };

  const getSliderPosition = () => {
    slider = document.querySelector('.slider');
    sliderPosition = slider && slider.getBoundingClientRect();
  };

  const isSliderWithArrows = (event) => {
    const { clientX } = event;

    const isTypeSlider = (type === types.SLIDER);
    const hasMoreThanOneSlide = payload.slides && (payload.slides.length > 1);
    const isNotMobile = (deviceType !== DEVICE_TYPE_MOBILE);

    return (
      isTypeSlider
      && hasMoreThanOneSlide
      && isNotMobile
      && sliderPosition
      && (clientX < sliderPosition.left + ARROW_SIZE || clientX > sliderPosition.right - ARROW_SIZE)
    );
  };

  const getWrapperCoords = () => {
    const coords = wrapperRef && wrapperRef.current && wrapperRef.current.getBoundingClientRect();
    // Firefox does not allow to send in postMessage a DOMRect object
    // so we do a destructure here to change its type from DOMRect to a JSON
    return coords ? { top: coords.top, left: coords.left, width: coords.width, height: coords.height } : {};
  };

  const onMouseDown = (event) => {
    event.preventDefault();
    // We do this to prevent the sidebar from opening when clicking on the arrows on the slider
    if (isSliderWithArrows(event)) {
      return;
    }

    if (targets) {
      sendMessageByTarget(event, targets);
    } else {
      const coords = getWrapperCoords();
      sendMessage(type, payload, coords);
    }
  };

  const addComponent = (event) => {
    if (!MessengerService.isValidEvent(event)) {
      return;
    }

    const { data } = event;

    const { action, message } = data;
    if (action === types.CHANGE_SCOPE && message === payload.componentId) {
      sendMessage(type, payload);
      const { componentId } = payload;
      renderActions.moveToComponent(componentId);
    }
  };

  useEffect(() => {
    window.addEventListener('message', addComponent);

    return function cleanUp() {
      window.removeEventListener('message', addComponent);
    };
  }, []);

  useEffect(() => {
    getSliderPosition();
  });

  return (
    <div
      ref={wrapperRef}
      className={className}
      style={style}
      role="button"
      tabIndex="-1"
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
};

Editable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  sendMessage: PropTypes.func.isRequired,
  payload: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  type: PropTypes.string,
  targets: PropTypes.objectOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    payload: PropTypes.any,
  })),
  style: PropTypes.shape({}),
  wrapperRef: PropTypes.shape(),
  deviceType: PropTypes.string.isRequired,
};

Editable.defaultProps = {
  className: '',
  children: null,
  payload: {},
  targets: null,
  type: null,
  style: {},
  wrapperRef: null,
};

module.exports = {
  default: withEditable(Editable),
  Editable,
};
