/* eslint-disable max-len */
module.exports = i18n => ({
  title: i18n.gettext('Preguntas frecuentes'),
  faqs: [
    {
      title: i18n.gettext('¿Cómo realizo el pago si no tengo cuenta en Mercado Libre?'),
      noGuest: i18n.gettext(
        '¡Crear una cuenta es muy fácil! Al momento de comprar, te redirigiremos a Mercado Libre '
        + 'para que la crees. Sólo tienes que ingresar un e-mail, una clave y continuar con tu compra.',
      ),
    },
    {
      title: i18n.gettext('¿Dónde veo el costo de envío y el tiempo de entrega?'),
      text: i18n.gettext(
        'En la página del producto que quieras comprar verás la opción de calcular cuándo llega, con la '
        + 'información sobre el costo de envío y el tiempo de entrega.',
      ),
    },
    {
      title: i18n.gettext('¿Puedo pagar en cuotas? '),
      text: i18n.gettext(
        'Podrás ver en la página del producto si se puede pagar en cuotas y con qué medios. Si decides comprarlo, '
        + 'antes de pagar podrás elegir la cantidad de cuotas y te informaremos el valor de cada una.',
      ),
    },
    {
      title: i18n.gettext('¿Cómo puedo realizar cambios y devoluciones?'),
      text: i18n.gettext(
        'Para hacer un cambio o devolución, contáctanos para que podamos ayudarte. Si compras con tu cuenta '
        + 'de Mercado Libre y no es lo que esperabas, recibirás el dinero devuelta.',
      ),
      noGuest: i18n.gettext(
        'Los cambios y devoluciones de productos se realizan a través de Mercado Libre. Puedes hacerlo en la versión '
        + 'de escritorio o la aplicación desde la sección Mis Compras, '
        + 'seleccionando el producto y luego eligiendo la opción Devolver el producto. Te daremos una etiqueta de '
        + 'envío para que imprimas y pegues en el paquete para llevarlo al correo ¡y listo!',
      ),
    },
  ],
});
