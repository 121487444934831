const React = require('react');

const PropTypes = require('prop-types');
const classNames = require('classnames');
const Image = require('nordic/image');

const Clickable = require('@mshops-web-components/clickable').default;

const { TYPES } = require('../../utils/constants/components/message');

const { info, warning, error } = TYPES;

const Message = ({
  icon,
  link,
  payload,
  scope,
  sendMessage,
  text,
  type,
  wrapperRef,
}) => {
  const mainClass = classNames('message-container', {
    'message-container--info': (type === info),
    'message-container--warning': (type === warning),
    'message-container--error': (type === error),
  });

  const getWrapperCoords = () => {
    const coords = wrapperRef?.current?.getBoundingClientRect();
    return coords ? { top: coords.top, left: coords.left, width: coords.width, height: coords.height } : {};
  };

  const triggerSendMessage = () => {
    if (scope && payload && sendMessage) {
      const coords = getWrapperCoords();
      sendMessage(scope, payload, coords);
    }
  };

  const action = type === info ? (
    <Clickable className="message-container__button" onClick={triggerSendMessage}>
      {link?.text}
    </Clickable>
  ) : (
    <a href={link?.url} target="_blank" rel="noopener noreferrer">
      {link?.text}
    </a>
  );

  return (
    <div className={mainClass}>
      <Image src={icon} width="24" alt="message-icon" />
      <span className="message-container__text">
        {text}
        {link && (
          action
        )}
      </span>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  type: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  wrapperRef: PropTypes.shape(),
  scope: PropTypes.string,
  payload: PropTypes.shape(),
  sendMessage: PropTypes.func,
};

Message.defaultProps = {
  type: info,
  link: null,
  wrapperRef: null,
  scope: null,
  payload: null,
  sendMessage: () => {},
};

module.exports = Message;
