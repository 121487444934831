const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');

const Action = require('../action');

const ContentSlide = ({
  align,
  button,
  description,
  deviceType,
  elementTitle,
  image,
}) => (
  <div className={`content-element content-element--align-${align}`}>
    {!image.hidden && (
      <div className="content-element__img-container">
        <Image
          className="content-element__image"
          src={deviceType === 'desktop' ? image.desktop : image.mobile}
          alt={elementTitle.text}
        />
      </div>
    )}
    <div className="content-element__container-text">
      {!elementTitle.hidden && <h4 className="content-element__title">{elementTitle.text}</h4>}
      {!description.hidden && <p className="content-element__description">{description.text}</p>}
      {!button.hidden && (
        <Action
          className="content-element__button-container"
          target={button.button_href}
        >
          <p className="content-element__button-text">{button.button_text}</p>
        </Action>
      )}
    </div>
  </div>
);

ContentSlide.propTypes = {
  align: PropTypes.string.isRequired,
  button: PropTypes.shape({
    button_href: PropTypes.string.isRequired,
    button_text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }),
  deviceType: PropTypes.string.isRequired,
  description: PropTypes.shape({
    hidden: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
  elementTitle: PropTypes.shape({
    hidden: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    desktop: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
    mobile: PropTypes.string.isRequired,
  }),
};

ContentSlide.defaultProps = {
  button: null,
  description: null,
  elementTitle: null,
  image: null,
};

module.exports = ContentSlide;
