const React = require('react');
const PropTypes = require('prop-types');

const SlideCTA = ({ className, cta, titleTextColor }) => {
  const textStyles = {
    color: `${titleTextColor}`,
  };

  const borderStyles = {
    border: `1px solid ${titleTextColor}`,
  };

  return (
    <button type="button" className={className} style={borderStyles}>
      <span style={textStyles}>{cta}</span>
    </button>
  );
};

SlideCTA.propTypes = {
  className: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  titleTextColor: PropTypes.string,
};

SlideCTA.defaultProps = {
  titleTextColor: '',
};

module.exports = SlideCTA;
