const React = require('react');
const PropTypes = require('prop-types');

function Row({ children }) {
  return (
    <div>
      {children}
    </div>
  );
}

Row.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

module.exports = Row;
