const React = require('react');
const PropTypes = require('prop-types');

const Button = require('@andes/button');
const VisibilityOFF24 = require('@andes/icons/VisibilityOFF24');

const wordings = require('./wordings');
const { useRenderDispatcher, useRenderContext } = require('../../pages/home/context');
const { EDITABLE_MODE } = require('../../utils/constants/common');

const DisabledWrapper = ({
  children,
  componentId,
  i18n,
}) => {
  const { sectionDisabledText, button } = wordings(i18n);
  const renderActions = useRenderDispatcher();
  const { editableMode } = useRenderContext();
  const isLayoutEditMode = editableMode === EDITABLE_MODE.LAYOUT;
  const handleClick = () => {
    renderActions.setComponentHidden(componentId, false);
  };

  return (
    <div className="hidden-wrapper">
      <div className="hidden-wrapper__children-container">
        {children}
      </div>
      <div className="hidden-wrapper__content-container">
        <VisibilityOFF24 />
        <p className="hidden-wrapper__text">{sectionDisabledText}</p>
        {
          (isLayoutEditMode)
            && (
            <Button className="hidden-wrapper__button" onClick={handleClick}>
              {button.text}
            </Button>
            )
        }
      </div>
    </div>
  );
};

DisabledWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  i18n: PropTypes.shape().isRequired,
};

module.exports = DisabledWrapper;
