const React = require('react');
const { string, bool } = require('prop-types');
const Head = require('react-declarative-head');
const Script = require('nordic/script');

const Recaptcha = ({ siteKey, cspNonce, action, lang }) => {
  const overwriteStyles = {
    grecaptcha: {
      transform: 'scale(0.89)',
      transformOrigin: '0 0',
    },
    container: {
      margin: '30px 0',
      position: 'relative',
    },
  };
  const url = `https://www.google.com/recaptcha/api.js?hl=${lang}&onload=onloadCallback&render=explicit async defer`;

  const markup = () => (
    <div>
      <div
        id="g-recaptcha"
        className="g-recaptcha"
        data-sitekey={siteKey}
        style={overwriteStyles.grecaptcha}
      />
      <Script>
        {`
        window.onloadCallback = function() {
          grecaptcha.enterprise.ready(function() {
            grecaptcha.enterprise.execute('${siteKey}', {action: '${action}'}).then(function(token) {
                // Token is an encrypted response that is sent by reCAPTCHA Enterprise to the end user's browser.
                // Validate the response token by following the instructions
                // to create an assessment.
            });
          });
        }
        var recaptchaScript = document.createElement('script');
        recaptchaScript.src = '${url}';
        recaptchaScript.defer = 1;
        recaptchaScript.async = 1;
        document.head.appendChild(recaptchaScript);
        recaptchaScript.onerror = () => {
          // Error al cargar
          document.getElementById('gctkn').value = 'script-error';
        }
    `}
      </Script>
    </div>
  );

  return (
    <div style={overwriteStyles.container} className="recaptcha-container">
      <Head>
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link nonce={cspNonce} rel="preload" href={url} as="script" />
      </Head>
      { markup() }
    </div>
  );
};

Recaptcha.propTypes = {
  siteKey: string.isRequired,
  required: bool.isRequired,
  action: string.isRequired,
  cspNonce: string,
};

Recaptcha.defaultProps = {
  cspNonce: '',
  lang: 'es',
};

module.exports = Recaptcha;
