/* global google uiMap */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

// const Map = require('../../appearance/Map');

const Address = require('./address');
const Phone = require('./phone');
const Email = require('./email');
const SocialNetworks = require('./social-networks');

const DEFAULT_POSITION = {
  lat: 0,
  lng: 0,
};

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
    // Map configuration
    const { position } = this.props.address || {};
    this.map = {
      ui: {
        zoom: true,
        zoomControlPosition: 'RIGHT_BOTTOM',
        streetView: true,
        streetViewControlPosition: 'LEFT_TOP',
        fullScreen: true,
      },
      accuracyType: 'none',
      position: position || DEFAULT_POSITION,
      zoom: {
        initial: 16,
        max: 18,
        min: 0,
      },
    };

    this.onInitializeMap = this.onInitializeMap.bind(this);
    this.onChangeViewMap = this.onChangeViewMap.bind(this);
    this.onLoadMap = this.onLoadMap.bind(this);
  }

  onInitializeMap(retrieveMap) {
    retrieveMap({
      carousel: null,
      searchbox: null,
    });
  }

  // this event is required for the map wrapper component
  onChangeViewMap() {
    // Do nothing
  }

  // eslint-disable-next-line no-unused-vars
  onLoadMap(event) {
    const infoWindow = new uiMap.InfoWindow({
      customTemplate: `
        <div class="info-window">
          <h3 class="info-window__title">${this.props.shop_name}</h3>
          <p class="info-windows__text">${this.props.address.full_address.value}</p>
        </div>`,
    });

    const mapMarker = {
      scaledSize: new google.maps.Size(48, 48),
    };

    const mapMarkerSelected = {
      scaledSize: new google.maps.Size(48, 48),
    };

    const pin = new uiMap.Pin({
      infoWindow,
      useCustomBrandIcon: false,
      icon: mapMarker,
      iconSelected: mapMarkerSelected,
      iconSelectedBig: mapMarkerSelected,
      location: this.map.position,
    });
    return {
      action: 'setPins',
      pins: [pin],
    };
  }

  render() {
    const { title, address, phone, email, social_networks: socialNetworks } = this.props;
    const showMap = (address && address.position);
    const colDataClassNames = classNames('contact-info__col-data', {
      'contact-info__col-data--full': !showMap,
    });
    const infoBlockClassNames = classNames('contact-info__block', {
      'remark-data': !showMap,
      'remark-address': address,
    });
    return (
      <div className="contact-info">
        <div className={colDataClassNames}>
          {
            !(address && phone && email && socialNetworks)
              ? <h2 className="contact-info__title">{title}</h2> : null
          }
          <div className={infoBlockClassNames}>
            { address ? <Address address={address.full_address} /> : null }
            { phone ? <Phone phone={phone} /> : null }
            { email ? <Email email={email} /> : null }
          </div>
          {
            (socialNetworks && socialNetworks.value.length > 0)
              ? <SocialNetworks socialNetworks={socialNetworks} /> : null
          }
        </div>
        { /* ***
           We hide the map due to inconsistencies in address position
          TODO: re add it after the problem is fixed
          *** */
          /*
          (address && address.position) ?
            <div className="ui-map-contain--fit">
              <Map
                position={this.map.position}
                accuracyType={this.map.accuracyType}
                zoom={this.map.zoom}
                ui={this.map.ui}
                onChangeView={this.onChangeViewMap}
                onLoad={this.onLoadMap}
                onInitialize={this.onInitializeMap}
              />
            </div> : null
        */}
      </div>
    );
  }
}

ContactInfo.propTypes = {
  shop_name: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.shape({
    full_address: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    map_marker: PropTypes.shape({
      '1x': PropTypes.string,
      '2x': PropTypes.string,
      '3x': PropTypes.string,
    }),
  }),
  phone: PropTypes.shape({
    title: PropTypes.string,
    raw: PropTypes.string,
    formatted: PropTypes.string,
  }),
  email: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }),
  social_networks: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      account: PropTypes.string,
      url: PropTypes.string,
    })),
  }),
};

ContactInfo.defaultProps = {
  shop_name: '',
  title: '',
  address: null,
  phone: null,
  email: null,
  social_networks: null,
};

module.exports = ContactInfo;
