const React = require('react');
const PropTypes = require('prop-types');

const Button = require('@andes/button');

const Delete = require('./icons/Delete');

const { ButtonText } = Button;

const DeleteButton = ({ label }) => (
  <Button className="editable-button editable-button--delete scale-up">
    <Delete />
    <ButtonText>{label}</ButtonText>
  </Button>
);

DeleteButton.propTypes = {
  label: PropTypes.string.isRequired,
};

module.exports = DeleteButton;
