const React = require('react');
const PropTypes = require('prop-types');

const Button = require('@andes/button');

const uuid = require('uuid');
const RecommendationsContainer = require('../recommendations/recommendations-container');
const Item = require('../item/mobile');

const { useGrid } = require('./base');

const GridRecommendations = (props) => {
  const { action_button: actionButton, display } = props;

  const { grid, newGrid } = useGrid(props);

  const updateGrid = (category) => {
    newGrid(category);
  };

  return (
    <RecommendationsContainer {...props} updateGrid={updateGrid}>
      {grid.map(row => (
        <div key={uuid()} className="grid-container">
          {row.map(item => <Item key={uuid()} {...item} display={display} />)}
        </div>
      ))}
      {actionButton
        && (
          <Button
            id={actionButton.id}
            className="grid-recommendations-button"
            href={actionButton.target}
          >
            {actionButton.label}
          </Button>
        )}
    </RecommendationsContainer>
  );
};

GridRecommendations.propTypes = {
  carousel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  itemsPerRow: PropTypes.number.isRequired,
  display: PropTypes.oneOf(['original', 'material']),
};

GridRecommendations.defaultProps = {
  display: 'original',
};

module.exports = GridRecommendations;
