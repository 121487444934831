const React = require('react');
const PropTypes = require('prop-types');

const NewsletterModalDefault = require('./default');

const metadataPropType = require('../metadataProvider/metadataPropType');
const newsletterFormPropTypes = require('../newsletter-form/utils/formProps');

const { useState } = React;

// TODO: Implement different modals by theme
const MODAL_BY_THEME = {};

const NewsletterModal = (props, { metadata }) => {
  const { layoutTheme, deviceType } = metadata;
  const { images, is_open: isOpen } = props;
  // TODO: Implement condition to show or hide modal
  const [showModal, setShowModal] = useState(isOpen);

  const imageUrl = images[deviceType]?.url;
  const modalType = (deviceType === 'mobile') ? 'card' : 'small';

  const ModalTheme = MODAL_BY_THEME[layoutTheme] || NewsletterModalDefault;

  return (
    <ModalTheme
      {...props}
      image={imageUrl}
      setShowModal={setShowModal}
      showModal={showModal}
      modalType={modalType}
    />
  );
};

NewsletterModal.propTypes = {
  ...newsletterFormPropTypes,
  images: PropTypes.shape({
    desktop: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  campaign_id: PropTypes.number.isRequired,
};

NewsletterModal.contextTypes = {
  metadata: metadataPropType,
};

module.exports = NewsletterModal;
