const types = require('../Editable/types');

const getCarouselProps = (props) => {
  const { componentId, title, shape, variations, categories } = props;
  return {
    type: types.CAROUSEL_SPLINTER[variations],
    payload: { componentId, title, shape, categories },
  };
};

module.exports = getCarouselProps;
