const React = require('react');
const PropTypes = require('prop-types');
const metadataPropType = require('./metadataPropType');

class MetadataProvier extends React.Component {
  getChildContext() {
    const { metadata } = this.props;
    return { metadata };
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

MetadataProvier.childContextTypes = {
  metadata: metadataPropType.isRequired,
};

MetadataProvier.propTypes = {
  children: PropTypes.element.isRequired,
  metadata: metadataPropType.isRequired,
};

module.exports = MetadataProvier;
