const React = require('react');
const PropTypes = require('prop-types');

const InfoSlide = require('./info-slide');
const Section = require('../section');
const withTracker = require('../with-tracker');

function SiteShoppingInfoDesktop({
  type,
  elements,
  lazyLoad,
}) {
  return (
    <Section type={type}>
      <div className="container">
        {elements.map(element => (
          <InfoSlide
            key={element.title_label}
            {...element}
            lazyLoad={lazyLoad}
          />
        ))}
      </div>
    </Section>
  );
}

SiteShoppingInfoDesktop.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
};

module.exports = withTracker(SiteShoppingInfoDesktop);
