const PropTypes = require('prop-types');
const React = require('react');

const { useState } = React;
const classNames = require('classnames');
const uuid = require('uuid');
const Section = require('../section');
const Action = require('../action');

function RecommendationsContainer({
  children,
  display,
  highlight_seeds: highlightSeeds,
  title,
  type,
  view_more_action: viewMoreAction,
  categories,
  updateGrid,
}) {
  const [id, setId] = useState(0);
  const hasCategory = Boolean(categories?.length);
  const sectionHeaderClassName = classNames(`section-header section-header__${display}`, {
    'section-header__title-container': hasCategory,
  });
  const rowContainerClassName = classNames('row container', { 'highlight-seeds': highlightSeeds });
  const categoriesClassName = classNames({ 'section-center': hasCategory });
  const titleClassName = classNames('section-header__title--base', { 'section-header__title': hasCategory });

  const updateSubHeader = (index, category) => {
    setId(index);
    updateGrid(category);
  };

  const setDecoration = (index) => classNames({ 'section-header__sub-header-decoration': id === index });

  return (
    <Section type={type} className={categoriesClassName}>
      <div className={rowContainerClassName}>
        <div className={sectionHeaderClassName}>
          <h2 className={titleClassName}>{title}</h2>
          {(!hasCategory && viewMoreAction) && <Action {...viewMoreAction} />}
        </div>
        {
          hasCategory
          && (
            <div className="section__sub-header">
              {categories.map((item, index) => (
                <h3
                  key={uuid()}
                  onClick={() => updateSubHeader(index, item.id)}
                  className={setDecoration(index)}
                >
                  {item.label}
                </h3>
              ))}
            </div>
          )
        }
        {children}
      </div>
    </Section>
  );
}

RecommendationsContainer.propTypes = {
  display: PropTypes.string,
  children: PropTypes.node.isRequired,
  highlight_seeds: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes),
};

RecommendationsContainer.defaultProps = {
  display: 'original',
  highlight_seeds: false,
  view_more_action: null,
};

module.exports = RecommendationsContainer;
