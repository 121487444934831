const React = require('react');
const PropTypes = require('prop-types');

const Image = require('../../components/image');
const Header = require('../../components/CategoryLabelImage/header');

const FakeItem = ({ emptyStateCard, theme, subtitle }) => {
  const { title, price, icon, cta } = emptyStateCard || {};

  return (
    <div className="section-category-item section-category-item--show section-category__fake-item">
      {
        emptyStateCard && (
          <div className="section-category-item__container">
            <div className="section-category-item__image-container">
              <Image
                className="section-category-item__image"
                src={icon}
                onload="on"
                alt={title}
              />
            </div>
            <div className="section-category-item__header">
              <Header
                cta={cta}
                price={price}
                subtitle={subtitle}
                title={title}
                layoutTheme={theme}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

FakeItem.propTypes = {
  emptyStateCard: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
  }),
  theme: PropTypes.string,
  subtitle: PropTypes.string,
};

FakeItem.defaultProps = {
  emptyStateCard: null,
  theme: '',
  subtitle: '',
};

module.exports = FakeItem;
