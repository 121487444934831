const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');
const injectI18n = require('nordic/i18n/injectI18n');
const TruckGlyph = require('./glyphs/truck');

const Shipping = ({ shipping, i18n, hidden = false }) => {
  const classNames = ClassNames('ui-item__shipping--material', {
    'ui-item__shipping-free': shipping && shipping.freeShipping,
    'ui-item__shipping--hidden': hidden,
  });

  const shippingTitle = i18n.gettext('Envío gratis');

  return shipping && (
    <div className={classNames} title={shippingTitle}>
      <TruckGlyph className="ui-item__shipping-icon--material" />
      <span className="ui-item__shipping-label--material">{shippingTitle}</span>
    </div>
  );
};

Shipping.propTypes = {
  i18n: PropTypes.shape({ gettext: PropTypes.func.isRequired }),
  shipping: PropTypes.shape({
    freeShipping: PropTypes.bool,
    text: PropTypes.string.isRequired,
  }),
  hidden: PropTypes.bool,
};

Shipping.defaultProps = {
  i18n: { gettext: f => f },
  shipping: null,
  hidden: false,
};

module.exports = injectI18n(Shipping);
