const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const ContentSlide = require('./ContentSlide');

const ContentListDesktop = ({
  deviceType,
  elements,
  title,
}) => {
  const containerClasses = ClassNames('content-list__elements-container', `content-list__${elements.length}-elements`);

  return (
    <div className="content-list__container">
      <div className={`content-list__container__${elements.length}-elements`}>
        <h5 className="content-list__title">{title}</h5>
        <div className={containerClasses}>
          {elements.map((element, index) => (
            <ContentSlide
              align={element.align}
              button={element.button}
              description={element.description}
              deviceType={deviceType}
              elementTitle={element.element_title}
              image={element.image}
              key={index.toString()}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

ContentListDesktop.propTypes = {
  deviceType: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string.isRequired,
      button: PropTypes.shape({
        button_href: PropTypes.string.isRequired,
        button_text: PropTypes.string.isRequired,
        hidden: PropTypes.bool.isRequired,
      }),
      description: PropTypes.shape({
        hidden: PropTypes.bool.isRequired,
        text: PropTypes.string,
      }).isRequired,
      element_title: PropTypes.shape({
        hidden: PropTypes.bool.isRequired,
        text: PropTypes.string,
      }).isRequired,
      image: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        hidden: PropTypes.bool.isRequired,
        mobile: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = ContentListDesktop;
