const types = require('../Editable/types');

const getCollectionGridProps = (props) => {
  const {
    componentId,
    title,
    itemsPerRow,
    maxItems,
    view_more_action: viewMoreAction,
    display = 'original',
    categories = [],
  } = props;
  return {
    type: types.COLLECTION_GRID,
    payload: { componentId, title, itemsPerRow, maxItems, viewMoreAction, display, categories },
  };
};

// We explicitly say that length must be greater than 0 to avoid strange javascript type casting
const renderWrapper = props => props.items && (props.items.length > 0);

module.exports = { getCollectionGridProps, renderWrapper };
