const React = require('react');
const PropTypes = require('prop-types');

const { Icons: { PlayIcon } } = require('@mshops-web-components/icons');

const PlayIconVideo = ({ onImageClick }) => {
  const onInternalImageClick = () => {
    onImageClick();
  };

  return <PlayIcon className="play-icon" onClick={onInternalImageClick} />;
};

PlayIconVideo.propTypes = {
  onImageClick: PropTypes.func.isRequired,
};

module.exports = PlayIconVideo;
