const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const { BACKGROUND_TRANSPARENCY } = require('../../utils/constants/labelImage');

const MaterialContent = ({ variations, content, styles }) => {
  const { label, cta } = content;
  const materialClasses = classNames(
    'label-image__material',
    `label-image__material--${variations}`,
    'discovery--custom-label-image-gradient',
  );

  const imageBackgroundStyle = {
    backgroundImage: `
      linear-gradient(to top, rgba(0,0,0,${BACKGROUND_TRANSPARENCY[variations]}), transparent), url(${styles.background_image})
    `,
  };

  return (
    <div className={materialClasses}>
      <div className="label-image__material-header">
        <h2 className="label-image__material-title">{label}</h2>
        <div className="label-image__material-divider" />
      </div>
      <div className={`label-image__background label-image__material-background--${variations}`} style={imageBackgroundStyle} />
      <div className="label-image__material-button">
        <button role="button">
          {cta}
        </button>
      </div>
    </div>
  );
};

MaterialContent.propTypes = {
  styles: PropTypes.shape({
    background_image: PropTypes.string,
  }).isRequired,
  variations: PropTypes.string.isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
      onlyLink: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light', 'custom']),
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
  }).isRequired,
};

module.exports = MaterialContent;
