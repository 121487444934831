const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Card = require('@andes/card');
const Price = require('loki-price');

const Action = require('../action');
const FormatPriceService = require('../../../services/formatPrice');
const metadataPropType = require('../metadataProvider/metadataPropType');
const Image = require('../image');
const { CARD_PADDING_SIZE } = require('../../utils/constants/cardWithImagesAndPrice');

class CardWithImageAndPrice extends React.Component {
  constructor(props, context) {
    super(props);
    const {
      decimal_places: decimalPlaces,
      decimal_separator: decimalSeparator,
      thousand_separator: thousandSeparator,
    } = context.metadata.currency;
    const mappedPrice = {
      ...props.price,
      symbol: props.price.currency_symbol,
      fraction: FormatPriceService.getThousandSeparated({ price: props.price.value, separator: thousandSeparator }),
      cents: FormatPriceService.getDecimalPart(props.price.value, decimalPlaces),
      decimal_separator: decimalSeparator, // Not displayed, but needed for microdata
    };

    this.state = {
      price: mappedPrice,
    };
  }

  render() {
    const { className, theme, image, label, url, fakePrice } = this.props;
    const { price } = this.state;
    const cardClasses = classNames('card-with-image-price', className, theme);
    return (
      <Card className={cardClasses} paddingSize={CARD_PADDING_SIZE}>
        <Action
          target={url}
          className="card-with-image-price__url"
        >
          <div className="card-with-image-price__image-wrapper">
            <Image
              src={image}
              alt={`${label} ${price.value || fakePrice}`}
              className="card-with-image-price__img"
            />
          </div>
          <div className="card-with-image-price__text-content">
            <p className="card-with-image-price__label">{label}</p>
            {
              (fakePrice)
                ? <h2 className="fake-price-title">{fakePrice}</h2>
                : <Price amount={price} className="ui-item__price_image" />
            }
          </div>
        </Action>
      </Card>
    );
  }
}

CardWithImageAndPrice.propTypes = PropTypes.shape({
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  theme: PropTypes.string,
  fakePrice: PropTypes.string,
  price: PropTypes.shape({
    value: PropTypes.number.isRequired,
    original_price: PropTypes.number,
    currency_id: PropTypes.string.isRequired,
    currency_symbol: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
}).isRequired;

CardWithImageAndPrice.defaultProps = {
  className: '',
  theme: '',
  original_price: '',
  text: '',
  url: '',
  fakePrice: null,
  price: '',
};

CardWithImageAndPrice.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = CardWithImageAndPrice;
