const defaultCarouselSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  infinite: true,
  lazyLoad: true,
  slideSize: {
    width: 1600,
    height: 340,
  },
};

const SLIDER_SIZE = {
  desktop: {
    small: 256,
    medium: 384,
    large: 520,
  },
  tablet: {
    small: 256,
    medium: 384,
    large: 520,
  },
  mobile: {
    small: 256,
    medium: 384,
    large: 520,
  },
};

module.exports = {
  defaultCarouselSettings,
  SLIDER_SIZE,
};
