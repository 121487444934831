const FAKE_CONTAINER_BRANDS = {
  desktop: 8,
  mobile: 4,
  tablet: 4,
};

const IMAGE_CONFIG = {
  mobile: {
    size: 'G',
    prefix: 'D_Q_NP_',
  },
  desktop: {
    size: 'G',
    prefix: 'D_Q_NP_',
  },
};

const MAX_CAROUSEL_ITEMS = 3;

module.exports = { FAKE_CONTAINER_BRANDS, IMAGE_CONFIG, MAX_CAROUSEL_ITEMS };
