const React = require('react');
const PropTypes = require('prop-types');

const ContactInfo = require('./index');
const Editable = require('../Editable').default;

const types = require('../Editable/types');
const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const Wrapper = (props) => {
  const byTarget = {
    'contact-info__block': {
      type: types.CONTACT,
      payload: props.id,
    },
    'contact-info__social-networks': {
      type: types.SOCIAL_NETWORKS,
      payload: props.social_networks,
    },
  };

  return (
    <Editable targets={byTarget} socialNetworks={props.social_networks}>
      <ContactInfo {...props} />
    </Editable>
  );
};

Wrapper.propTypes = {
  id: PropTypes.string.isRequired,
  social_networks: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      account: PropTypes.string,
      url: PropTypes.string,
    })),
  }),
};

Wrapper.defaultProps = {
  social_networks: null,
};

Wrapper.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Wrapper;
