const React = require('react');

const PropTypes = require('prop-types');
const { Icons: { PlayIconButton } } = require('@mshops-web-components/icons');

const PlayIconVideo = ({ onImageClick, i18n }) => {
  const onInternalImageClick = () => {
    onImageClick();
  };

  const buttonLabel = i18n.gettext('Ver video');

  return <PlayIconButton buttonLabel={buttonLabel} className="play-button" onClick={onInternalImageClick} />;
};

PlayIconVideo.propTypes = {
  i18n: PropTypes.shape(),
  onImageClick: PropTypes.func.isRequired,
};

PlayIconVideo.defaultProps = {
  i18n: { gettext: f => f },
};

module.exports = PlayIconVideo;
