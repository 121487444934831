const React = require('react');
const PropTypes = require('prop-types');

const ImageWrapper = require('./ImageWrapper');
const SlideImage = require('./SlideImage');

const { CLASS_BY_VARIATION } = require('./config');
const classesHelper = require('../../utils/customBEMHelper');

const Slide = (props) => {
  const {
    variation,
    heading,
    title,
    subtitle,
    isInternal,
    action,
    text_alignment: textAlignment = 'left',
  } = props;
  const useLayer = heading || title || subtitle;

  const classes = classesHelper({ name: CLASS_BY_VARIATION[variation] });

  return (
    <div className={classes('')}>
      <ImageWrapper className={classes()} action={action} internal={isInternal}>
        {useLayer && <div className={classes('layer', textAlignment)} />}
        <SlideImage {...props} />
      </ImageWrapper>
    </div>
  );
};

Slide.propTypes = {
  action: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text_alignment: PropTypes.string,
  isInternal: PropTypes.bool,
  variation: PropTypes.oneOf(['original', 'material']).isRequired,
};

Slide.defaultProps = {
  action: '',
  heading: '',
  title: '',
  subtitle: '',
  text_alignment: '',
  isInternal: false,
};

module.exports = Slide;
