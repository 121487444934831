const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

function CarouselWrapper(RecommendationsBase) {
  function CarouselBase(props, context) {
    const { metadata } = context;
    return (props.tabs.length > 0)
      && (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          metadata={metadata.currency}
          restClient={metadata.restClient}
          type="recommendations"
          {...props}
        />
      </div>
      );
  }

  CarouselBase.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        permalink: PropTypes.string.isRequired,
        picture: PropTypes.object.isRequired,
        price: PropTypes.object.isRequired,
        shipping: PropTypes.object.isRequired,
      })),
    })).isRequired,
  };

  CarouselBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return CarouselBase;
}

module.exports = CarouselWrapper;
