const { useState } = require('react');

const buildGrid = (items, props) => {
  const { lazyLoad, metadata, restClient, itemsPerRow } = props;
  const extendedItems = items.map(item => ({
    ...item,
    lazyLoad,
    metadata,
    restClient,
  }));

  let totalRows = Math.floor(extendedItems.length / itemsPerRow);
  totalRows += ((extendedItems.length % itemsPerRow) > 0) ? 1 : 0;

  const range = totalRows > 0 ? [...Array(totalRows).keys()] : [];

  return range.map(i => (extendedItems.slice(i * itemsPerRow, itemsPerRow + (i * itemsPerRow))));
};

const getCategory = (categories, categoryId) => categories.find(item => item.id === categoryId);

const useGrid = (props) => {
  const { items, categories } = props;

  const newItems = categories?.length ? getCategory(categories, categories[0].id).items : items;

  const [grid, setGrid] = useState(buildGrid(newItems, props));

  const newGrid = (categoryId) => {
    setGrid(buildGrid(getCategory(categories, categoryId).items, props));
  };

  return {
    grid,
    newGrid,
  };
};

module.exports = {
  useGrid,
};
