/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const PriceBlockOriginal = require('./price-block/priceBlockOriginal');
const PriceBlockMaterial = require('./price-block/priceBlockMaterial');

const VARIATION_ELEMENT = {
  original: PriceBlockOriginal,
  material: PriceBlockMaterial,
};

const PriceBlock = (props) => {
  const PriceBlock = VARIATION_ELEMENT[props.display];
  return <PriceBlock {...props} />;
};

PriceBlock.propTypes = {
  discount: PropTypes.object,
  display: PropTypes.string,
  price: PropTypes.object.isRequired,
  installments: PropTypes.string,
};

PriceBlock.defaultProps = {
  discount: null,
  display: 'original',
  installments: null,
};

module.exports = PriceBlock;
