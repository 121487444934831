/* eslint-disable max-len */
const React = require('react');

const Edit = () => (
  <svg version="1.1" width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-565 -926)" stroke="#fff" strokeWidth="1.2">
        <g transform="translate(548 917)">
          <g transform="translate(24 16) scale(-1 1) translate(-8 -7)">
            <g transform="translate(.5 .125)">
              <path d="m12.092 1.2478l0.63909 0.63909c0.68916 0.68916 0.68916 1.8065 0 2.4957l-8.1565 8.1565-3.1348-3.1348 8.1565-8.1565c0.68916-0.68916 1.8065-0.68916 2.4957 0z" />
              <polyline points="1.1182 9.5302 0.45015 13.523 4.4474 12.859" />
              <path d="m8.125 2.9906l2.9614 2.9614" strokeLinecap="square" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

module.exports = Edit;
