const React = require('react');
const PropTypes = require('prop-types');

const FakeMosaicCard = require('./FakeMosaicCard');

const { CARD_VARIATIONS } = require('./constants');

const MosaicEmptyState = ({ empty_state_card: emptyStateCard = {} }) => {
  const { icon, title, cta } = emptyStateCard;
  return (
    <section className="hub_boxed-width">
      <div className="content">
        <div className="mesh-row">
          <FakeMosaicCard image={icon} title={title} cta={cta} variation={CARD_VARIATIONS.PRIMARY} />
          <FakeMosaicCard image={icon} title={title} cta={cta} variation={CARD_VARIATIONS.SECONDARY} />
          <FakeMosaicCard image={icon} title={title} cta={cta} variation={CARD_VARIATIONS.TERTIARY} />
        </div>
      </div>
    </section>
  );
};

MosaicEmptyState.propTypes = {
  empty_state_card: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    cta: PropTypes.string,
  }),
};

MosaicEmptyState.defaultProps = {
  empty_state_card: {},
};

module.exports = MosaicEmptyState;
