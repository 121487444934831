const React = require('react');

const PropTypes = require('prop-types');
const classNames = require('classnames');
const Image = require('nordic/image');

const { generateUrl } = require('../../appearance/BadgeCarousel/brand-carousel-empty-state/generateUrl');
const withTracker = require('../with-tracker');
const withTrackerOnPrint = require('../with-tracker-on-print');

const FakeBrand = ({ shape, alt, imageConfig, image }) => {
  const src = image && generateUrl(imageConfig, image);
  const brandClass = classNames('fake-brand', `fake-brand--${shape}`);
  const logoClass = classNames('fake-brand__logo-img', `fake-brand__logo--${shape}`);

  return (
    <div className={brandClass}>
      <div className="fake-brand__logo">
        {
          src
            ? (
              <Image
                src={src}
                alt={alt}
                className={logoClass}
              />
            )
            : <strong className="fake-brand__logo-text" />
        }
      </div>
    </div>
  );
};

FakeBrand.propTypes = {
  shape: PropTypes.string.isRequired,
  alt: PropTypes.string,
  imageConfig: PropTypes.shape({
    size: PropTypes.string,
    prefix: PropTypes.string,
  }),
  image: PropTypes.string,
};

FakeBrand.defaultProps = {
  image: undefined,
  alt: undefined,
  imageConfig: undefined,
};

module.exports = withTracker(withTrackerOnPrint(FakeBrand));
