const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../../components/metadataProvider/metadataPropType');
const FakeBrand = require('../../../components/placeholder/FakeBrand');

const { FAKE_CONTAINER_BRANDS, IMAGE_CONFIG: imageConfig, MAX_CAROUSEL_ITEMS } = require('./utils');

const WrapperEditable = (Base) => {
  const Wrapper = (props, { metadata: { deviceType } }) => {
    const { variations, children, title, shape } = props;
    const emptyState = (children.length < MAX_CAROUSEL_ITEMS);
    const isBrandCarousel = (variations === 'brands');
    const fakeItems = new Array(FAKE_CONTAINER_BRANDS[deviceType]).fill({});

    if (emptyState && isBrandCarousel) {
      return (
        <div className="fake-brand-container">
          {title && (
            <h4 className="fake-brand-container__title">
              {title}
            </h4>
          )}

          <div className="fake-brand-container__carousel">
            {emptyState
              && (
                <>
                  {
                    children.map(
                      brand => (
                        <FakeBrand
                          shape={shape}
                          alt={brand.props.node.properties.label}
                          image={brand.props.node.properties.image_src}
                          imageConfig={imageConfig[deviceType]}
                          key={brand.props.node.id}
                        />
                      ),
                    )
                  }
                  {
                    fakeItems.map((fake, index) => <FakeBrand shape={shape} key={fake[index]} />)
                  }
                </>
              )}
          </div>
        </div>
      );
    }

    if (!emptyState) {
      return <Base {...props} />;
    }

    return null;
  };

  Wrapper.propTypes = {
    variations: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    shape: PropTypes.string,
    title: PropTypes.string,
    empty_state_card: PropTypes.shape({
      icon: PropTypes.string,
      price: PropTypes.string,
      fees: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  Wrapper.defaultProps = {
    title: '',
    shape: 'circle',
    empty_state_card: {
      icon: '',
      price: '',
      fees: '',
      title: '',
    },
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
