const React = require('react');
const Props = require('./props');

const StarFull = ({ className }) => (
  <svg className={className} viewBox="0 0 33 32">
    <path d="M16.7 24l-9.4 5L9 18.6l-7.5-7.2 10.5-2L16.7 0l4.8 9.5L32 11.3l-7.6 7.2L26.2 29z" />
  </svg>
);

StarFull.propTypes = Props.propTypes;
StarFull.defaultProps = Props.defaultProps;

module.exports = StarFull;
