const React = require('react');
const displayName = require('../display-name');

function withLifeCycle(WrappedComponent) {
  class WithLifeCycle extends React.Component {
    constructor(props) {
      super(props);
      this.state = { mounted: false };
    }

    componentDidMount() {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ mounted: true });
    }

    render() {
      return <WrappedComponent {...this.props} mounted={this.state.mounted} />;
    }
  }

  WithLifeCycle.displayName = displayName(WithLifeCycle, WrappedComponent);

  return WithLifeCycle;
}

module.exports = withLifeCycle;
