const React = require('react');
const ClassNames = require('classnames');

const Button = require('@andes/button');
const { Icons } = require('@mshops-web-components/icons');

const types = require('../../appearance/Editable/types');

const { useRenderContext, useRenderDispatcher } = require('../../pages/home/context');
const wordings = require('./wordings');
const {
  EDITABLE_MODE,
  NOT_ORDERABLE_COMPONENTS,
  NOT_REMOVABLE_COMPONENTS,
} = require('../../utils/constants/common');

const { ButtonText } = Button;
const { Delete32, ChevronUp, ChevronDown, Plus } = Icons;

const { useRef, useEffect } = React;

const WRAPPER_NAME = 'dragable-section-wrapper';

const withOrder = (Component) => (props) => {
  const {
    name,
    componentId,
    hovered,
    i18n,
    sendMessage,
    animationUp,
    animationDown,
    bringFront,
    pullBack,
    scrollToMe,
    hidden,
  } = props;
  const { orderButton, sectionButton } = wordings(i18n);

  const { editableMode, appearance } = useRenderContext();
  const renderActions = useRenderDispatcher();

  const canRemoveComponent = !NOT_REMOVABLE_COMPONENTS.includes(name);

  const componentIndex = appearance.getLayoutComponentIndex(componentId);
  const filteredLayoutChildren = appearance.layout
    ? appearance.layout.children.filter(
      (child) => !NOT_ORDERABLE_COMPONENTS.includes(child.component),
    )
    : [];
  const layoutComponentsCount = filteredLayoutChildren.length - 1;

  const moveUp = () => {
    renderActions.triggerAnimationUp(componentId);
  };

  const moveDown = () => {
    renderActions.triggerAnimationDown(componentId);
  };

  const removeComponent = () => {
    sendMessage(
      types.LAYOUT_REMOVE_COMPONENT,
      { componentId },
    );
  };

  const addComponent = () => {
    sendMessage(
      types.LAYOUT_ADD_COMPONENT,
      { position: componentIndex + 1 },
    );
  };

  const myRef = useRef();

  const scrollIntoRef = () => {
    if (scrollToMe) {
      myRef.current.scrollIntoView(false);
      renderActions.updateComponentProps(componentId, { scrollToMe: false });
    }
  };

  useEffect(() => {
    scrollIntoRef();
  }, [scrollToMe]);

  const onAnimationEnd = (event) => {
    const targetName = event.target.getAttribute('name');
    if ((targetName !== WRAPPER_NAME) || !bringFront) {
      return;
    }

    if (animationUp) {
      renderActions.moveComponentUp(componentId);
      sendMessage(
        types.LAYOUT_ORDER,
        { componentId, position: componentIndex - 1 },
      );
      return;
    }

    renderActions.moveComponentDown(componentId);
    sendMessage(
      types.LAYOUT_ORDER,
      { componentId, position: componentIndex + 1 },
    );
  };

  const isFirstComponent = (componentIndex === 0);
  const isLastComponent = (componentIndex >= layoutComponentsCount);

  const sectionClasses = ClassNames('dragable-component', {
    'dragable-component--move-up': animationUp,
    'dragable-component--move-down': animationDown,
    'dragable-component--front': bringFront,
    'dragable-component--back': pullBack,
    'dragable-component--bottom-space': isLastComponent,
  });

  const buttonContainerClasses = ClassNames('dragable-component__button-container', {
    'dragable-component__button-container--first': isFirstComponent,
    'dragable-component__button-container--visible': hovered,
  });
  const buttonOrderClasses = ClassNames('dragable-component__wrapper', 'dragable-component__wrapper--order');
  const buttonUpClasses = ClassNames('dragable-component__button', 'dragable-component__button--up');
  const buttonDownClasses = ClassNames('dragable-component__button', 'dragable-component__button--down');
  const buttonDeleteClasses = ClassNames('dragable-component__button-delete', {
    'dragable-component__button-delete--first': isFirstComponent,
    'dragable-component__button-container--visible': hovered,
  });
  const buttonSectionClasses = ClassNames('dragable-component__wrapper', 'dragable-component__wrapper--section');

  const showComponentControllers = ((componentIndex > -1) && (!hidden) && (editableMode === EDITABLE_MODE.LAYOUT));
  return (
    <section ref={myRef} name={WRAPPER_NAME} className={sectionClasses} onAnimationEnd={onAnimationEnd}>
      {showComponentControllers && (
        <div className={buttonContainerClasses}>
          <div className={buttonOrderClasses}>
            {(!isFirstComponent) && (
              <button onClick={moveUp} className={buttonUpClasses}>
                <ChevronUp width="16" height="16" />
              </button>
            )}
            <span>{orderButton}</span>
            {(!isLastComponent) && (
              <button onClick={moveDown} className={buttonDownClasses}>
                <ChevronDown width="16" height="16" />
              </button>
            )}
          </div>
        </div>
      )}
      {(showComponentControllers && canRemoveComponent) && (
        <button className={buttonDeleteClasses} onClick={removeComponent}>
          <Delete32 className="dragable-component__button-delete-icon" />
        </button>
      )}
      <div className="dragable-component__element-container">
        <Component {...props} />
      </div>
      {showComponentControllers && (
        <div className={buttonContainerClasses}>
          <Button className={buttonSectionClasses} onClick={addComponent}>
            <Plus />
            <ButtonText>{sectionButton}</ButtonText>
          </Button>
        </div>
      )}
    </section>
  );
};

module.exports = withOrder;
