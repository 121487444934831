/*
 * Creates an array made of smaller arrays
 * @param {Array} array The array to process.
 * @param {number} [size=1] The length of each chunk
 * @returns {Array} Returns the new array of chunks.
 */

function chunk(array, size = 1) {
  const length = array == null ? 0 : array.length;
  if (!length || size < 1) {
    return [];
  }

  const result = [];
  let currentChunk = 0;

  for (let i = 0; i < Math.ceil(length / size); i += 1) {
    result.push(array.slice(currentChunk, (currentChunk += size)));
  }

  return result;
}

module.exports = chunk;
