const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

function PaymentMethodsWrapper(PaymentData, resolution) {
  function PaymentMethodsBase({ styles, ...props }, context) {
    const { siteId, lowEnd } = context.metadata;

    return (
      <PaymentData
        deviceType="desktop"
        type="payment-data"
        siteId={siteId}
        lowEnd={lowEnd}
        paymentStyles={styles[resolution]}
        {...props}
      />
    );
  }

  PaymentMethodsBase.propTypes = {
    styles: PropTypes.shape({
      small: PropTypes.object,
      large: PropTypes.object,
    }).isRequired,
  };

  PaymentMethodsBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return PaymentMethodsBase;
}

module.exports = PaymentMethodsWrapper;
