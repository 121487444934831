const COMPONENTS_TO_IGNORE = [
  'DebugInfo',
  'DebugInfoEditable',
  'Document',
  'DocumentEditable',
  'Footer',
  'FooterEditable',
  'Header',
  'HeaderEditable',
  'Layout',
  'LayoutEditable',
  'LabelImage',
  'LabelImageEditable',
  'Widgets',
  'WidgetsEditable',
  'CategoryLabelImageEditable',
  'CategoryLabelImage',
];

const isComponentOutsideLayout = componentName => COMPONENTS_TO_IGNORE.includes(componentName);

const defaultAdviceMessage = {
  hasAdviceMessage: false,
  adviceMessageType: '',
  adviceMessage: '',
  link: {},
};

const getAdviceMessageComponent = properties => {
  const adviceMessage = properties?.advice_message;
  if (adviceMessage) {
    return {
      hasAdviceMessage: true,
      adviceMessageType: adviceMessage.type,
      adviceMessage: adviceMessage.text,
      link: adviceMessage.link,
    };
  }
  return defaultAdviceMessage;
};

const getFirstChildAdviceMessage = children => {
  if (children?.length > 0) {
    return getAdviceMessageComponent(children[0].properties);
  }

  return defaultAdviceMessage;
};

const getAdviceMessage = (children, properties) => {
  let adviceMessage = getAdviceMessageComponent(properties);
  if (!adviceMessage.hasAdviceMessage) {
    adviceMessage = getFirstChildAdviceMessage(children);
  }
  return adviceMessage;
};

module.exports = { isComponentOutsideLayout, getAdviceMessage };
