/* eslint-disable max-len */
const React = require('react');

const Payment = () => (
  <svg width="110" height="72" viewBox="0 0 100 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M93.2408 62.9211H6.67416C3.90421 62.9211 1.65842 60.6601 1.65842 57.8715V6.18042C1.65842 3.3918 3.90421 1.13086 6.67416 1.13086H93.2408C96.0108 1.13086 98.2566 3.3918 98.2566 6.18042V57.8715C98.2566 60.6601 96.0108 62.9211 93.2408 62.9211" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.01843 52.3887C2.01843 55.3848 4.92951 57.8222 8.55257 57.8965L8.72291 57.8983H91.4564C95.1011 57.8983 98.0663 55.5069 98.1567 52.5287L98.1588 52.3887V56.3977C98.1588 60.1151 95.1581 63.1286 91.4564 63.1286H8.72291C5.02119 63.1286 2.01843 60.1151 2.01843 56.3977V52.3887Z" fill="#EEEEEE" />
    <path d="M93.2408 62.9211H6.67416C3.90421 62.9211 1.65842 60.6601 1.65842 57.8715V6.18042C1.65842 3.3918 3.90421 1.13086 6.67416 1.13086H93.2408C96.0108 1.13086 98.2566 3.3918 98.2566 6.18042V57.8715C98.2566 60.6601 96.0108 62.9211 93.2408 62.9211" stroke="#333333" strokeWidth="1.5" />
    <path d="M12.8498 49.6871H52.1398" stroke="#333333" strokeWidth="1.5" />
    <path d="M58.647 49.6871H67.2361" stroke="#333333" strokeWidth="1.5" />
    <path d="M12.8498 39.5511H25.6264" stroke="#333333" strokeWidth="1.5" />
    <circle cx="19.2381" cy="15.1766" r="7.06135" className="icon--custom-color" fill="#22277A" stroke="white" strokeWidth="1.5" />
    <circle cx="19.2381" cy="15.1766" r="7.81135" stroke="#333333" strokeWidth="1.5" />
    <circle cx="31.6321" cy="15.1766" r="7.81135" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M33.0552 39.5511H45.8318" stroke="#333333" strokeWidth="1.5" />
    <path d="M53.2605 39.5511H66.0372" stroke="#333333" strokeWidth="1.5" />
    <path d="M73.4659 39.5511H86.2425" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

module.exports = Payment;
