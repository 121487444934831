const React = require('react');
const PropTypes = require('prop-types');
const uuid = require('uuid');
const classNames = require('classnames');

const RecommendationsContainer = require('../recommendations/recommendations-container');
const Item = require('../item');

const { useGrid } = require('./base');

const GridRecommendations = (props) => {
  const { display, itemsPerRow } = props;

  const { grid, newGrid } = useGrid(props);

  const updateGrid = (categoryId) => {
    newGrid(categoryId);
  };

  const container = classNames('grid-container', { 'grid-container__items-per-row-one': itemsPerRow < 2 });

  return (
    <RecommendationsContainer {...props} updateGrid={updateGrid}>
      {grid.map(row => (
        <div key={uuid()} className={container}>
          {row.map(item => <Item key={uuid()} {...item} display={display} itemsPerRow={itemsPerRow} />)}
        </div>
      ))}
    </RecommendationsContainer>
  );
};

GridRecommendations.propTypes = {
  carousel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  itemsPerRow: PropTypes.number.isRequired,
  display: PropTypes.oneOf(['original', 'material']),
};

GridRecommendations.defaultProps = {
  display: 'original',
};

module.exports = GridRecommendations;
