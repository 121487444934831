const React = require('react');
const PropTypes = require('prop-types');

const Email = ({ email }) => (
  <div>
    <label htmlFor="email" className="contact-info__label">{email.title}</label>
    <div id="email" className="contact-info__data">
      <a href={`mailto:${email.value}`} rel="nofollow">{email.value}</a>
    </div>
  </div>
);

Email.propTypes = {
  email: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

module.exports = Email;
