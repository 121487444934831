const React = require('react');
const PropTypes = require('prop-types');
const Style = require('nordic/style');

const MLNotFoundScreen = require('ui-library_ml/ui/not-found-screen');

function NotFoundScreen({ title, link }) {
  return (
    <div>
      <Style href="errorScreen.css" />
      <MLNotFoundScreen
        title={title}
        link={{
          text: link.text,
          href: link.href,
        }}
      />
    </div>
  );
}

NotFoundScreen.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
};

module.exports = NotFoundScreen;
