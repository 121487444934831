const React = require('react');
const PropTypes = require('prop-types');

const getSectionTextClassNames = require('./class-helper');

const SectionTextGeneral = require('./general');
const SectionTextCaptive = require('./captive');

const metadataPropType = require('../metadataProvider/metadataPropType');

const SectionText = ({ title, text, ctas, steering_wheel: steeringWheel }, { metadata }) => {
  const { layoutTheme } = metadata;
  if (title?.hidden && steeringWheel?.hidden && text?.hidden && ctas?.hidden) {
    return null;
  }

  if (!title?.text && !steeringWheel?.text && !text?.text && !ctas?.buttons?.length > 0) {
    return null;
  }

  const classNames = getSectionTextClassNames({ title, steeringWheel, text, ctas });

  const SectionTextComponent = (layoutTheme === 'captive') ? SectionTextCaptive : SectionTextGeneral;

  return (<SectionTextComponent
    title={title}
    text={text}
    ctas={ctas}
    steering_wheel={steeringWheel}
    classNames={classNames}
  />);
};

SectionText.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  steering_wheel: PropTypes.shape({
    text: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  ctas: PropTypes.shape({
    align: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

SectionText.contextTypes = {
  metadata: metadataPropType,
};

module.exports = SectionText;
