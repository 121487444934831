module.exports = {
  MOBILE: 'MOBILE',
  LENGTH_BREAKPOINT: 4,
  GRID_SIZE_SM: 3,
  GRID_SIZE_L: 3,
  MAX_CATEGORIES: 3,
  THEMES: {
    DARK: 'dark',
    LIGHT: 'light',
    CUSTOM: 'custom',
  },
};
