/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const InactiveWhatsApp = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M42.501 10.448C38.367 6.301 32.854 4.025 27 4.025c-12.087 0-21.924 9.837-21.924 21.912 0 4.147 1.163 8.18 3.35 11.657l.519.835-2.213 8.092 8.294-2.175.797.48a21.85 21.85 0 0 0 11.152 3.06h.012c12.075 0 21.912-9.837 21.912-21.911A21.78 21.78 0 0 0 42.5 10.448z" />
      <path fill="#455A64" d="M45.24 7.692C40.266 2.732 33.667 0 26.625 0 12.114 0 .316 11.783.303 26.248c0 4.633 1.211 9.14 3.508 13.13L.076 52.989l13.956-3.651a26.384 26.384 0 0 0 12.582 3.197h.012c14.5 0 26.31-11.783 26.324-26.26 0-7.013-2.739-13.61-7.71-18.582zM26.625 48.09a21.858 21.858 0 0 1-11.142-3.046l-.795-.479-8.279 2.166 2.209-8.057-.518-.831a21.69 21.69 0 0 1-3.344-11.607c0-12.036 9.818-21.817 21.882-21.817 5.842 0 11.332 2.278 15.47 6.395 4.127 4.13 6.399 9.605 6.399 15.434-.013 12.06-9.83 21.842-21.882 21.842zm12-16.34c-.655-.328-3.886-1.914-4.491-2.14-.606-.215-1.048-.328-1.477.327-.442.654-1.703 2.14-2.082 2.568-.379.44-.77.491-1.426.164-.656-.328-2.776-1.02-5.287-3.26-1.956-1.738-3.269-3.89-3.66-4.546-.378-.654-.038-1.007.29-1.334.29-.29.657-.768.985-1.146.328-.377.441-.654.656-1.095.214-.44.113-.818-.05-1.145-.165-.328-1.477-3.563-2.032-4.872-.53-1.285-1.073-1.108-1.477-1.12-.378-.026-.82-.026-1.262-.026-.441 0-1.148.164-1.754.818-.605.655-2.296 2.241-2.296 5.476 0 3.236 2.36 6.345 2.687 6.786.329.44 4.632 7.062 11.231 9.908a36.263 36.263 0 0 0 3.748 1.384c1.578.504 3.004.428 4.14.265 1.261-.19 3.886-1.586 4.441-3.122.543-1.536.543-2.845.379-3.122-.177-.277-.606-.441-1.262-.768z" />
    </g>
  </svg>
);

InactiveWhatsApp.defaultProps = {
  className: '',
};

InactiveWhatsApp.propTypes = {
  className: PropTypes.string,
};

module.exports = InactiveWhatsApp;
