const React = require('react');
const PropTypes = require('prop-types');
const withLifeCycleMobile = require('../with-life-cycle/mobile');

const Image = ({ lazyLoad, mounted, src, size, src2x, title, isFirstPage }) => {
  if (!mounted && !lazyLoad && !isFirstPage) {
    return (
      <div className="ui-item__image-container">
        <div className="ui-item__image" />
      </div>
    );
  }

  const srcSet = src2x ? `${src} 1x, ${src2x} 2x` : null;
  const blankGif = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  return (
    <div className="ui-item__image-container">
      <meta itemProp="image" content={src} />
      <img
        src={!mounted && lazyLoad ? blankGif : src}
        srcSet={!mounted && lazyLoad ? blankGif : srcSet}
        data-src={src}
        className={!mounted && isFirstPage ? 'ui-item__image lazy-loadable' : 'ui-item__image'}
        width={size}
        height={size}
        alt={title}
        data-srcset={srcSet}
      />
    </div>
  );
};

Image.propTypes = {
  mounted: PropTypes.bool.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  src2x: PropTypes.string,
  title: PropTypes.string,
  isFirstPage: PropTypes.bool.isRequired,
};

Image.defaultProps = {
  size: null,
  src2x: null,
  title: null,
};

module.exports = withLifeCycleMobile(Image);
