const types = require('../Editable/types');

const getSectionTextProps = ({
  componentId,
  title,
  steering_wheel: steeringWheel,
  id,
  ctas,
  text,
  hovered,
  name,
}) => ({
  type: types.SECTION_TEXT,
  payload: { componentId, title, steeringWheel, id, ctas, text, hovered, name },
});

module.exports = { getSectionTextProps };
