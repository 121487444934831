const React = require('react');
const PropTypes = require('prop-types');

const Spinner = require('@andes/spinner');

function Layout({ fetching, children }) {
  return (
    <div>
      { fetching && <Spinner /> }
      {children}
    </div>
  );
}

Layout.propTypes = {
  fetching: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Layout.defaultProps = {
  fetching: false,
};

module.exports = Layout;
