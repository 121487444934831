const React = require('react');
const PropTypes = require('prop-types');

const Title = ({ title }) => (
  <section className="legal-notices__title-section">
    <h2 className="title-section__title">{title}</h2>
  </section>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

module.exports = Title;
