/* eslint-disable max-len */
module.exports = i18n => ({
  title: i18n.gettext('¿Cómo comprar?'),
  steps: {
    main: [
      {
        icon: 'choose',
        title: i18n.gettext('Elige los productos que vas a comprar'),
        text: i18n.gettext('Si quieres más de uno, súmalos a tu carrito.'),
      },
      {
        icon: 'buy',
        title: i18n.gettext('Paga con el medio de pago que quieras'),
        text: i18n.gettext('Compra con seguridad: usamos la tecnología de Mercado Pago.'),
      },
      {
        icon: 'collect',
        title: i18n.gettext('Recibe el producto que esperas'),
        text: i18n.gettext('Elige la forma de entrega que prefieras ¡y listo! Aseguramos tu entrega con Mercado Envíos.'),
      },
    ],
    noGuest: [
      {
        icon: 'choose',
        title: i18n.gettext('Elige'),
        text: i18n.gettext('Selecciona los productos que quieras, agrégalos al carrito o elige la opción Comprar ahora.'),
      },
      {
        icon: 'buy',
        title: i18n.gettext('Paga'),
        text: i18n.gettext(
          'Procesamos tu compra en nuestra tienda utilizando la tecnología de '
          + 'Mercado Libre. Te llevaremos para que realices el pago de forma ágil y segura a través de '
          + 'pagos con débito, crédito y efectivo.',
        ),
      },
      {
        icon: 'collect',
        title: i18n.gettext('Recibe'),
        text: i18n.gettext('Elige la forma de entrega que prefieras ¡y listo!'),
      },
    ],
  },
});
