const React = require('react');
const PropTypes = require('prop-types');
const Section = require('../../components/section');
const Validate = require('../../../services/validation');

function BuyerInfo({ items }) {
  // Only render for MLA if there are items
  return (items.length > 0) && (
    <Section className="buyer-info">
      <div className="container">
        {items.map(item => {
          const { title_label: titleLabel, target_url: targetUrl, link_label: linkLabel } = item;
          const linkProps = Validate.isAbsoluteURL(targetUrl)
            ? { href: targetUrl, target: '_blank', rel: 'noopener noreferrer' }
            : { href: targetUrl };
          return (
            <div key={titleLabel} className="buyer-info__card">
              <p>{titleLabel}</p>
              <a {...linkProps}>{linkLabel}</a>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

BuyerInfo.defaultProps = {
  items: [],
};

BuyerInfo.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
      target_url: PropTypes.string.isRequired,
      link_label: PropTypes.string.isRequired,
    }),
  ),
};

module.exports = BuyerInfo;
