const React = require('react');
const PropTypes = require('prop-types');
const Classnames = require('classnames');
const uuid = require('uuid/v4');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { THEME_TITLE_FONT } = require('./utils');

const CategoryGallery = ({
  title,
  children,
  editable,
  fakeChildren,
  categories,
}, context) => {
  const { metadata: { layoutTheme } } = context;
  const isMinCategories = categories?.length < 2;
  const columnClasses = Classnames(
    'section-category__columns',
    'section-category__columns__item',
    {
      [`section-category__columns--${categories.length}item`]: !isMinCategories,
      'section-category__columns--minItem': isMinCategories,
    },
  );

  const titleClasses = Classnames(
    'section-category__title',
    {
      'font-primary-text': layoutTheme !== THEME_TITLE_FONT,
      'font-secondary-text': layoutTheme === THEME_TITLE_FONT,
    },
  );

  return (
    <section className="section-category">
      <div className="row container section-category__container">
        {
          title?.text && !title?.hidden && (
            <h2 className={titleClasses}>{title.text}</h2>
          )
        }
        <div className={columnClasses}>
          {
            children?.map(item => (
              <React.Fragment key={uuid()}>{item}</React.Fragment>
            ))
          }
          {
            editable && fakeChildren && fakeChildren()
          }
        </div>
      </div>
    </section>
  );
};

CategoryGallery.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  editable: PropTypes.bool,
  fakeChildren: PropTypes.func,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      image_id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

CategoryGallery.defaultProps = {
  editable: false,
  fakeChildren: () => {},
  categories: [],
};

CategoryGallery.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = CategoryGallery;
