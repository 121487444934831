const React = require('react');
const PropTypes = require('prop-types');
const Question = require('./components/Question');

const Info = ({ questions }) => (
  <section className="lgpd-landing__info-section">
    <div className="info-section__questions">
      {
        questions.map(question => (
          <Question key={question.title} title={question.title} text={question.text} />
        ))
      }
    </div>
  </section>
);

Info.propTypes = {
  questions: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

module.exports = Info;
