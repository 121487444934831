const React = require('react');
const PropTypes = require('prop-types');

const Base = require('.');
const MosaicEmptyState = require('./emptyState');

const createEditable = require('../../adapters/createEditable');
const withEditable = require('../../adapters/withEditable');
const withHover = require('../../adapters/withHover');
const withOrder = require('../../adapters/withOrder');

const types = require('../Editable/types');

const getMosaicProps = (props) => {
  const { componentId, title, maxItems } = props;
  return {
    type: types.MOSAIC,
    payload: { componentId, title, maxItems },
  };
};

const MosaicEditable = (props) => {
  const { children } = props;
  if (children.length === 0 || (children[0].props.node.children.length === 0)) {
    return <MosaicEmptyState {...props} />;
  }

  return <Base {...props} />;
};

MosaicEditable.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.element,
  ),
};

module.exports = withHover(withEditable(createEditable(withOrder(MosaicEditable), getMosaicProps)));
