const imageHost = 'https://http2.mlstatic.com/';

const settings = {
  Slide: null,
  dots: true,
  arrowsVisible: true,
  slidesToScroll: 1,
  variableWidth: false,
  infinite: false,
  speed: 150,
  slidesToShow: 6,
  size: {
    width: 'auto',
  },
  responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6 } }],
};

const buildImageUrl = (image) => {
  const isImageUrl = image.match(/^(http:\/\/|https:\/\/)/);
  if (isImageUrl) {
    return image;
  }

  return `${imageHost}D_NQ_${image}-OO.jpg`;
};

module.exports = {
  settings,
  buildImageUrl,
};
