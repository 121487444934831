const React = require('react');
const PropTypes = require('prop-types');

const FakeOriginal = require('./fakeOriginal');
const FakeMaterial = require('./fakeMaterial');

const VARIATION_ELEMENT = {
  original: FakeOriginal,
  material: FakeMaterial,
};

const FakeContainer = ({ type, empty_state_card: emptyStateCard, display }) => {
  const EmptyElement = VARIATION_ELEMENT[display];

  return <EmptyElement type={type} emptyStateCard={emptyStateCard} />;
};

FakeContainer.propTypes = {
  type: PropTypes.oneOf(['small', 'large']).isRequired,
  empty_state_card: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    cta: PropTypes.string,
  }),
  display: PropTypes.oneOf(['original', 'material']),
};

FakeContainer.defaultProps = {
  empty_state_card: null,
  display: 'original',
};

module.exports = FakeContainer;
