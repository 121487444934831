const React = require('react');
const PropTypes = require('prop-types');

const Title = require('./components/Title');
const Info = require('./components/Info');

const LegalNoticesInfo = (props) => {
  const { lastUpdate, subtitle, text, title, link, url } = props;

  return (
    <div className="legal-notices-landing">
      <Title title={title} />
      <Info lastUpdate={lastUpdate} subtitle={subtitle} text={text} link={link} url={url} />
    </div>
  );
};

LegalNoticesInfo.propTypes = {
  lastUpdate: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

module.exports = LegalNoticesInfo;
