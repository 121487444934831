const classnames = require('classnames');

const getTitleClassName = (title) => {
  const titleClassName = classnames(
    'section-text__title',
    title?.align && `section-text__title--${title?.align}`,
  );
  return titleClassName;
};

const getSubTitleClassName = (steeringWheel) => {
  const subTitleClassName = classnames(
    'section-text__subtitle',
    {
      [`section-text__subtitle--${steeringWheel?.align}`]: steeringWheel?.align,
    },
  );
  return subTitleClassName;
};

const getTextClassName = (text) => {
  const textClassName = classnames(
    'section-text__text',
    text?.align && `section-text__text--${text?.align}`,
  );
  return textClassName;
};

const getCtasClassName = (ctas) => {
  const ctasClassName = classnames(
    'section-text__row-buttons-actions',
    ctas?.align && `section-text__row-buttons-actions--${ctas?.align}`,
  );
  return ctasClassName;
};

const getActionsClassName = () => {
  const actionsClassName = classnames(
    'section-text__row-buttons-actions__btn-action-user',
    'section-text__row-buttons-actions__btn-action-user--default',
  );
  return actionsClassName;
};

const getActionsSecondaryClassName = () => {
  const actionsSecondaryClassName = classnames(
    'section-text__row-buttons-actions__btn-action-user',
    'section-text__row-buttons-actions__btn-action-user--quiet',
  );
  return actionsSecondaryClassName;
};

const getSectionTextClassNames = ({ title, steeringWheel, text, ctas }) => ({
  titleClassName: getTitleClassName(title),
  subTitleClassName: getSubTitleClassName(steeringWheel),
  textClassName: getTextClassName(text),
  ctasClassName: getCtasClassName(ctas),
  actionsClassName: getActionsClassName(),
  actionsSecondaryClassName: getActionsSecondaryClassName(),
});

module.exports = getSectionTextClassNames;
