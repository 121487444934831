const React = require('react');
const PropTypes = require('prop-types');

const IconContainer = require('./IconContainer');
const { THEME } = require('./utils');

const Header = ({
  cta,
  hasButtonAction,
  hasPriceCategory,
  hasTitleCategory,
  layoutTheme,
  price,
  subtitle,
  title: titleCategory,
}) => {
  const title = hasTitleCategory && (
    <p className="section-category-item__title font-secondary-text">{titleCategory}</p>
  );

  const priceContainer = hasPriceCategory && (
    <div className="section-category-item__price-container">
      <p className="section-category-item__subtitle font-secondary-text">{subtitle}</p>
      <span className="section-category-item__price font-secondary-text">{price}</span>
    </div>
  );

  const buttonContainer = hasButtonAction && (
    <div className="section-category-item__button-container">
      <button role="button" className="section-category-item__button font-secondary-text">
        {cta}
      </button>
      <IconContainer theme={layoutTheme} />
    </div>
  );

  if (layoutTheme === THEME.LEGEND || layoutTheme === THEME.EXPLORER) {
    return (
      <>
        {title}
        <div className="section-category-item__price-cta-container">
          {priceContainer}
          {buttonContainer}
        </div>
      </>
    );
  }

  return (
    <>
      {title}
      {priceContainer}
      {buttonContainer}
    </>
  );
};

Header.protoTypes = {
  cta: PropTypes.string.isRequired,
  hasButtonAction: PropTypes.bool,
  hasPriceCategory: PropTypes.bool,
  hasTitleCategory: PropTypes.bool,
  layoutTheme: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {
  hasButtonAction: true,
  hasPriceCategory: true,
  hasTitleCategory: true,
};

module.exports = Header;
