const React = require('react');
const PropTypes = require('prop-types');

/* eslint-disable react/no-danger */
const TermsAndConditions = ({ title, text: paragraph }) => (
  <div className="info-section__term">
    <h3>{title}</h3>
    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
  </div>
);

TermsAndConditions.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

module.exports = TermsAndConditions;
