const WrapperEditable = require('./wrapper.editable');
const Base = require('./desktop');

const createEditable = require('../../adapters/createEditable');
const withOrder = require('../../adapters/withOrder');
const withHover = require('../../adapters/withHover');
const withEditable = require('../../adapters/withEditable');

const { getCollectionGridProps } = require('./getProps');

module.exports = withHover(withEditable(withOrder(createEditable(WrapperEditable(Base), getCollectionGridProps))));
