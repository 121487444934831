const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { MIN_CAROUSEL_ITEMS } = require('../../components/category-carousel/constants');

const WrapperEditable = (Base) => {
  const Wrapper = (props) => {
    const {
      categories,
      empty_state_card: emptyStateCard,
    } = props;

    const emptyState = (categories.length < MIN_CAROUSEL_ITEMS);

    if (emptyState) {
      const items = Array.from(Array(MIN_CAROUSEL_ITEMS).keys()).map(() => emptyStateCard);
      const emptyProps = {
        ...emptyStateCard,
        categories: items,
        isEmpty: emptyState,
      };

      return <Base {...emptyProps} />;
    }

    return <Base {...props} />;
  };

  Wrapper.propTypes = {
    title: PropTypes.string.isRequired,
    show_price: PropTypes.bool.isRequired,
    show_button: PropTypes.bool.isRequired,
    sub_title: PropTypes.string.isRequired,
    empty_state_card: PropTypes.object.isRequired,
    columns: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  Wrapper.defaultProps = {
    columns: 2,
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
