const React = require('react');
const ClassNames = require('classnames');
const PropTypes = require('prop-types');
const uuid = require('uuid/v4');

const CarouselSnapped = require('@andes/carousel-snapped');

const CategoryCard = require('./CategoryCard');
const metadataPropType = require('../metadataProvider/metadataPropType');

const {
  EXPLORER_THEME,
  LEGEND_THEME,
  CAROUSEL_FULL,
  CAROUSEL_COLUMNS_1,
  CAROUSEL_COLUMNS_3,
  CAROUSEL_COLUMNS_4,
  CAROUSEL_PAGINATION_POSITION,
  CAROUSEL_ARROW_SIZE,
  DEVICE_TYPE,
  SCROLL_HIDDEN,
  SCROLL_VISIBLE,
} = require('./constants');

const { CarouselSnappedSlide } = CarouselSnapped;

const CategoriesCarousel = ({
  title,
  sub_title: subTitle,
  show_title: showTitle,
  show_sub_title: showSubTitle,
  show_price: showPrice,
  show_button: showButton,
  button_text: buttonText,
  price_prefix: pricePrefix,
  columns,
  categories,
  isEmpty,
}, { metadata }) => {
  const { deviceType, layoutTheme } = metadata;

  const pagination = layoutTheme === EXPLORER_THEME || layoutTheme === LEGEND_THEME;
  const isMobile = deviceType === DEVICE_TYPE;
  const column = isMobile ? CAROUSEL_COLUMNS_1 : columns;
  const scroll = isMobile ? SCROLL_VISIBLE : SCROLL_HIDDEN;
  const carouselContainer = ClassNames(
    'carousel__container',
    { 'carousel__container-3': columns === CAROUSEL_COLUMNS_3 },
    { 'carousel__container-4': columns === CAROUSEL_COLUMNS_4 },
  );

  const slides = categories.map((category) => ({
    label: category.label,
    title: category.category_name,
    subTitle: category.price,
    image: category.image_src,
    link: category.link,
    price: category.price,
    isDefaultImage: category.is_default_image,
    buttonText,
    pricePrefix,
    showPrice,
    showButton,
    deviceType,
    columns,
    theme: layoutTheme,
    isEmpty,
  }));

  const showHeader = () => (
    <div className="carousel__container-body-header">
      {showTitle && <h2 className="carousel__container-body-header-title">{title}</h2>}
      {showSubTitle && <h3 className="carousel__container-body-header-subtitle">{subTitle}</h3>}
    </div>
  );

  return (
    <article className={carouselContainer}>
      <div className="carousel__container-body">
        {(showTitle || showSubTitle) && showHeader()}
        <CarouselSnapped
          slidesPerView={column}
          pagination={pagination}
          paginationPosition={CAROUSEL_PAGINATION_POSITION}
          type={CAROUSEL_FULL}
          arrowsSize={CAROUSEL_ARROW_SIZE}
          arrows={!isMobile}
          scroll={scroll}
        >
          {
            slides.map(item => (
              <CarouselSnappedSlide key={uuid()}>
                <CategoryCard {...item} position={item.position} deviceType={deviceType} />
              </CarouselSnappedSlide>
            ))
          }
        </CarouselSnapped>
      </div>
    </article>
  );
};

CategoriesCarousel.propTypes = {
  title: PropTypes.string,
  sub_title: PropTypes.string,
  show_title: PropTypes.bool,
  show_sub_title: PropTypes.bool,
  show_price: PropTypes.bool,
  show_button: PropTypes.bool,
  button_text: PropTypes.string.isRequired,
  price_prefix: PropTypes.string.isRequired,
  columns: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEmpty: PropTypes.bool,
};

CategoriesCarousel.defaultProps = {
  title: '',
  sub_title: '',
  children: [],
  show_title: true,
  show_sub_title: true,
  show_price: true,
  show_button: true,
  isEmpty: false,
};

CategoriesCarousel.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = CategoriesCarousel;
