const React = require('react');

const NewsletterFormDefault = require('./default');
const NewsletterFormLegend = require('./legend');

const metadataPropType = require('../metadataProvider/metadataPropType');

// TODO: Implement different components for other themes
const FORM_BY_THEME = {
  legend: NewsletterFormLegend,
};

const { useState } = React;

const NewsletterForm = (props, { metadata }) => {
  const { layoutTheme } = metadata;

  const [email, setEmail] = useState('');

  const handleSubscription = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const ThemeForm = FORM_BY_THEME[layoutTheme] || NewsletterFormDefault;

  return (
    <ThemeForm
      {...props}
      email={email}
      handleEmailChange={handleEmailChange}
      handleSubscription={handleSubscription}
    />
  );
};

NewsletterForm.contextTypes = {
  metadata: metadataPropType,
};

module.exports = NewsletterForm;
