const PropTypes = require('prop-types');

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
};

module.exports = { propTypes, defaultProps };
