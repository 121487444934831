const Action = require('../../action');
const Image = require('../../image');
const PropTypes = require('prop-types');
const React = require('react');

// wdvms = week deal view more slide

function WeekDealViewMoreSlide(props) {
  return (
    <div className="ui-item__wrapper wdvms__wrapper">
      <a className="ui-card ui-item wdvms" href={props.target}>
        <div className="wdvms-content">
          <div className="wdvms-picture">
            <Image lazyLoad src={props.picture_url} />
          </div>
          <div className="wdvms-label">{props.label}</div>
          <div className="wdvms-view-more">{props.view_more_action.label}</div>
        </div>
      </a>
    </div>
  );
}

WeekDealViewMoreSlide.propTypes = {
  label: PropTypes.string.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  picture_url: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes).isRequired,
};

module.exports = WeekDealViewMoreSlide;
