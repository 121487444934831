const Item = require('../item');
const PropTypes = require('prop-types');
const React = require('react');
const withTracker = require('../with-tracker');
const withTrackerOnPrint = require('../with-tracker-on-print');
const RecommendationsContainer = require('./recommendations-container');
const desktopSeeds = require('./seeds/desktop');

const carouselSettings = {
  Slide: Item,
  slidesToShow: 5,
  arrowsVisible: false,
  variableWidth: false,
  infinite: false,
  slidesToScroll: 5,
  // Width of the carousel. Needs to be considered as: (slide width) * (amount of slides per page to show)
  size: {
    width: '1200px',
    minWidth: '1200px',
  },
};

function Recommendations(props) {
  const imgSize = '224';
  const Carousel = props.carousel;
  const extendedItems = props.items.map(item => ({
    ...item,
    lazyLoad: props.lazyLoad,
    metadata: props.metadata,
    restClient: props.restClient,
    imgSize,
  }));
  const Seed = desktopSeeds[props.id];
  if (Seed) {
    extendedItems.unshift({
      Component: Seed,
      id: `seed-${props.id}`,
      ...props.seed_info,
    });
  }
  return (
    <RecommendationsContainer {...props}>
      <Carousel {...carouselSettings} slides={extendedItems} />
    </RecommendationsContainer>
  );
}

Recommendations.propTypes = {
  carousel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  restClient: PropTypes.object.isRequired,
  seed_info: PropTypes.object,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

Recommendations.defaultProps = {
  seed_info: null,
};

module.exports = withTracker(withTrackerOnPrint(Recommendations));
