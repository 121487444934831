const React = require('react');
const PropTypes = require('prop-types');

const FakeCardOriginal = require('./fake-card-variations/fakeCardOriginal');
const FakeCardMaterial = require('./fake-card-variations/fakeCardMaterial');

const VARIATION_ELEMENT = {
  original: FakeCardOriginal,
  material: FakeCardMaterial,
};

const CarouselPlaceholder = (props) => {
  const FakeCard = VARIATION_ELEMENT[props.display];
  return <FakeCard {...props} />;
};

CarouselPlaceholder.propTypes = {
  display: PropTypes.string,
};

CarouselPlaceholder.defaultProps = {
  display: 'original',
};

module.exports = CarouselPlaceholder;
